import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
// import ScrollToTop from '../../Utilies/ScrollTop';
import getStripe from '../Utilies/useStripe'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './Subscription';
const Sub = () => {
   // const stripePromise = loadStripe('pk_test_51NUWkSCafBjLFi0u7BL3RunHqHt3qHcRe0n6MzmhxUI9UCBT37S1D0hxWmZhTYuMa7RyVPQrQzvp3ipc1smnDcc3005y1kuKuh');
    const stripePromise = loadStripe('pk_live_51NUWkSCafBjLFi0uWtvrvNh14L5SuzcMFwy5jVDERMeOoIT840MjhdQHRT28TZS6IkYfAFjS99kAaMo8GLXUk2p800f2BW58s6');
    return (
        <div>
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </div>
    )
}

export default Sub


