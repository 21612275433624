import React, { useState, useEffect } from 'react'
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { Link,useNavigate } from 'react-router-dom';

const Success = () => {
  
  const [email, setemail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {


    setemail(localStorage.getItem('useremail'));
    fetchSub()
  }, []);

  const [data, setdata] = useState([]);
  const fetchSub = async () => {

    const headers = {
        "Authorization": "token " + localStorage.getItem("token")
    }

    try {
        const response = await fetch('https://trip4cure.com/api/my_plan/', {
            headers: headers
        });
        const user =localStorage.getItem('user');
        const token =localStorage.getItem('token')
        const data = await response.json();

        if(response.status==401){
          navigate('/')
        }else if(token && user=="hospital" && data.subscriptions.length != 0){
          navigate('/success')
          localStorage.setItem("plan", true);
        } else{
          navigate('/')
        }

        setdata(data.subscriptions[0])
        console.log(data.subscriptions)

    } catch (error) {
      
        console.error('Error fetching search results:', error);
    }
}


  return (
    <div>
      <Header />

      <div className='subscription-wrapper'>
        <Row className='justify-content-center'>
          <Col xl={4} className='my-5'>
            <Card>
              <Card.Body>
                <Row className='justify-content-center'>

                  <Col lg={12}>

                    <div className='text-center py-3'>
                      <h1><strong>SUCCESS</strong></h1>
                      <div className='my-3'>
                       {data.transaction_id != "none" && <span><span className='hrLine'></span>
                        <p className='my-2'><strong>Transaction Id:</strong> {data.transaction_id}</p></span>}
                        <span className='hrLine'></span>
                        <p className='my-2'><strong>Plan Name:</strong> {data.plan}</p>
                        <span className='hrLine'></span>
                        <p className='my-2'><strong>Price:</strong>${data.amount}</p>
                        <span className='hrLine'></span>
                        <p className='my-2'><strong>Email Id:</strong> {email}</p>
                        <span className='hrLine'></span>
                        <p className='my-2'><strong>Time Period:</strong> {(new Date(data.purches_date)).toLocaleDateString('en-CA')} to  {(new Date(data.plan_expire)).toLocaleDateString('en-CA')}</p>
                        <span className='hrLine'></span>
                      </div>
                      <div>
                      <Link to={'/hospital-profile'}> <button className='btn'>Profile</button></Link> 
                      </div>
                    </div>
                  </Col>

                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  );
};

export default Success;
