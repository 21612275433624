import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from '../../Utilies/ScrollTop';

export default function Disclaimer() {
    return (
        <div>
            <ScrollToTop />
            <Header />
            <Container>
                <Row>
                    <div className='mx-auto mt-5 text-center '>
                        <h2 className='view-announce-main-heading'>Disclaimer</h2>
                    </div>
                </Row>
                <div className='mb-5'>
                    <p>
                    This website provides information about medical tourism services and healthcare facilities for informational purposes only. The content on this site is not a substitute for professional medical advice, diagnosis, or treatment. It is essential to consult with a qualified healthcare provider before making any decisions related to medical procedures or treatments. We do not endorse or guarantee the quality of any healthcare provider or facility listed on this website. Users of this site are encouraged to conduct their research and verify the credentials and reputation of any healthcare provider or facility before making any commitments. By using this website, you acknowledge that you have read, understood, and agreed to these terms and conditions. 
                    </p>
                    </div>
            </Container>
            <Footer />
        </div>

    )
}
