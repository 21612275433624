import React, { useState, useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from 'react-select';
import axios from 'axios'
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

export default function Overview() {
    const [editAbout, seteditAbout] = useState(false);

    useEffect(() => {
        fetchOverview();
    }, []);



    const validationSchema = Yup.object().shape({

    });


    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const [overview, setoverview] = useState("");
    const [file, setFile] = useState();
    const [data, setdata] = useState();
    function handleChangeImage(event) {
        console.log(event.target.files[0])
        setFile(event.target.files[0])
    }


    const fetchOverview = async () => {
        try {

            const headers = {
                "Authorization": "token " + localStorage.getItem("token")
            }
            const response = await fetch('https://trip4cure.com/api/overview/', {
                headers: headers
            });

            const data = await response.json();

            setdata(() => data.overview_data.overview);

        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };


    let onSubmit = async e => {

        //console.log(speciality)
        try {


            const formData = new FormData()
            formData.append("overview", overview);
            //formData.append("document", file)
            if(file){
                formData.append("document", file)
            }
            



            const headers = {
                "Authorization": "token " + localStorage.getItem("token")
            }
            const update = await axios.post('https://trip4cure.com/api/overview/', formData,
                {
                    headers: headers
                })

            if (update.status === 201) {
                fetchOverview();
                toast.success('Overview updated successfully');
                seteditAbout(false)
                console.log(e.target);

                const RESPONSE_STATUS = update.Msg
                if (RESPONSE_STATUS === 'Success image story added successfully') {

                }

            }
        } catch (err) {
            //setMsg("Something wents wrong")
            console.log(err);
        }
    }


    return (

        <div className='row'>
            <div class="d-flex justify-content-between align-items-center mt-5 mb-3">
                <h4 class="text-right">Overview Settings</h4>
                <button type="button" class="line-primary-btn py-2 px-3 submit-button" onClick={e => seteditAbout(!editAbout)} style={{ float: 'right' }} name="btnAddMore"> <i class="fa fa-pencil" aria-hidden="true" ></i> </button>
            </div>
            <div className='row'>
                {!editAbout ?
                <div  style={{wordWrap:'break-word'}} >
                    <p dangerouslySetInnerHTML={{ __html: data}}></p>
                    </div>
                    :
                    <div>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <input type="file" onChange={handleChangeImage} class="form-control my-3" />
                            <label>Overview</label>
                            <CKEditor
                                editor={ClassicEditor}
                               data={data}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    console.log(data);
                                    setoverview(data)
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />

                            {editAbout ? <div class="my-5 text-center"><button class="line-primary-btn py-2 px-3 submit-button" type="submit">Save Profile</button></div> : <></>
                            }

                        </Form>

                    </div>
                }




            </div>

        </div>
    )
}