import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from '../../Utilies/ScrollTop';

export default function TermofUse() {
    return (
        <>
            <ScrollToTop />
            <Header />
            <Container>
                <Row>
                    <div className='mx-auto mt-5 text-center '>
                        <h2 className='view-announce-main-heading'>Terms of Use</h2>
                    </div>
                </Row>

                <div className='mb-5'>
                    <p>
                        The user can use information on this website for individual purposes. For this purpose, it is necessary that the user consents to the collection, processing, and use of his/her personal data and, if applicable, also of special personal data.
                    </p>
                    <p>This applies in particular to the special services listed as below:</p>
                    <ul>
                        <li>
                            T4C operates an online platform which enables the user to contact a medical provider as well as third-party providers and associated companies (e.g. travel agencies, customer service agencies, payment service providers, or translators) whose services are advertised via our website(s).
                        </li>

                        <li>
                            If a medical service provider concludes a medical treatment contract with the user, the user agrees that the medical service provider shall inform T4C, with line-item breakdown, of the type and date of the medical treatment as well as the amount to be paid and the date of the invoice transmitted by the medical service provider to the user (and, if required by law, that the medical service provider shall transmit the corresponding data to T4C).
                        </li>
                        <li>
                            If a third-party provider concludes a contract with the user for certain services, the user authorizes the third-party provider to inform T4C of the amount and date of the invoice transmitted by the third-party provider to the user with fees included and explained.
                        </li>
                        <li>
                            If the user is the policyholder of an insurance partner of T4C ("insurer"), the user authorizes the medical service provider, the third-party provider, and the insurer to inform T4C of the specific personal data relating to the medical treatment of the user as well as the amount and date of the invoice transmitted by the service provider to the user or to the insurer.
                        </li>
                        <li>
                            The user & providers are offered enrollment to receive emails with promotions by the T4C.
                        </li>
                        <li>
                            T4C uses the personal data for its own advertising purposes and sends the user information about T4C, new products and services, medical service providers, etc., by email, phone call, SMS, or post.
                        </li>

                    </ul>

                    <h5 className='m-0 p-0'>CHANGES TO THE DATA PROTECTION DECLARATION</h5>
                    ‍<p className='m-0 p-0'>
                    T4C reserves the right to change this Privacy Policy at any time in accordance with its terms. T4C will post any changes to the Privacy Policy on this website. In addition, users will be notified by email of any significant changes.
                    </p>

                </div>
            </Container>
            <Footer />
        </>



    )
}
