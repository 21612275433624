import { createBrowserRouter } from "react-router-dom";
import Homepage from "../Pages/Homepage/Homepage";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import SignIn from "../Pages/SignIn/SignIn";
import SignUp from "../Pages/SignUp/SignUp";
import NewPassword from "../Pages/NewPassword/NewPassword";
import VerifyAccount from "../Pages/VerifyAccount/VerifyAccount";
import HospitalSignIn from "../Pages/HospitalSignIn/HospitalSignIn";
import HospitalSignUp from "../Pages/HospitalSignUp/HospitalSignUp";
import ContactUS from "../Pages/ContactUS/ContactUS";
import ViewSpeciality from "../Pages/ViewSpeciality/ViewSpeciality";
import ViewMedicalCenter from "../Pages/ViewMedicalCenter/ViewMedicalCenter";
import Destination1 from "../Pages/ViewAllDestination/AllDestinations/Destination1";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import VerifyOtp from "../Pages/VerifyOtp/VerifyOtp";
import UserProfilePage from "../Pages/UserProfilePage/UserProfilePage";
import ViewAllDestination from "../Pages/ViewAllDestination/ViewAllDestination";
import Search from "../Pages/Search/Search";
import Treatment from "../Pages/Treatment/Treatment";
import MedicalCenterInfo from "../Pages/ViewMedicalCenterInfoPages/MedicalCenterInfo";
import ViewDestinationCenters from "../Pages/ViewAllDestination/ViewDestinationCenters";
import ViewMedicalTourismGuide from "../Pages/ViewMedicalTourismGuide/ViewMedicalTourismGuide";
import ViewAnnouncement from "../Pages/ViewAnnouncement/ViewAnnouncement";
import AnnounceCard from "../Pages/ViewAnnouncement/AnnounceCards/AnnounceCard";
import ViewMedicalTourismInfoPage from "../Pages/ViewMedicalTourismGuide/ViewMedicalTourismInfoPage/ViewMedicalTourismInfoPage";
import HospitalUserProfile from "../Pages/HospitalUserProfile/HospitalUserProfile";
import Success from "../Pages/Success";
import Cancel from "../Pages/Cancel";
import UserProfile from "../Pages/UserProfilePage/UserProfilePage";
import DashboardPage from "../Pages/DashboardPage/DashboardPage";
import HospitalInfo from "../Components/Profile/HospitalInfo/HospitalInfo";
import ViewAllInsurance from "../Pages/ViewAllInsurance/viewallinsurance";
import Sub from "../Pages/Sub";
import Subscription from "../Pages/allsub";
import InsuranceDetail from "../Pages/InsuranceDetail/InsuranceDetail";
import About from "../Pages/About/About";
import Howitworks from "../Pages/Howitworks/Howitworks"
import Disclaimer from "../Pages/Disclaimer/Disclaimer";
import Privacy from "../Pages/Privacy/Privacy";
import TermofUse from "../Pages/TermofUse/TermofUse";
import PricingRefrence from "../Pages/PricingRefrence/PricingRefrence";


const router = createBrowserRouter( [
  {
    path: "/",
    element: <Homepage />
  },
  {
    path: "/user-sign-in",
    element: <SignIn />
  },
  {
    path: "/hospital-sign-in",
    element: <HospitalSignIn />
  },
  {
    path: "/view-medical-tourism-guide",
    element: <ViewMedicalTourismGuide />
  },
  {
    path: "/hospital-profile",
    element: <HospitalUserProfile />
  },
  {
    path: "/user-profile",
    element: <UserProfile />
  },
  {

    path: "/sign-up",
    element: <SignUp />
  },

  {
    path: "/h-p",
    element: <HospitalInfo />
  },
  {
    path: "/payment/:id/:plan",
    element: <Sub/>
  },
  {
    path: "/subscription",
    element: <Subscription/>
  },
  {
    path: "/dashboard",
    element: <DashboardPage/>
  },
  {
    path: "/hospital-sign-up",
    element: <HospitalSignUp />
  },

  {
    path: "/contact-us",
    element: <ContactUS />
  },
  {
    path: "/about-us",
    element: <About />
  },
  {
    path: "/how-it-works",
    element: <Howitworks />
  },

  {
    path: "/disclaimer",
    element: <Disclaimer />
  },

  {
    path: "/pricing-refrence",
    element: <PricingRefrence />
  },

  {
    path: "/privacy",
    element: <Privacy />
  },

  {
    path: "/terms-of-use",
    element: <TermofUse />
  },
  {
    path: "/view-speciality",
    element: <ViewSpeciality />
  },

  {
    path: "/view-medical-center",
    element: <ViewMedicalCenter />
  },

  {
    path: "/view-medical-center/:id",
    element: <MedicalCenterInfo />
  },
  {
    path: "/insurance-detail/:id",
    element: <InsuranceDetail/>
  },
     
  {
    path: "/view-all-announcement",
    element: <ViewAnnouncement />
  },

  {
    path: "/view-all-announcement/announcement-detail/:id",
    element: <AnnounceCard/>
  },
  {
    path: "/verify-account/:name",
    element: <VerifyAccount />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/new-password",
    element: <NewPassword />
  },

  {
    path: "/reset-password",
    element: <ResetPassword />
  },

  {
    path: "/verify-otp",
    element: <VerifyOtp />
  },

  {
    path: "/view-all-announcement",
    element: <UserProfilePage />
  },
  {
    path: "/view-all-destination",
    element: <ViewAllDestination />
  },
  {
    path: "/success",
    element: <Success />
  },
  {
    path: "/cancel/:price",
    element: <Cancel/>
  },
 
  {
    path: "/view-medical-tourism-guide/view-medical-tourism-info-page/:id",
    element: <ViewMedicalTourismInfoPage />
  },
  {

    path: "/view-all-destination/AllDestinations/Destination1",
    element: <Destination1 />
  },
  {
    path: "/view-destination-centers/:country",
    element: <ViewDestinationCenters />
  },
  {
    path: "/search/:speciality/:country",
    element: <Search />
  },
  {
    path: "/treatment/:speciality",
    element: <Treatment />
  },
  
  {
    path: "/allinsurance",
    element: <ViewAllInsurance />
  }
],

  { basename: '/' }
);


export default router;