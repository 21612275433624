import React,{useEffect} from 'react'

import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { Container } from 'react-bootstrap';
import Users from '../Dashboard/users';
import { useNavigate } from "react-router-dom";
function DashboardPage() {
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
        if(token && user=="hospital"){
          navigate('/dashboard')
        }else{
          navigate('/')
        }
      }, []);
    return (

        <div>
            <Header />
            <Container>
             <Users/>
            </Container>
            <Footer />
        </div>
    )
}

export default DashboardPage
