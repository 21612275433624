import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import './ViewMedicalTourismInfoPage.css'
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import { useParams } from 'react-router-dom'

const ViewMedicalTourismInfoPage = () => {
    const { id } = useParams();

    console.log(id);
    const [data, setData] = useState();

    useEffect(() => {
        fetchDetail(id);
    }, [])

    let fetchDetail = async (guide) => {
        try {
            let res = await fetch("https://trip4cure.com/api/medical_tourism_guide_detail/", {
                method: "POST",
                body: JSON.stringify({
                    guide_id: guide,
                }),
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                }
            });
            let resJson = await res.json();
            setData(resJson['Guide Detail'])
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <>
            <Header />
            <section>
                {data != null && <Container>
                    <Row>
                        <div className='col-md-9 mx-auto mt-5 text-center '>
                            <h2 className='medical-tourism-info-main-heading'>{data.card_heading}</h2>
                            <p className='main-sub-heading-page-fmc' >{data.short_description}</p>
                        </div>
                    </Row>
                    <Row>
                        <div>Date : {(new Date(data.created_at)).toLocaleString('hi-IN')}</div>
                        <div className='col-md-12 mx-auto my-5' style={{wordWrap:'break-word'}} >
                            <p  dangerouslySetInnerHTML={{__html: data.card_text}}></p>
                            {/* <p>{data.card_text}</p> */}
                        </div>
                    </Row>
                </Container>}
            </section>
            <Footer />
        </>
    )
}

export default ViewMedicalTourismInfoPage