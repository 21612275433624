import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Footer/Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer>

      <Container>
        <Row className='py-5  footer-left-side'>
          <Col xl={2} sm={6} xs={6}>
            <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt='' height={120} />
          </Col>
          <Col xl={3} sm={6} xs={6} className=' d-block d-xs-none d-xl-none pt-4  text-center'>
            <h6 className='footer-connect'>Connect With Us</h6>
            <div className='footer-social px-xl-4' >
              <Link to={''}><i className='fa fa-twitter'></i></Link>
              <Link to={''}><i className='fa fa-facebook'></i></Link>
              <Link to={''}><i className='fa fa-linkedin'></i></Link>
              <Link to={''}><i className="fa fa-instagram"></i></Link>
            </div>
          </Col>
          <Col xl={5} sm={8} xs={6} className='my-sm-3'>
            <h6 className='footer-connect pt-3'>Quick Links</h6>
            <Row>
              <Col xl={7} sm={6} >
                <ul className="footer-links">
                  <li><Link to={'/'}>Home</Link></li>
                  <li><Link to={'../view-medical-center'}>Medical Centers</Link></li>
                  <li><Link to={'../view-medical-tourism-guide'}>Medical Tourism Guide</Link></li>
                  <li><Link to={'../view-all-destination'}>Destinations</Link></li>
                  <li><Link to={'../allinsurance'}>Insurance</Link></li>
                  <li><Link to={'../view-all-announcement'}>Latest Announcements</Link></li>
                </ul>
              </Col>
              <Col xl={5} sm={6} className='mt-4 mt-md-0'>
                <ul className="footer-links ">
                  <li><Link to={'../about-us'}>About Us</Link></li>
                  <li><Link to={'../how-it-works'}>How it Works</Link></li>
                  <li><Link to={'../pricing-refrence'}>Pricing Reference</Link></li>
                  <li><Link to={'../disclaimer'}>Disclaimer</Link></li>
                  <li><Link to={'../privacy'}>Privacy</Link></li>
                  <li><Link to={'../terms-of-use'}>Terms of Use</Link></li>
                  <li><Link to={'../contact-us'}>Contact Us</Link></li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xl={3} sm={4} xs={6} className='my-sm-3 pt-3'>
            <h6 className='footer-connect'>Featured Specialties</h6>
            <ul className="footer-links">
              <li><Link to={'../treatment/Cancer Treatment'}>Cancer Treatment</Link></li>
              <li><Link to={'../treatment/Cardiac Surgery'}>Cardiac Surgery</Link></li>
              <li><Link to={'../treatment/Cosmetic Surgery'}>Cosmetics Surgery</Link></li>
              <li><Link to={'../treatment/Fertility Treatments'}>Fertility Treatments</Link></li>
              <li><Link to={'../treatment/Knee Replacement'}>Knee Replacement</Link></li>
              <li><Link to={'../treatment/Neurology'}>Neurology</Link></li>
             
            </ul>
          </Col>
          <Col xl={2} sm={12} xs={12} className=' d-xl-inline-block d-none my-sm-3 pt-3'>
            <h6 className='footer-connect'>Connect With Us</h6>
            <div className='footer-social' >
              <Link target="_blank" to={'https://twitter.com/Trip4Cure'}><i className="fa fa-twitter"></i></Link>
              <Link target="_blank" to={'https://www.facebook.com/tripforcure'}><i className="fa fa-facebook"></i></Link>
              <Link target="_blank" to={'https://www.linkedin.com/company/tripforcure/'}><i className="fa fa-linkedin"></i></Link>
              <Link target="_blank" to={'https://www.instagram.com/trip4cure'}><i className="fa fa-instagram"></i></Link>
            </div>
          </Col>
        </Row>
        <Row className='text-center'>
          <hr />
          <p className='text-white'>Copyright © 2023 Trip4Cure, All Rights Reserved</p>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer