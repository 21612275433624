import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Card, Button, Nav } from "react-bootstrap";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import {useNavigate } from 'react-router-dom';


import { Link } from "react-router-dom";
const Subscription = () => {
    const [plan, setplan] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {

        const user =localStorage.getItem('user');
        const token =localStorage.getItem('token')
        if(token && user=="hospital" ){
          navigate(`/subscription`)
        }else{
          navigate('/')
        }
        const fetchplan = async () => {
            try {
                const response = await fetch('https://trip4cure.com/api/all_subcription_plan_api/');

                const data = await response.json();
                setplan(data);
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        };
        fetchplan();

    }, []);


    return (
        <div>
            <Header />

            <div className='subscription-wrapper'>
                <Row className='justify-content-center'>
                    <Col xl={9} className='my-5'>
                        <Card>
                            <Card.Body>
                                {plan && <Row className='justify-content-center'>
                                    <Col lg={4}>
                                        <div className='text-center py-3'>
                                            <h1><strong>{plan[0].plan_name}</strong></h1>
                                            <div className='my-5'>
                                                <span className='hrLine'></span>
                                                <p className='my-2'><strong>{plan[0].free_day} Days Availability</strong></p>
                                                <span className='hrLine'></span>
                                                <p className='my-2'><strong>24/7 Support</strong></p>
                                                <span className='hrLine'></span>
                                            </div>

                                            <h2 className='mb-4'>
                                                <strong>{plan[0].amount}</strong>
                                            </h2>

                                            <div>
                                            <Link to={`/payment/1000/${plan[0].id}`}><button className='btn'>Choose Now</button></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4}>

                                        <div className='text-center py-3 siderBorder'>
                                            <h1><strong>{plan[1].plan_name}</strong></h1>
                                            <div className='my-5'>
                                                <span className='hrLine'></span>
                                                <p className='my-2'><strong>{plan[1].free_day} Days Availability</strong></p>
                                                <span className='hrLine'></span>
                                                <p className='my-2'><strong>24/7 Support</strong></p>
                                                <span className='hrLine'></span>
                                            </div>

                                           <h2 className='mb-4'> <strong>{plan[1].amount}</strong></h2>

                                            <div>
                                            <Link to={`/payment/300/${plan[1].id}`}><button className='btn'>Choose Now</button></Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className='text-center py-3'>
                                            <h1><strong>{plan[2].plan_name}</strong></h1>
                                            <div className='my-5'>
                                                <span className='hrLine'></span>
                                                <p className='my-2'><strong>{plan[2].free_day} Days Availability</strong></p>
                                                <span className='hrLine'></span>
                                                <p className='my-2'><strong>24/7 Support</strong></p>
                                                <span className='hrLine'></span>
                                            </div>
                                            <h2 className='mb-4'><strong>{plan[2].amount}</strong></h2>

                                            <div>
                                            <Link to={`/payment/0/${plan[2].id}`}><button className='btn'>Choose Now</button></Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Footer />
        </div>
    )
}

export default Subscription


