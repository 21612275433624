import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from '../../Utilies/ScrollTop';

export default function Howitworks() {
    return (
        <>
            <ScrollToTop />
            <Header />
            <Container>
                <Row>
                    <div className='mx-auto mt-5 text-center '>
                        <h2 className='view-announce-main-heading'>How it Works?</h2>
                    </div>
                </Row>

                <div className='mb-5'>
                   
                    <p><span className='fw-bolder'>Trip4Cure</span> assists global patients to choose their medical providers based on our own workflow (step by step):
                    </p>
                    <h5>Research and Decision-Making:</h5>
                    <ul>
                        <li>
                            Patients research their medical condition and treatment options.
                        </li>
                        <li>
                            They explore destinations known for specific medical procedures.
                        </li>
                        <li>
                            Considerations include costs, quality of care, and travel logistics.
                        </li>
                    </ul>

                    <h5>Choosing a Healthcare Provider:</h5>
                    <ul>
                        <li>
                            Patients select a healthcare facility or hospital based or wellness center on reputation, expertise, and patient reviews.
                        </li>
                        <li>
                            Some medical tourism agencies offer assistance with these logistics.
                        </li>
                    </ul>

                    <h5>Planning Travel and Accommodation:</h5>
                    <ul>
                        <li>
                            Patients make travel arrangements, including flights, visas, and accommodations once decision is made through our service.
                        </li>
                        <li>
                            Some medical tourism agencies offer assistance with these logistics.
                        </li>
                    </ul>

                    <h5>Consultation and Pre-Procedure Assessment:</h5>
                    <ul>
                        <li>
                        Before traveling, patients often have remote consultations with the medical team.
                        </li>
                        <li>
                        They provide medical history and undergo necessary tests.
                        </li>
                    </ul>
                    
                   
                    <h5>Travel and Arrival:</h5>
                    <ul>
                        <li>
                        Patients travel to the destination, ensuring all required documents are in order.
                        </li>
                        <li>
                        They arrive at the destination, where they may be greeted by a coordinator or representative.
                        </li>
                    </ul>
                    
                    <h5>Medical Procedure:</h5>
                    <ul>
                        <li>
                        The scheduled medical procedure or treatment takes place at the chosen healthcare facility.
                        </li>
                        <li>
                        The medical team follows the planned treatment protocol.
                        </li>
                    </ul>
                 
                    <h5>Recovery and Post-Procedure Care:</h5>
                    <ul>
                        <li>
                        Patients recover in the destination country, often in nearby accommodations.
                        </li>
                        <li>
                        They attend follow-up appointments as necessary.
                        </li>
                    </ul>
                    
                    <h5>Returning Home:</h5>
                    <ul>
                        <li>
                        Once cleared by the medical team, patients can return home.
                        </li>
                        <li>
                        They may receive post-operative care instructions for their local healthcare providers.
                        </li>
                    </ul>
                   
                    <h5>Follow-up Care at Home:</h5>
                    <ul>
                        <li>
                        Patients continue their recovery and follow-up care with their local healthcare providers.
                        </li>
                      
                    </ul>
                    <h5>Feedback and Evaluation:</h5>
                    <ul>
                        <li>
                        Patients may provide feedback on their experience with Trip4Cure and to the medical or wellness center.
                        </li>
                        <li>
                        This information can help others considering similar treatments.
                        </li>
                    </ul>
                   <p>
                   It's crucial for individuals considering medical tourism to thoroughly research and plan each step, ensuring they choose a reputable healthcare provider and adhere to all medical and travel requirements. Additionally, consulting with healthcare professionals at home is essential for a safe and successful medical tourism experience.
                   </p>
                </div>
            </Container>
            <Footer />
        </>
    )
}
