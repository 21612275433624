import React, { useState } from 'react';
import './spinner.css'; // Import the CSS for the spinner
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import './subscirption.css'
import axios from 'axios'
import CardSection from './CardSection';
import ScrollToTop from '../Utilies/ScrollTop'
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';;


export default function CheckoutForm() {
    const { id, plan } = useParams()
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [sub, setSub] = useState(false)

   

    let handleSubmit = async e => {

        setSub(true)
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make  sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            // Show error to your customer.
            console.log(result.error.message);
        } else {


        try {


            const formData = new FormData()
            formData.append("source", result.token.id)
            formData.append("amount", id)
            formData.append("plan_id", plan)

            const headers = {
                "Content-Type": 'application/json',
                "Accept": 'application/json',
                "Authorization": "token " + localStorage.getItem("token")
            }
            const update = await axios.post("https://trip4cure.com/api/create-subscription/", formData,
                {
                    headers: headers
                })
            console.log(update)
         
                if (update.status === 201) {
                    setSub(false)
                    console.log('>>>',update.data)
                    if (update.data.msg =="payment successful") {
                        localStorage.setItem('subscription', JSON.stringify(update.data));
                        navigate("/success");
                    } else {
                        navigate(`/cancel/${id}`)
                    }
                } else {
                    setSub(false)
                    navigate(`/cancel/${id}`);
                }
            
        } catch (err) {
            console.log('>>>>>>',"cancel")
            setSub(false)
            navigate(`/cancel/${id}`);
        }
    }
    }

    return (
        <>
        <ScrollToTop/>
            <Header />
            {sub && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            <div className='d-flex align-items-center justify-content-cente subs' >

                <Row>
                    <Col md={12}>
                        {id == 1000 ? <h1>Annual Plan</h1> : id == 300 ? <h1>Quarterly Plan</h1> : <h1>Free Trial</h1>}

                    </Col>
                    <Col md={12}>
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12}>
                                    <CardSection />
                                </Col>
                                <Col md={6} className='text-md-center'>
                                    <button type='submit' disabled={!stripe} className="line-primary-btn px-2 py-3 submit-button my-4">Subscribe</button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>


            </div>
            <Footer />
        </>
    );
}