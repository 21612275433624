import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Card, Tab, Nav, Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useParams } from "react-router-dom";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Slider from 'react-slick';
import Select from 'react-select'
import { useCallback } from 'react';
import ScrollToTop from '../../Utilies/ScrollTop';
import './MedicalCenterInfo.css'
import ImageGallery from 'react-image-gallery';
import { toast, ToastContainer } from 'react-toastify';
//import { Carousel } from 'react-carousel-minimal';
import Carousel from 'react-gallery-carousel';
const MedicalCenterInfo = () => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const { id } = useParams()
  const [token, settoken] = useState("")

  const [hospital, setHospital] = useState({})

  const [services, setServices] = useState([])
  const [doctors, setDoctors] = useState([])
  const [overview, setOverview] = useState([])
  const [specialty, setSpecialty] = useState(null);


  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('Patient Name is required')
      .min(1),
    country: Yup.string()
      .required('Country name is required')
      .min(2),
    city: Yup.string()
      .required('City name is required')
      .min(2),
    age: Yup.string()
      .required('Age is required')
      .max(3),
    contactnumber: Yup.string()
      .required('Contact number is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    message: Yup.string()
      .required('Message is required')
      .min(10),
  });

  const [name, setName] = useState("");
  const [submitButton, setSubmitButton] = useState(true);
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [contact, setContact] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [message, setMessage] = useState("");
  const [sId, setId] = useState([]);
  const handleInput1 = (e) => {
    const formattedNumber = formatNumber(e.target.value);
    setName(formattedNumber);
  };
  const handleInput2 = (e) => {
    const formattedNumber = NotAlph(e.target.value);
    setAge(formattedNumber);
  };

  const handleInput3 = (e) => {
    const formattedNumber = formatNumber(e.target.value);
    setCountry(formattedNumber);
  };

  const handleInput4 = (e) => {
    const formattedNumber = formatNumber(e.target.value);
    setCity(formattedNumber);
  };

  const handleInput5 = (e) => {
    const formattedNumber = NotAlph(e.target.value);
    setContact(formattedNumber);
  };
  const handleMessageChange = event => {
    // 👇️ access textarea value
    console.log(event);
    setMessage(event.target.value);

  };


  const formatNumber = (value) => {
    return value.replace(/[^A-Za-z ' ']/ig, "")
  }



  const NotAlph = (value) => {
    return value.replace(/[^0-9]/ig, "")
  }

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;



  let onSubmit = async (e) => {

    setSubmitButton(false)

    console.log(message)
    try {
      if (fromDate != "" && toDate != "") {

        var formdata = JSON.stringify({
          patient_name: name,
          patient_country: country,
          patient_email: email,
          patient_city: city,
          patient_contact_num: contact,
          patient_age: age,
          from_date: fromDate,
          to_date: toDate,
          service_type: sId,
          message: message,
          hospital_id: id
        })
      } else if (fromDate == "" && toDate == "") {
        var formdata = JSON.stringify({
          patient_name: name,
          patient_country: country,
          patient_email: email,
          patient_city: city,
          patient_contact_num: contact,
          patient_age: age,
          service_type: sId,
          message: message,
          hospital_id: id
        })
      } else if (fromDate == "") {
        var formdata = JSON.stringify({
          patient_name: name,
          patient_country: country,
          patient_email: email,
          patient_city: city,
          patient_contact_num: contact,
          patient_age: age,
          to_date: toDate,
          service_type: sId,
          message: message,
          hospital_id: id
        })
      } else if (toDate == "") {
        var formdata = JSON.stringify({
          patient_name: name,
          patient_country: country,
          patient_email: email,
          patient_city: city,
          patient_contact_num: contact,
          patient_age: age,
          from_date: fromDate,
          service_type: sId,
          message: message,
          hospital_id: id
        })
      }
      let res = await fetch("https://trip4cure.com/api/patient_request/", {
        method: "POST",
        body: formdata,
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json',
          "Authorization": "token " + localStorage.getItem("token")
        }
      });
      let resJson = await res.json();
      if (res.status === 201) {
        setSubmitButton(true);
        toast.success('Contact Hospital submitted successfully');
        setTimeout(function () {
          window.location.reload();
        }, 5000);
        setName("");
        setAge("")
        setCity("");
        setContact("")
        setEmail("")
        setFromDate("")
        setToDate("")
        //  setId([])
        setMessage("")
        setCountry("")
        setShow(false)



      } else {
        // setMsg(resJson.msg||resJson.error||resJson.message)
        console.log(res)
      }
    } catch (err) {
      //setMsg("Something wents wrong")
      console.log(err);
    }
  }



  const [allImg, setAllImg] = useState([]);
  const [user, setUser] = useState([]);
  const images = [];
  useEffect(() => {
    settoken(localStorage.getItem("token"))
    setUser(localStorage.getItem("user"))
    const fetchHospitalDetails = async () => {
      try {
        const response = await fetch('https://trip4cure.com/api/hospital_details/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ hospital_id: id }),
        });

        const data = await response.json();

        const hospitalData = data.Hospital
        const serviceData = data.Hospital_Services
        const doctorData = data.doctor
        const overviewData = data.overview
        const imgData = data.Hospital_Images
        allImg.push({
          image: 'https://trip4cure.com' + hospitalData.profile_image,
          src: 'https://trip4cure.com' + hospitalData.profile_image,
          thumbnail: 'https://trip4cure.com' + hospitalData.profile_image,
        },)
        for (let i = 0; i < data.Hospital_Images.length; i = i + 1) {
          allImg.push({
            image: 'https://trip4cure.com' + data.Hospital_Images[i], src: 'https://trip4cure.com' + data.Hospital_Images[i], thumbnail: 'https://trip4cure.com' + data.Hospital_Images[i],
          })
        }
        setHospital(() => ({ ...hospitalData }))
        setServices(() => [...serviceData])
        setDoctors(() => [...doctorData])
        setOverview(() => (overviewData))
        fetchInsurance(hospitalData.hospital_country)
        console.log(allImg)
        console.log('images>>>>>>>>>>.', hospitalData.profile_image)
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchHospitalDetails();
  }, [id]);

  const [Insurance, setInsurance] = useState([])
  const fetchInsurance = async (country) => {
    console.log(country)
    try {
      const response = await fetch('https://trip4cure.com/api/search_insurance/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({ search_text: country })
      });

      const data = await response.json();
      setInsurance(data.insurance)

    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const [space, setSpace] = useState([]);
  const fetchSpace = async (e) => {
    console.log(space)
    e.preventDefault()
    try {
      const response = await fetch('https://trip4cure.com/api/seach_doctors/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //"Authorization": "token " + localStorage.getItem("token")
        },
        body: JSON.stringify({ service_name: space.service_name ,hospital_id:hospital.id})
      });

      const data = await response.json();
      console.log(data)
      setDoctors(() => ([...data.doctors]))
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleSearch = useCallback(e => {
    e.preventDefault()
    let temp = doctors.filter(doctor => doctor.speciality === specialty.value)
    console.log(temp)
  }, [specialty])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);


  const handleShow = () => setShow(true);


  const handleChangeService = (e) => {
    setId(Array.isArray(e) ? e.map(x => x.id) : []);

  }




  return (
    <>
      <ScrollToTop />
      <Header />
      <ToastContainer />
      <section id='medical-center-info-page_1' >
        <Container>
          <Row>
            <Col md={8} xs={12}>
              <div>
                <div className=' mt-5 '>
                  <h2 className='main-heading-page'>{hospital.hospital_name}</h2>
                </div>
                <div className='  mt-5 d-none d-sm-block'>
                  <div className='row'>
                    {hospital ? <div className='col-6'>
                      {/* {allImg.length !== 0 || undefined ? <ImageGallery items={allImg} showFullscreenButton={true} showPlayButton={false} /> : <></>} */}
                      {/* <img className='mt-3 me-4 img-fluid' style={{ width: '320px',height:'230px' }} src={hospital.profile_image ? 'https://trip4cure.com/api/' + hospital.profile_image : ''}/> */}
                      {allImg.length !== 0 || undefined ?
                        //           <Carousel
                        //   data={allImg}
                        //   time={2000}
                        //   width="950px"
                        //   height="300px"
                        //   radius="10px"
                        //   slideNumber={true}
                        //   captionPosition="bottom"
                        //   automatic={true}
                        //   dots={true}
                        //   pauseIconColor="white"
                        //   pauseIconSize="40px"
                        //   slideBackgroundColor="darkgrey"
                        //   slideImageFit="cover"
                        //   thumbnails={true}
                        //   thumbnailWidth="100px"
                        //   style={{
                        //     textAlign: "center",
                        //     maxWidth: "850px",
                        //     maxHeight: "500px",
                        //     margin: "0px auto",
                        //   }}
                        // /> 
                        <Carousel images={allImg} style={{ height: '300px', width: '100%' }} />
                        : <></>}
                    </div> : <></>}
                    <div className='col-6'>
                      <h4 className='mt-3 fw-bold'>Location</h4>
                      <h4 className=' fw-bold'>{hospital.hospital_city}, {hospital.hospital_country}</h4>
                      {token && user == 'user' ? <button class=" px-3 py-2 my-2 submit-button" variant="primary" onClick={handleShow}>Contact Hospital</button> : <Link class=" px-3 py-2  submit-button" variant="primary" to={'/user-sign-in'}>Contact Hospital</Link>}
                    </div>
                  </div>

                </div>

                <div className='  mt-5 d-block d-sm-none'>
                  <div className='row'>
                    {hospital ? <div className='col-12'>
                      {allImg.length !== 0 || undefined ?
                        // <ImageGallery items={allImg} showFullscreenButton={true} showPlayButton={false} /> 
                        <Carousel images={allImg} style={{ height: '300px', width: '100%' }} />
                        : <></>}
                      {/* <img className='mt-3 me-4 img-fluid' style={{ width: '320px',height:'230px' }} src={hospital.profile_image ? 'https://trip4cure.com/api/' + hospital.profile_image : ''}/> */}

                    </div> : <></>}
                    <div className='col-12'>
                      <h4 className='mt-3 fw-bold'>Location</h4>
                      <h4 className=' fw-bold'>{hospital.hospital_city}, {hospital.hospital_country}</h4>
                      {token ? <button class=" px-3 py-2 my-2 submit-button" variant="primary" onClick={handleShow}>Contact Hospital</button> : <Link class=" px-3 py-2  submit-button" variant="primary" to={'/user-sign-in'}>Contact Hospital</Link>}
                    </div>





                  </div>

                </div>
              </div>
              <Modal show={show} onHide={handleClose} dialogClassName="my-modal">
                <Modal.Header closeButton>
                  <Modal.Title>Contact Hospital</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form onSubmit={handleSubmit(onSubmit)}>


                    <Form.Group className="mb-3" controlId="fullName">
                      <Form.Label>Patient Name*</Form.Label>
                      <input name="patient_name" type="text" maxLength={'200'} {...register('firstname')} onChange={(e) => handleInput1(e)} value={name} placeholder='Enter Patient Name' className={`form-control ${errors.firstname ? 'is-invalid' : ''}`} />
                      <div className="invalid-feedback">{errors.firstname?.message}</div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email*</Form.Label>
                      <input name="patient_email" type="text" autoComplete="nope" {...register('email')} onChange={(e) => setEmail(e.target.value)} value={email} placeholder='Enter email' className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                      <div className="invalid-feedback">{errors.email?.message}</div>
                    </Form.Group>


                    <Form.Group className="mb-3">
                      <Form.Label>Contact Number*</Form.Label>
                      <input name="patient_contact"  autoComplete="nope" type="text" {...register('contactnumber')} maxLength={'20'} onChange={(e) => handleInput5(e)} value={contact} placeholder='Enter contact number' className={`form-control ${errors.contactnumber ? 'is-invalid' : ''}`} />
                      <div className="invalid-feedback">{errors.contactnumber?.message}</div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="fullName">
                      <Form.Label>Age*</Form.Label>
                      <input name="patient_age" type="text" {...register('age')} placeholder='Enter age ' maxLength={'3'} onChange={(e) => handleInput2(e)} value={age} className={`form-control ${errors.age ? 'is-invalid' : ''}`} />
                      <div className="invalid-feedback">{errors.age?.message}</div>
                    </Form.Group>


                    <Form.Group className="mb-3">
                      <Form.Label>City*</Form.Label>
                      <input name="patient_city" autoComplete="nope" type="text" {...register('city')} maxLength={'200'} onChange={(e) => handleInput4(e)} value={city} placeholder='Enter city name' className={`form-control ${errors.city ? 'is-invalid' : ''}`} />
                      <div className="invalid-feedback">{errors.city?.message}</div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="fullName">
                      <Form.Label>Country*</Form.Label>
                      <input name="patient_country"  autoComplete="nope" type="text" {...register('country')} maxLength={'200'} onChange={(e) => handleInput3(e)} value={country} placeholder='Enter country name' className={`form-control ${errors.country ? 'is-invalid' : ''}`} />
                      <div className="invalid-feedback">{errors.country?.message}</div>
                    </Form.Group>



                    <Form.Group className="my-3">
                      <Form.Label><p className='font-style'>Choose Speciality*</p></Form.Label>
                      <Select name="service" className='form-control' required isMulti={true}
                        getOptionLabel={(option) => option.service_name}
                        getOptionValue={(option) => option.service_name}
                        onChange={handleChangeService}
                        options={services} />
                      <div className="invalid-feedback">{errors.service?.message}</div>
                    </Form.Group>

                    <p className='my-2'>Select suitable dates for you: </p>

                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="fullName">
                          <Form.Label>From Date</Form.Label>
                          <input name="from_date" type="date" onChange={(e) => setFromDate(e.target.value)} value={fromDate} className="form-control date-input" />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>To Date</Form.Label>
                          <input name="to_date" type="date" onChange={(e) => setToDate(e.target.value)} value={toDate} className="form-control date-input" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <Form.Label>Message*</Form.Label>
                      <textarea rows={4} name="message" type="text" {...register('message')} maxLength={'4000'} onChange={(e) => setMessage(e.target.value)} value={message} placeholder='Enter Message' className={`form-control ${errors.message ? 'is-invalid' : ''}`} />

                      <div className="invalid-feedback">{errors.message?.message}</div>

                    </Form.Group>


                    <div className='d-grid my-4'>
                      <button variant="primary" type="submit" disabled={!submitButton} className="line-primary-btn px-2 py-3 submit-button">
                        Submit
                      </button>
                    </div>
                  </Form>
                </Modal.Body>

              </Modal>

              <Tab.Container id="left-tabs-example" defaultActiveKey="overview">
                <Row className='mt-4'>
                  <Col md="12">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="overview" className=" nav-tabs round-left" >Overview</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="services" className="nav-tabs nav-tab">Services</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="doctors" className=" nav-tabs round-right">Doctors</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col md={12}>
                    <Tab.Content className='my-3'>
                      <Tab.Pane eventKey="overview">
                        <div style={{ wordWrap: 'break-word' }} >
                          <p dangerouslySetInnerHTML={{ __html: overview.overview }}></p>
                        </div>
                        {overview.document != null ? <a className='fw-bolder' href={`${"https://trip4cure.com" + overview.document}`}>Download</a> : <></>}
                      </Tab.Pane>
                      <Tab.Pane eventKey="services">
                        <Row>
                          {
                            services.map(service =>
                              <Col className='col-12'>
                                <React.Fragment key={service.id}>
                                  <h5 className='fw-bold'>{service.service_name}</h5>
                                  {service.price ? <p>
                                    <span className='fw-bolder'>Price :</span> {service.price}
                                  </p> : <></>}
                                  <p>
                                    {service.service_description}
                                  </p>
                                </React.Fragment>
                              </Col>
                            )
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="doctors">
                        <div className='mb-4 '>
                          <Form onSubmit={fetchSpace} >
                            <Container className='d-none d-sm-block'>

                              <Row className=''>
                                <Col md={5}>
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label><p className='font-style'>Choose Speciality</p></Form.Label>
                                    <Select className="form-control-filter" name='speciality' required={true} getOptionLabel={(option) => option.service_name}
                                      getOptionValue={(option) => option.service_name}
                                      onChange={(value) => setSpace(value)}
                                      options={services}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md="3">
                                  <button type="submit" className="px-3 py-3  submit-button" ><i class="fa fa-search"> </i> Search</button>
                                </Col>
                              </Row>
                            </Container>
                          </Form>
                        </div>

                        <Row>
                          {console.log(doctors)}
                          {
                           doctors.length!=0 && doctors.map(doctor =>
                              <Col className='col-12 my-3'>
                                <div class="cardc ">

                                  <div class="justify-content-start px-3">
                                    <div className='d-none d-sm-block'>
                                      <Row>
                                        <Col className='col-2'>

                                          <img class="user-img fit-image" src={doctor?.photo ? `${"https://trip4cure.com" + doctor?.photo}` : `${process.env.PUBLIC_URL}/images/default.jpg`} />
                                        </Col>
                                        <Col className='col-10'>
                                          <div class="text-left">
                                            <p><span className='fw-bolder'>Name : </span>{doctor.name}</p>
                                            <p ><span className='fw-bolder'>Speciality : </span>{doctor.speciality}</p>
                                            <p ><span className='fw-bolder'>Department : </span>{doctor.department}</p>
                                            <p ><span className='fw-bolder'>Experience :</span> {doctor.experience}</p>


                                          </div></Col>

                                      </Row>
                                    </div>
                                    <Row className='d-block d-sm-none'>
                                      <Col className='col-12'>
                                        <img class="user-img fit-image " src={doctor.photo ? `${"https://trip4cure.com" + doctor.photo}` : `${process.env.PUBLIC_URL}/images/default.jpg`} />
                                      </Col>
                                      <Col className='col-12'>
                                        <div class="text-left">
                                          <p className='m-0'><span className='fw-bolder'>Name : </span>{doctor.name}</p>
                                          <p className='m-0'><span className='fw-bolder'>Speciality : </span>{doctor.speciality}</p>
                                          <p className='m-0'><span className='fw-bolder'>Department : </span>{doctor.department}</p>
                                          <p className='m-0'><span className='fw-bolder'>Experience :</span> {doctor.experience}</p>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Col>
                            )}

                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
            <Col md={4} xs={12}>

              <div class="row m-2">
                {
                  Insurance.length > 0 && <div style={{ backgroundColor: 'rgb(184, 53, 59)', color: 'white', width: '100%', padding: '10px' }}>
                    Insurance, Travel Agents & Vendors
                  </div>}
                {
                  Insurance.map(service =>
                    <Link to={`/insurance-detail/${service.id}`} style={{ textDecoration: 'none' }}>
                      <Col md={12} xs={12} className='m-2 px-2' key={service.id}>
                        <Link to={`/insurance-detail/${service.id}`} className="sliderblock">
                          <Card className="text-center">
                            <div className="image-block">
                              <Card.Img variant="top" style={{ width: '100%', height: '28vh' }} src={service.image ? 'https://trip4cure.com' + service.image : ''} />

                            </div>
                            <Card.Body>
                              <Card.Title><h4 className='card-heading'>{service.company_name}</h4></Card.Title>
                              <Card.Text><p className='card-sub-heading-view-medical'>{service.country}</p></Card.Text>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    </Link>
                  )}
              </div>
            </Col>
          </Row>


        </Container>
      </section>
      <Footer />
    </>
  )
}

export default MedicalCenterInfo