import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import './ViewAllDestination.css';
import ReactPaginate from "react-paginate";
import ScrollToTop from "../../Utilies/ScrollTop";
const ViewAllDestination = () => {
  useEffect(() => {
    fetchDestinations(page);
  }, [])

  const [destination, setDestination] = useState()
  const [page, setpage] = useState(1)
  const [pages, setpages] = useState(1);


  const fetchDestinations = async (count) => {

    try {
      const response = await fetch('https://trip4cure.com/api/all_destination/?page=' + count, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();
      const hospitalsData = data.results
      setpages(data.count / 10)
      setDestination(() => ([...hospitalsData]))

    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handlePageClick = (event) => {
    const count = event.selected + 1;
    console.log(count)
    setpage(count);
    fetchDestinations(count);
  };

  return (
    <>
      <ScrollToTop />
      <Header />
      <section className='viewalldestination'>
        <Container>
          <Row>
            <div className='col-md-12 mx-auto mt-5 text-center'>
              <h2 className='view-destination-main-heading'>Destinations</h2>
              <p className='main-sub-heading-page-fmc'>Trip4Cure’s recommended destinations and countries for medical tourism and/or wellness are based on current trends, quality, cost, and customer satisfaction.</p>
            </div>
          </Row>
          <Row className='gx-6 gy-5 mb-5 '>
            {destination &&
              destination.map(des =>
                <Col md={6} xs={12} className=''>
                  <Card className="sliderd"  >
                    <Link to={{ pathname: `../view-destination-centers/${des.country_name}` }} style={{ height: '60vh' }}>
                      <Card.Img variant="top" style={{ width: "100%", height: "100%" }} src={`${"https://trip4cure.com" + des.country_image}`} />
                      <div className="fd-content">
                        <Card.Img className=" i-flag rounded-circle top-0 start-0" variant="top" src={`${"https://trip4cure.com" + des.country_logo}`} />
                        <div className="fd-bottom-content">
                          <h3 className="destination-country">{des.country_name}</h3>
                          <h4 className="destination-centers">({des.Hospitalcount} Medical Centers)</h4>
                        </div>
                      </div>
                    </Link>
                  </Card>
                </Col>)}

            <div className='d-flex align-items-center justify-content-center mr-2 mt-5'>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                pageCount={pages}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"page-active active"}
              />
            </div>

          </Row>

        </Container>
      </section>
      <Footer />
    </>
  )
}

export default ViewAllDestination