import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../HospitalSignIn/SignIn.css'
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { toast, ToastContainer } from 'react-toastify';


function HospitalSignIn() {
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(8)
            .max(15)
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [password, setpassword] = useState("");
    const [email, setemail] = useState("");
    const [msg, setMsg] = useState("");
    const [on, setOn] = useState(false);

    let onSubmit = async (e) => {
        setOn(true)
        try {
            let res = await fetch("https://trip4cure.com/api/hospital_login/", {
                method: "POST",
                body: JSON.stringify({
                    email: email,
                    password: password
                }),
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                }
            });
            let resJson = await res.json();

            if (resJson.Active == false) {
                setOn(false)
                navigate("/verify-account/hospital");
                const functionThatReturnPromise = () => new Promise(resolve => setTimeout(resolve, 2000));
                toast.promise(
                    functionThatReturnPromise,
                    {
                        success: 'Account not verified.OTP was sent to verify your account.',
                        theme: "dark",
                    }
                );
                localStorage.setItem("registrationmail", email);
            } else if (res.status === 201) {
                setOn(false)
                if(resJson.payment_status==true){
                    navigate("/")
                }else{
                    navigate("/subscription")
                    localStorage.setItem("plan", resJson.payment_status);
                }
                //navigate("/")
                const functionThatReturnPromise = () => new Promise(resolve => setTimeout(resolve, 2000));
                toast.promise(
                    functionThatReturnPromise,
                    {
                        success: 'Hospital login successfully',
                        theme: "dark",
                    }
                );
                console.log(res);
             
                localStorage.setItem("plan", resJson.payment_status);
                localStorage.setItem("token", resJson.token);
                localStorage.setItem("user", 'hospital');
                localStorage.setItem("useremail", email);
            } else {
                setMsg(resJson.msg || resJson.error || resJson.message)
                console.log(res)
                setOn(false)
            }
        } catch (err) {
            setMsg("Something wents wrong")
            console.log(err);
            setOn(false)
        }
    }

    const [passwordType, setPasswordType] = useState("password");



    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }


    return (
        <>
            <Header />
            <ToastContainer/>
            <div className='login-reg-wrapper h-100 d-flex flex-column'>
                <Container className='my-auto'>
                    <Row>
                        <Col md={9} lg={7} xl={5} className="mx-auto">
                            <div className='bg-white shadow-md rounded p-4 px-sm-5 mt-4'>
                           
                                <p class="lead text-center">Login to Hospital account</p>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <input name="email" type="text" {...register('email')} placeholder='Enter email' onChange={(e) => setemail(e.target.value)} value={email} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.email?.message}</div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <input name="password" type={passwordType} {...register('password')} placeholder='Enter password' onChange={(e) => setpassword(e.target.value)} value={password} maxLength={'15'} className={`input-password d-inline form-control ${errors.password ? 'is-invalid' : ''}`} />
                                        <span >
                                            <button className="line-primary-btn px-md-3 py-3 submit-button mb-1" type='button' onClick={togglePassword}>
                                                {passwordType === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}</button>
                                        </span>
                                        <div className="invalid-feedback">{errors.password?.message}</div>
                                        <span style={{ color: 'red'}}>{msg}</span>
                                    
                                       
                                    </Form.Group>
                                    <Row className='mt-3'>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="Remember Me" />
                                            </Form.Group>
                                        </Col>
                                        <Col className='text-end'><Link to={'/forgot-password'}>Forgot Password ?</Link></Col>
                                    </Row>
                                    <div className='d-grid my-4'>
                                   
                                        <button  variant="primary" type="submit" disabled={on} className="line-primary-btn px-2 py-3 submit-button">Sign In</button>
                                    </div>
                                </Form>
                                <p class="text-muted text-center mb-2">Don't have an account? <Link to={'/hospital-sign-up'}>Sign Up</Link></p>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* <Container fluid className='py-3'>
                <p className="text-center text-2 text-muted mb-0">Copyright © 2023 <Link to={''}>trip4cure</Link>. All Rights Reserved.</p>
                </Container> */}

            </div>
            <Footer />
        </>
    )
}

export default HospitalSignIn;