import React, { useEffect, useState } from 'react';
import '../Header/Header.css';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import { Button, Row, Col } from 'react-bootstrap';
import axios from 'axios'
import Card from 'react-bootstrap/Card';

function Header() {

  const [lgShow, setLgShow] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    console.log('token>>>', token, "type", user)
    settoken(token);
    setuser(user);
    console.log(">>>>>>>>>>>>>>", localStorage.getItem("user"))
  }, []);


  const [token, settoken] = useState("");
  const [user, setuser] = useState("")

  const logout = () => {
    localStorage.clear();
    window.location.href = '/'
  }

  const [search, setSearch] = useState("");
  const [hospital, sethospital] = useState("");
  const [announcement, setannouncement] = useState("");
  const [guide_data, setguide_data] = useState("");
  const [destination, setdestination] = useState("");
  const [insurance, setinsurance] = useState("");
  let onSearch = async e => {
    try {
      const formData = new FormData()
      formData.append("search", search)

      const headers = {
        "Authorization": "token " + localStorage.getItem("token")
      }
      const update = await axios.post('https://trip4cure.com/api/master_search/', formData)
      const data = update.data;
      if (update.status === 200) {
        console.log(update.data)
        sethospital(data.hospital)
        setannouncement(data.announcement)
        setguide_data(data.guide_data)
        setdestination(data.destination)
        setinsurance(data.Insurance)
      }
    } catch (err) {
      console.log(err);
    }
  }
  const setMShow = () => {
    setLgShow(false);
    setSearch("");
    sethospital("")
    setannouncement("")
    setguide_data("")
    setinsurance("")
    setdestination("")
  }

  return (
    <React.Fragment>

      <Navbar expand="lg" className='nav-grad navbar-light mainmenu-nav navbar navbar-expand-lg classic transparent' style={{ height: '16vh' }}>
        <Container className='position-relative'>
          <Link to={'/'}><Navbar.Brand className='fw-bolder' to="/"><img src={`${process.env.PUBLIC_URL}/images/logo.png`} style={{ width: '90px' }} alt="" /></Navbar.Brand> </Link>

          <Row>
            <Col xs={6}>
              <Link className='d-lg-none d-xl-none d-md-block' onClick={() => setLgShow(true)}>
                <img src="https://assets-global.website-files.com/5d8aac42c851d2d6528d50d4/5e286f339f0c501c60ba7d70_Search%20Icon%20Thin.svg" className='my-1' style={{ width: '30px' }} />
              </Link>
            </Col>
            <Col xs={6}>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Col>
          </Row>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Collapse id="basic-navbar-nav" className='menubar'>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setMShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Row className='w-100 px-3'>
                  <Col md={10} xs={8} className='p-0'>
                    <input className='w-100' onChange={(e) => setSearch(e.target.value)} value={search} required style={{ height: '40px' }} />
                  </Col>
                  <Col md={2} xs={4} className='p-0'>
                    <Button style={{ height: '40px' }} className='btn btn-primary' onClick={onSearch} disabled={search == ""} >Search</Button>
                  </Col>
                </Row>

              </Modal.Header>
              <Modal.Body >
                {
                  hospital.length > 0 && <div style={{ backgroundColor: 'rgb(184, 53, 59)', color: 'white', width: '100%', padding: '10px' }}>
                    Medical Centres
                  </div>}
                {
                  hospital.length > 0 && hospital.map(hospitals => {
                    return (
                      <Link to={`../view-medical-center/${hospitals.id}`} className="sliderblock " >
                        <Card className='my-2'>
                          <Card.Body>
                            <h4>{hospitals.hospital_name}</h4>
                            {hospitals.hospital_city}, {hospitals.hospital_country}
                          </Card.Body>
                        </Card>
                      </Link>
                    )
                  }
                  )}

                {
                  destination.length > 0 && <div style={{ backgroundColor: 'rgb(184, 53, 59)', color: 'white', width: '100%', padding: '10px' }}>
                    Destinations
                  </div>}
                {
                  destination.length > 0 && destination.map(destinations => {
                    return (
                      <Link to={{ pathname: `../view-destination-centers/${destinations.country_name}` }} className="sliderblock " >
                        <Card className='my-2'>
                          <Card.Title></Card.Title>
                          <Card.Body>
                            <h4>{destinations.country_name}</h4>
                          </Card.Body>
                        </Card>
                      </Link>
                    )
                  }
                  )}

                {
                  announcement.length > 0 && <div style={{ backgroundColor: 'rgb(184, 53, 59)', color: 'white', width: '100%', padding: '10px' }}>
                    Announcements
                  </div>}
                {
                  announcement.length > 0 && announcement.map(announcements => {
                    return (
                      <Link to={{ pathname: `../view-all-announcement/announcement-detail/${announcements.id}` }} className="sliderblock " >
                        <Card className='my-2'>
                          <Card.Body>
                            <h4>{announcements.heading}</h4>
                            {announcements.short_description}
                          </Card.Body>
                        </Card>
                      </Link>
                    )
                  }
                  )}

                {
                  guide_data.length > 0 && <div style={{ backgroundColor: 'rgb(184, 53, 59)', color: 'white', width: '100%', padding: '10px' }}>
                    Medical Tourism Guide
                  </div>}
                {
                  guide_data.length > 0 && guide_data.map(data => {
                    return (
                      <Link to={{ pathname: `../view-medical-tourism-guide/view-medical-tourism-info-page/${data.id}` }} className="sliderblock " >
                        <Card className='my-2'>
                          <Card.Title></Card.Title>
                          <Card.Body>
                            <h4>{data.card_heading}</h4>
                            {data.short_description}
                          </Card.Body>
                        </Card>
                      </Link>
                    )
                  }
                  )}

                {
                  insurance.length > 0 && <div style={{ backgroundColor: 'rgb(184, 53, 59)', color: 'white', width: '100%', padding: '10px' }}>
                    Insurance, Travel Agents & Vendors
                  </div>}
                {
                  insurance.length > 0 && insurance.map(data => {
                    return (
                      <Link to={{ pathname: `../insurance-detail/${data.id}` }} className="sliderblock " >
                        <Card className='my-2'>
                          <Card.Title></Card.Title>
                          <Card.Body>
                            <h4>{data.company_name}</h4>
                            {data.short_description}
                          </Card.Body>
                        </Card>
                      </Link>
                    )
                  }
                  )}


              </Modal.Body>
            </Modal>
            <Nav className='ms-auto'>
              {token ? (<>

                <Link className='nav-link px-2 button-primary d-none d-sm-none d-md-none d-lg-block ' onClick={() => setLgShow(true)}>
                  <img src="https://assets-global.website-files.com/5d8aac42c851d2d6528d50d4/5e286f339f0c501c60ba7d70_Search%20Icon%20Thin.svg" style={{ width: '24px' }} />

                </Link>
                <Dropdown className='px-2 button-primary d-none d-sm-none d-md-none d-lg-block '>
                  <Dropdown.Toggle className='button-p '>
                    <img src={`${process.env.PUBLIC_URL}/images/user.png`} style={{ width: '24px' }} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='text-center'>
                    <Dropdown.Item className='header-link' as={Link} to={user == 'hospital' ? '/hospital-profile' : '/user-profile'}> Profile</Dropdown.Item>
                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>


                <Link className='nav-link mx-2 d-lg-none d-xl-none d-md-block' to={'/'}>Home</Link>
                <Link className='nav-link mx-2 d-lg-none d-xl-none d-md-block' to={'/contact-us'} >Contact Us</Link>

                {user == 'hospital' ? (<> <Link className='nav-link ' to={'/dashboard'}>
                  <img class="d-none d-sm-none d-md-none d-lg-block" src={`${process.env.PUBLIC_URL}/images/dashboard.png`} style={{ width: '24px' }} />
                  <span className='mx-2 d-lg-none d-xl-none d-md-block'>Dashboard</span></Link>
                </>) : <></>}
                <Dropdown className='mx-2 button-primary d-none d-sm-none d-md-none d-lg-block ' style={{ marginTop: '3px' }}>
                  <Dropdown.Toggle className='button-p'>
                    MENU
                  </Dropdown.Toggle>
                  <Dropdown.Menu >
                    <Dropdown.Item className='header-link' as={Link} to={'/'}>Home</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../view-medical-center'}>Medical Centers</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../view-medical-tourism-guide'}>Medical Tourism Guide</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../view-all-destination'}>Destinations</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../view-all-announcement'}>Latest Announcements</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../about-us'}>About Us</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../how-it-works'}>How it Works</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../pricing-refrence'}>Pricing Reference</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Link variant="primary" className="d-none d-sm-none d-md-none d-lg-block line-primary-btn px-3 py-2 mx-4 submit-button" to={'/contact-us'} >CONTACT US</Link>
                <Link className='nav-link mx-2 d-lg-none d-xl-none d-md-block' to={user == 'hospital' ? '/hospital-profile' : '/user-profile'} >Profile</Link>

                <Link className='nav-link mx-2 d-lg-none d-xl-none d-md-block' onClick={logout}>Logout</Link>



              </>) : (<>
                <Link className='nav-link mx-2 my-2 d-none d-sm-none d-md-none d-lg-block' onClick={() => setLgShow(true)}>
                  <img src="https://assets-global.website-files.com/5d8aac42c851d2d6528d50d4/5e286f339f0c501c60ba7d70_Search%20Icon%20Thin.svg" style={{ width: '20px' }} />
                </Link>
                <Link className='nav-link mx-2 d-lg-none d-xl-none d-md-block' to={'/'}>Home</Link>
                <Link className='nav-link mx-2' to={'/user-sign-in'}>User Sign In</Link>
                <Link className='nav-link mx-2' to={'/hospital-sign-in'}>Hospital Sign In</Link>
                <Dropdown className='nav-link mx-2  d-none d-sm-none d-md-none d-lg-block ' style={{ marginTop: '3px' }}>
                  <Dropdown.Toggle className='button-p'>
                    MENU
                  </Dropdown.Toggle>

                  <Dropdown.Menu >
                    <Dropdown.Item className='header-link' as={Link} to={'/'}>Home</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../view-medical-center'}>Medical Centers</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../view-medical-tourism-guide'}>Medical Tourism Guide</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../view-all-destination'}>Destinations</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../view-all-announcement'}>Latest Announcements</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../about-us'}>About Us</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../how-it-works'}>How it Works</Dropdown.Item>
                    <Dropdown.Item className='header-link' as={Link} to={'../pricing-refrence'}>Pricing Reference</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Link className='nav-link ' to={'/contact-us'} > <span className='d-lg-none d-xl-none d-md-block mx-2'> CONTACT US</span> <button variant="primary" className="d-none d-sm-none d-md-none d-lg-block line-primary-btn px-3 py-2 mx-2 submit-button">
                  CONTACT US
                </button></Link>

              </>)}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  )
}

export default Header