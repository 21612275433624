import React, { useEffect, useState, useCallback } from 'react'
import '../ViewMedicalCenter/ViewMedicalCenter.css'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import MedicalCenterInfoPages_1 from '../ViewMedicalCenterInfoPages/MedicalCenterInfo';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import ScrollToTop from '../../Utilies/ScrollTop';
import Select from 'react-select';
import ReactPaginate from "react-paginate";
import Allservices from '../../Components/services';

const ViewDestinationCenters = () => {

  const { country } = useParams()
  const [page, setpage] = useState(1)
  const [pages, setpages] = useState(1);
  const [hospitals, setHospitals] = useState([])
  const [specility, setSpecility] = useState("")

  useEffect(() => {
    fetchHospitals(page);
    fetchInsurance();
  }, []);

  const fetchHospitals = async (count) => {
    try {
      const response = await fetch('https://trip4cure.com/api/country_hospital/?page=' + count, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country_name: country })
      });

      const data = await response.json();

      setHospitals(() => ([...data.results]))
      setpages(data.count / 10)
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };


  const handlePageClick = (event) => {
    const count = event.selected + 1;
    console.log(count)
    setpage(count);
    fetchHospitals(count)
  };


  const handleSearch = async (e) => {
    console.log(specility)
    e.preventDefault()
    try {
      const response = await fetch('https://trip4cure.com/api/search_hospital/?page=' + page, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country, specility: specialty.value }),
      });

      const data = await response.json();
      setHospitals(() => (data.results))
      setpages(data.count / 10)
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };



  const [specialty, setSpecialty] = useState(null);

  const speciality = Allservices;

  const [Insurance, setInsurance] = useState([])
  const fetchInsurance = async () => {
    console.log(country)
    try {
      const response = await fetch('https://trip4cure.com/api/search_insurance/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ search_text: country })
      });

      const data = await response.json();
      setInsurance(data.insurance)

    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  return (
    <>
      <ScrollToTop />
      <Header />
      <section id='view-medical-page' className='view-medical-center-page '>
        <Container>
          <Row>
            <div className='col-md-8 mx-auto mt-5'>
              <h1 className='medical-center-heading text-center'>Medical Centers in {country}</h1>
              {/* <p className='medical-center-subheading'>Lorem ipsum dolor sit amet, Aenean hendrerit diam at sodales tempus. Sed varius magna accumsan nulla egestas, sed faucibus justo blandit. In hac habitasse platea dictumst.</p> */}
              <p className='mt-5 search-country'>Search {country} for</p>
              <Form onSubmit={handleSearch}>
                <Row>

                  <Col xs={8} md={4}>
                    <Select cclassName="form-control-filter" name='speciality' required={true} onChange={(value) => setSpecialty(value)} options={speciality} />
                  </Col>

                  <Col xs={4} md={4}>
                    <button type='submit' className="line-primary-btn px-4 py-2 btn w-60" style={{ backgroundColor: '#b8353b', color: 'white' }}><i class="fa fa-search"></i></button>
                  </Col>

                </Row>
              </Form>
              <Row>
              </Row>

              <Row className=' mb-5'>
                <div className=' pt-5  '>
                  {
                    hospitals.length > 0 ?
                      <Row className='gx-6 gy-5'>
                        {

                          hospitals.map(hospital =>
                            <Col md={5} xs={12} className='mx-md-3 px-md-3' key={hospital.id ? hospital.id : hospital.hospital.id}>
                              <Link to={`/view-medical-center/${hospital.id}`} className="sliderblock">
                                <Card className="text-center">
                                  <div className="image-block">
                                    <Card.Img variant="top" style={{ width: '100%', height: '26vh' }} src={'https://trip4cure.com' + hospital.profile_image} />
                                  </div>
                                  <Card.Body>
                                    <Card.Title><h4 className='card-heading'>{hospital.hospital_name}</h4></Card.Title>
                                    <Card.Text><p className='card-sub-heading-view-medical'>{hospital.hospital_city}</p></Card.Text>
                                    <Card.Text><p>{hospital.hospital_country} - {hospital.specialitycount} Specialties</p></Card.Text>
                                  </Card.Body>
                                </Card>
                              </Link>
                            </Col>
                          )
                        }
                      </Row>
                      :
                      <span> <p className='mt-5 text-center'>Currently no hospitals or wellness centers available.</p>
                      <p className='text-center mb-5'><Link className='red-link' to={'../view-all-destination'}>View all destinations</Link></p></span>
                  }

                </div>

                <div className='d-flex align-items-center justify-content-center mr-2 mt-5'>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={10}
                    pageCount={pages}
                    previousLabel="< Previous"
                    renderOnZeroPageCount={null}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    activeClassName={"page-active active"}
                  />
                </div>
              </Row>
            </div>
            <div className='col-md-4'>
              <div class="row m-2">
                {
                  Insurance.length > 0 && <div style={{ backgroundColor: 'rgb(184, 53, 59)', color: 'white', width: '100%', padding: '10px' }}>
                    Insurance, Travel Agents & Vendors
                  </div>}
                {
                  Insurance.map(service =>
                    <Link to={`/insurance-detail/${service.id}`} style={{ textDecoration: 'none' }}>
                      <Col md={12} xs={12} className='m-2 px-2' key={service.id}>
                        <Link to={`/insurance-detail/${service.id}`} className="sliderblock">
                          <Card className="text-center">
                            <div className="image-block">
                              <Card.Img variant="top" style={{ width: '100%', height: '28vh' }} src={service.image ? 'https://trip4cure.com' + service.image : ''} />

                            </div>
                            <Card.Body>
                              <Card.Title><h4 className='card-heading'>{service.company_name}</h4></Card.Title>
                              <Card.Text><p className='card-sub-heading-view-medical'>{service.country}</p></Card.Text>
                            </Card.Body>
                          </Card>
                        </Link>
                      </Col>
                    </Link>
                  )}
              </div>
            </div>

          </Row>

        </Container>
      </section>
      <Footer />
    </>
  )
}

export default ViewDestinationCenters