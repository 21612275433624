import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../SignIn/SignIn.css'
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { toast, ToastContainer } from 'react-toastify';
function SignIn() {
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(8)
            .max(15)
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [password, setpassword] = useState("");
    const [email, setemail] = useState("");
    const [msg, setMsg] = useState("");
    const [on, setOn] = useState(false);
    let onSubmit = async (e) => {


        try {
            let res = await fetch("https://trip4cure.com/api/user_login/", {
                method: "POST",
                body: JSON.stringify({
                    email: email,
                    password: password
                }),
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                }
            });
            let resJson = await res.json();
            console.log(resJson.Active)
            if (resJson.Active == false) {
                setOn(false)
                navigate("/verify-account/user");
                const functionThatReturnPromise = () => new Promise(resolve => setTimeout(resolve, 2000));
                toast.promise(
                    functionThatReturnPromise,
                    {
                        success: 'Account not verified.OTP was sent to verify your account.',
                        theme: "dark",
                    }
                );
                localStorage.setItem("registrationmail", email);
            } else if (res.status === 201) {
                setOn(false)
                navigate("/")
                const functionThatReturnPromise = () => new Promise(resolve => setTimeout(resolve, 2000));
                toast.promise(
                    functionThatReturnPromise,
                    {
                        success: 'User login successfully',
                        theme: "dark",
                    }
                );
                localStorage.setItem("token", resJson['token']);
                localStorage.setItem("user", 'user');
                console.log(res)

            } else {
                setMsg(resJson.msg || resJson.error || resJson.message)
                console.log(resJson)
                setOn(false)
            }
        } catch (err) {
            setMsg("Something wents wrong")
            console.log(err);
            setOn(false)
        }

    }


    const [passwordType, setPasswordType] = useState("password");



    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (
        <>
            <Header />
            <ToastContainer />
            <div className='login-reg-wrapper h-100 d-flex flex-column'>
                <Container className='my-auto'>
                    <Row>
                        <Col md={9} lg={7} xl={5} className="mx-auto">
                            <div className='bg-white shadow-md rounded p-4 px-sm-5 mt-4'>

                                <p class="lead text-center">Login to User account</p>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label type="email" >Email address</Form.Label>
                                        <input name="email" type="text" {...register('email')} placeholder='Enter email' onChange={(e) => setemail(e.target.value)} value={email} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.email?.message}</div>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label className='d-block'>Password</Form.Label>
                                        <input name="password" type={passwordType} {...register('password')} maxLength={'15'} onChange={(e) => setpassword(e.target.value)} value={password} placeholder='Enter password' className={`input-password d-inline form-control ${errors.password ? 'is-invalid' : ''}`} />
                                        <span>
                                            <button className="line-primary-btn px-md-3 py-3 submit-button mb-1" type='button' onClick={togglePassword}>
                                                {passwordType === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}</button>
                                        </span>
                                        <div className="invalid-feedback">{errors.password?.message}</div>

                                        <span style={{ color: 'red' }}>{msg}</span>
                                    </Form.Group>
                                    <Row className='mt-3'>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" label="Remember Me" />
                                            </Form.Group>
                                        </Col>
                                        <Col className='text-end'><Link to={'/forgot-password'}>Forgot Password ?</Link></Col>
                                    </Row>
                                    <div className='d-grid my-4'>
                                        <button className="line-primary-btn px-2 py-3 submit-button" disabled={on} type="submit">
                                            Sign In
                                        </button>
                                    </div>
                                </Form>
                                <p class="text-muted text-center mb-2">Don't have an account? <Link to={'/sign-up'}>Sign Up</Link></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default SignIn;