import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import axios from 'axios'
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { Col, Container, Form, Row, Card, Tab, Nav, Button } from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Allservices from '../../services'

export default function Services() {


  const [price, setPrice] = useState("");
  const [des, setDes] = useState("");
  const [priceEdit, setPriceEdit] = useState("");
  const [desEdit, setDesEdit] = useState("");
  const [allservice, setAllService] = useState([]);

  const validationSchema = Yup.object().shape({});


  const formOptions = { resolver: yupResolver(validationSchema) };
  const { handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const als  = Allservices;

  const [addService, setaddService] = useState([]);
  const fetchAddService = async () => {
    try {

      const headers = {
        "Authorization": "token " + localStorage.getItem("token")
      }
      const response = await fetch('https://trip4cure.com/api/add_get_service/', {
        headers: headers
      });

      const data = await response.json();
      setaddService(() => data.Services);
      localStorage.setItem('dataservice', JSON.stringify(data.Services));
      const speciality = als.filter(obj => !data.Services.some(item => item['service_name'] === obj['value']));

      setAllService(speciality);
      console.log(speciality)


    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  useEffect(() => {
    fetchAddService();
  }, []);

  let onSubmit = async e => {

    //console.log(speciality)
    try {


      const formData = new FormData()
      formData.append("service_name", specialty.value)
      if (price) {
        formData.append("price", price)
      }

      formData.append("service_description", des)



      const headers = {
        "Authorization": "token " + localStorage.getItem("token")
      }
      const update = await axios.post('https://trip4cure.com/api/add_get_service/', formData,
        {
          headers: headers
        })

      if (update.status === 201) {
        fetchAddService();


        toast.success('Service added successfully');
        console.log(e.target);
        setSpecialty("");
        setPrice("");
        setDes("");

      }
    } catch (err) {
      console.log(err);
    }
  }

  let onEdit = async e => {

    // console.log(speciality)
    try {


      const formData = new FormData()
      formData.append("service_id", s_id)
      formData.append("service_name", specialityEdit.value)
      formData.append("price", priceEdit)
      formData.append("service_description", desEdit)



      const headers = {
        "Authorization": "token " + localStorage.getItem("token")
      }
      const update = await axios.post('https://trip4cure.com/api/update_service/', formData,
        {
          headers: headers
        })

      if (update.status === 201) {
        fetchAddService();
        setShow(false);
        toast.success('Service updated successfully');
        console.log(e.target)

      }
    } catch (err) {
      console.log(err);
    }
  }
  const [specialty, setSpecialty] = useState(null);
  const [specialityEdit, setSpecialtyEdit] = useState(null);


  const handleRemove = e => {
    console.log(e)
    const url = `https://trip4cure.com/api/delete_service/${e.id}/`;
    const headers = {
      "Authorization": "token " + localStorage.getItem("token")
    }
    axios
      .delete(url, {
        headers: headers
      })
      .then(res => {
        console.log(res)
        toast.error('Deleted successfully');
        fetchAddService();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [show, setShow] = useState(false);
  const [s_id, sets_id] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setDesEdit(e.service_description)
    setPriceEdit(e.price)
    setSpecialtyEdit(e.service_name)
    sets_id(e.id)
    console.log(e);

  }

  return (
    <div>
      <h4 class="text-right mt-5">Services</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='row'>

          <div class="col-md-6 mb-4">
            <label className='my-2'>Speciality*</label>
            <Select className="form-control-filter" name='speciality' required={true} onChange={value => setSpecialty(value)} options={allservice} value={specialty} />
          </div>

          <div class="col-md-6 mb-4"><label>Price (Preferably in US Dollars)</label>
            <input type="text" class="form-control" maxLength={'200'} placeholder="Price" onChange={(e) => setPrice(e.target.value)} value={price} />
          </div>
        </div>

        <div class="col-md-12"><label>Description</label>
          <textarea type="text" rows={4} maxLength={4000} class="form-control" placeholder="Enter Description" onChange={(e) => setDes(e.target.value)} value={des} />

        </div>


        <div class="mt-5 text-center"><button class="btn btn-primary" variant="primary" type="submit">Add Service</button></div>
      </form>

      <Row className='my-3'>
        {
          addService.map(service =>
            <Col className='col-12 my-2'>
              <React.Fragment key={service.id}>
                <h5 className='fw-bold'>{service.service_name}</h5>
                {service.price ? <p>
                  <span className='fw-bolder'>Price :</span> {service.price}
                </p> : <></>}
                <p>
                  {service.service_description}
                </p>
                <button class="line-primary-btn px-3 py-2 submit-button" onClick={() => handleRemove(service)} >Delete</button>

                <button class="line-primary-btn px-3 py-2 submit-button mx-2" onClick={() => handleShow(service)} >Edit</button>
              </React.Fragment>
            </Col>
          )
        }
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Service</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onEdit)}>
          <Modal.Body>

            <div className='row'>

              <div class="col-md-6 ">
                <label className='my-2'>Speciality*</label>

                <Select className="form-control-filter" name='speciality' required={true} onChange={value => setSpecialtyEdit(value)} options={als} />
              </div>

              <div class="col-md-6"><label>Price(Preferably in US Dollars)</label>
                <input type="number" class="form-control" placeholder="Price" onChange={(e) => setPriceEdit(e.target.value)} value={priceEdit} />
              </div>
            </div>

            <div class="col-md-12"><label>Description</label>
              <textarea type="text" class="form-control" rows={4} placeholder="Enter Description" onChange={(e) => setDesEdit(e.target.value)} value={desEdit} />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type='submit'>
              Update
            </Button>

          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}
