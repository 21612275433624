const Allservices = [
  { value: 'Addiction Treatment', label: 'Addiction Treatment' },
  { value: 'Aesthetics', label: 'Aesthetics' },
  { value: 'Alternative Therapies', label: 'Alternative Therapies' },
  { value: 'Anesthesiology', label: 'Anesthesiology' },
  { value: 'Ayurveda Treatments', label: 'Ayurveda Treatments' },
  { value: 'Bariatric Surgery', label: 'Bariatric Surgery' },
  { value: 'Bone Marrow Transplant', label: 'Bone Marrow Transplant' },
  { value: 'Cancer Treatment', label: 'Cancer Treatment' },
  { value: 'Cardiac Surgery', label: 'Cardiac Surgery' },
  { value: 'Cardiology', label: 'Cardiology' },
  { value: 'Cardiovascular', label: 'Cardiovascular' },
  { value: 'Cosmetic Surgery', label: 'Cosmetic Surgery' },
  { value: 'Dental', label: 'Dental' },
  { value: 'Dermatology', label: 'Dermatology' },
  { value: 'Dialysis', label: 'Dialysis' },
  { value: 'Ear Nose And Throat', label: 'Ear Nose And Throat' },
  { value: 'Endocrinology', label: 'Endocrinology' },
  { value: 'Eye Surgery', label: 'Eye Surgery' },
  { value: 'Fertility Treatments', label: 'Fertility Treatments' },
  { value: 'Gastroenterology', label: 'Gastroenterology' },
  { value: 'General Surgery', label: 'General Surgery' },
  { value: 'Gynecology', label: 'Gynecology' },
  { value: 'Hair Transplant', label: 'Hair Transplant' },
  { value: 'Hematology', label: 'Hematology' },
  { value: 'Hip Replacement', label: 'Hip Replacement' },
  { value: 'Imaging', label: 'Imaging' },
  { value: 'Knee Replacement', label: 'Knee Replacement' },
  { value: 'Medical Check Ups', label: 'Medical Check Ups' },
  { value: 'Nephrology', label: 'Nephrology' },
  { value: 'Neurology', label: 'Neurology' },
  { value: 'Neuro Surgery', label: 'Neuro Surgery' },
  { value: 'Ob Gyn', label: 'Ob Gyn' },
  { value: 'Oncology', label: 'Oncology' },
  { value: 'Organ Transplant', label: 'Organ Transplant' },
  { value: 'Orthopedics', label: 'Orthopedics' },
  { value: 'Pediatrics', label: 'Pediatrics' },
  { value: 'Rehabilitation', label: 'Rehabilitation' },
  { value: 'Stem', label: 'Stem' },
  { value: 'Thyroid', label: 'Thyroid' },
  { value: 'Urology', label: 'Urology' },
  { value: 'Vascular Surgery', label: 'Vascular Surgery' },
  { value: 'Wellness', label: 'Wellness' },
  { value: 'Yoga', label: 'Yoga' },
]

export default Allservices;






