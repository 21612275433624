import Select from 'react-select';
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import Topbar from "../../Components/Topbar/Topbar";
import "./Homepage.css";
import Footer from "../../Components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import countryList from 'react-select-country-list'
import { ToastContainer } from 'react-toastify';
import ScrollToTop from '../../Utilies/ScrollTop';
import Allservices from '../../Components/services'




const speciality =  Allservices;


const testimonial = {
  dots: true,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: false,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ]
};

const afterbefore = {
  dots: false,
  autoplay: true,
  adaptiveHeight: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        dots: true,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        dots: true,
        centerMode: false,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ]
};

const home = {
  dots: false,
  autoplay: true,
  adaptiveHeight: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
};





const globalProvider = {
  dots: false,
  autoplay: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: false,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ]
};


function Homepage() {

  const navigate = useNavigate()

  const [value, setValue] = useState('')
  const [country, setCountry] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [announcement, setAnnoumcement] = useState(null);
  const [fannouncement, setFannoumcement] = useState([]);
  const [guide, setGuide] = useState([]);
  const [dest, setDest] = useState([])
  const [Insurance, setInsurance] = useState([])
  const options = useMemo(() => countryList().getData(), [])
  //console.log("c>>>>>>>>>>>>>>>>", options)

  const [hospitals, setHospitals] = useState([])
  const mtg = {
    dots: true,
    autoplay: true,
    infinite: guide.length > 2 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 1
        }
      }
    ]
  };
  const changeHandler = value => {
    console.log(value.label)
    setCountry(value.label);
    setValue(value);
  }

  const fmc = {
    dots: true,
    autoplay: true,
    infinite: fannouncement.length > 2 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '20px',
    arrows: false,
    responsive: [
      {
        breakpoint:1180,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };


  const medical = {
    dots: true,
    autoplay: true,
    infinite: hospitals.length > 2 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '20px',
    arrows: false,
    responsive: [
      {
        breakpoint:1180,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };

  const Insu = {
    dots: true,
    autoplay: true,
    infinite: Insurance.length > 2 ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '20px',
    arrows: false,
    responsive: [
      {
        breakpoint:1180,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };

  const fd = {
    dots: false,
    autoplay: true,
    infinite: dest.length > 3 ? true : false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint:1180,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1,
          width: '300px'
        }

      }
    ]
  };

  const handleSearch = useCallback((e) => {
    e.preventDefault()
    navigate(`/search/${specialty.value}/${country}`)
  }, [country, specialty, navigate])

  useEffect(() => {

console.log('>>>>>>>>>>>>>>>>>',Allservices)
    const fetchAnnoumcement = async () => {
      try {
        const response = await fetch('https://trip4cure.com/api/get_annoumcement/');

        const data = await response.json();
        setAnnoumcement(data);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    const fetchFetauredAnnoumcement = async () => {
      try {
        const response = await fetch('https://trip4cure.com/api/get_featured_announcement_blog/');

        const data = await response.json();
        setFannoumcement(() => [...data.Featured_blog]);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };


    const fetchHospitals = async () => {
      try {
        const response = await fetch('https://trip4cure.com/api/get_featured_hospital/');

        const data = await response.json();
        const hospitalsData = data.Featured_Hospital
        console.log(hospitalsData)
        setHospitals(() => [...hospitalsData]);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    const fetchInsurance = async () => {
      try {
        const response = await fetch('https://trip4cure.com/api/fetured_insurance/');

        const data = await response.json();
        const hospitalsData = data.featured_insurance
        console.log(hospitalsData)
        setInsurance(() => [...hospitalsData]);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };


    const fetchDestinations = async () => {
      try {
        const response = await fetch('https://trip4cure.com/api/get_featured_destination/');

        const data = await response.json();
        setDest(() => ([...data]));
        console.log(dest)
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    const fetchGuide = async () => {
      try {
        const response = await fetch('https://trip4cure.com/api/get_featured_tourism_guide/');

        const data = await response.json();
        setGuide(() => ([...data]))
        console.log("guide>>>>>>>>>>>>", guide);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchFetauredAnnoumcement();
    fetchGuide();
    fetchInsurance();
    fetchAnnoumcement();
    fetchHospitals();
    fetchDestinations();
  }, []);


  const [rating, setRating] = useState(0);
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);

  const handleMouseEnter = (value) => {
    setHoveredRating(value);
  };

  const handleMouseLeave = () => {
    setHoveredRating(0);
  };

  const handleClick = (value) => {
    if (rating === value) {
      setRating(0);
    } else {
      setRating(value);
    }
  };

  const handleClick1 = (value) => {
    if (rating === value) {
      setRating1(0);
    } else {
      setRating1(value);
    }
  };

  const handleClick2 = (value) => {
    if (rating === value) {
      setRating2(0);
    } else {
      setRating2(value);
    }
  };

  const divStyle = {

    background: '#f3f3f3 url(' + "https://trip4cure.com" + announcement?.Announcement?.background_image + ')',
  };

  const filterOption = (option, inputVal) => {
    const inputValue = inputVal.toLowerCase();
    const lowercaseWords = []
    lowercaseWords.push({label:option.data.label.toLowerCase(),value:option.data.value.toLowerCase()})
    const label = lowercaseWords[0]['label'];
     if(label.substring(0,1) == inputValue.substring(0,1) || inputValue == ""){
      console.log("option",label.includes(inputValue) )
      return label.includes(inputValue) 
    }
  };


  
  return (
    <>
      <ScrollToTop />
      <Topbar />
      <Header />
      <ToastContainer />
      <section id="section-01" style={{ backgroundColor: '#f3f3f3' }}  >
        <Container className="home-main-intro align-items-center" style={divStyle}>
          <Row className="align-items-center">
            <div className="banner-text pb-5  d-none d-sm-block">
              {/* <h5 className='main-heading w-lg-50 w-md-50 w-xl-50'>{announcement.Announcement.heading}</h5> */}
              <h5 className='main-heading' style={{ width: '60%' }}>Wellness and Medical Tourism for Everyone, Everywhere.</h5>
            </div>

            <div className="banner-text pb-3 d-block d-sm-none " >
              {/* <h5 className='main-heading w-100 text-center'>{announcement.Announcement.heading}</h5> */}
              <h5 className='main-heading w-100 text-center'>Wellness and Medical Tourism for Everyone, Everywhere.</h5>

              <img className='img-fluid' src={'https://trip4cure.com' + announcement?.Announcement?.background_image} />


            </div>





            <div className="form-search pb-3 pt-xl-0 pt-md-5 d-none d-sm-block from-width">
              <Form onSubmit={handleSearch}>
                <Container>
                  <Row>
                    <Col xl={4} md={4} sm={4} xs={4} className="pt-1   bg-white-transparent bdrtlb border-right position-relative rounded-left form-search-item">
                      <Form.Group className="pb-0 choose-height" controlId="formBasicEmail">
                        <Form.Label><p className='font-style'>Choose Speciality</p></Form.Label>
                        <Select className="form-control-filter" name='speciality' required={true} onChange={value => setSpecialty(value)} options={speciality} 
                     filterOption={filterOption}
                        />
                      </Form.Group>
                    </Col>&emsp;
                    <Col xl={4} md={4} sm={4} xs={4} className="pt-1 bg-white-transparent brtb position-relative rounded-right form-search-item">
                      <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label><p className='font-style'>Choose Country</p></Form.Label>
                        <Select className="form-control-filter" required={true} options={options} value={value} onChange={changeHandler}   filterOption={filterOption} />
                      </Form.Group>
                    </Col>
                    <Col xl={3} md={3} sm={3} xs={3}>
                      <button type="submit" className="line-primary-btn px-2 py-3 mx-3 w-60" style={{ backgroundColor: '#b8353b', color: 'white', borderRadius: "5px", fontSize: '1rem', border: "1px solid #b8353b" }}><i class="fa fa-search"></i>&nbsp;Search</button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>


            <div className="form-search pb-2 w-100 d-block d-sm-none">
              <Form onSubmit={handleSearch}>
                <Container>
                  <Row>
                    <Col xs={12} className=" pt-1 bg-white-transparent bdrtlb border-right position-relative rounded-left form-search-item">
                      <Form.Group className="pb-0 choose-height" controlId="formBasicEmail">
                        <Form.Label><p className='font-style'>Choose Speciality</p></Form.Label>
                        <Select className="form-control-filter" name='speciality' required={true} onChange={value => setSpecialty(value)} options={speciality} filterOption={filterOption} />
                      </Form.Group>
                    </Col>&emsp;
                    <Col xs={12} className=" pt-1 bg-white-transparent brtb position-relative rounded-right form-search-item">
                      <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label><p className='font-style'>Choose Country</p></Form.Label>
                        <Select className="form-control-filter" options={options} required={true} value={value} onChange={changeHandler} filterOption={filterOption} />
                      </Form.Group>
                    </Col>
                    <Col xs={12} className='p-0 my-4'>
                      <button type="submit" className="btn w-100 p-3 search-big-btn" style={{ backgroundColor: '#b8353b', color: 'white' }}><i class="fa fa-search"></i> Search</button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </div>

            <div className="font-size-lg  mt-xs-4 mb-2  pt-md-4 mt-lg-0 pt-lg-0"><span className="red-link">OR</span> Browse Top Specialties: <Link to={'/view-speciality'} className="red-link">View All Specialties</Link></div>

            <div className="list-inline pb-5 flex-wrap my-2 d-none d-sm-block">
              <div className="list-inline-item py-2">
                <Link to={'/treatment/Cancer Treatment'} class="card border-0 icon-box link-hover-dark-white">
                  <div class="card-body p-0">
                    <div>
                      <img src={`${process.env.PUBLIC_URL}/images/ic-cancer-treatment.png`} className="normal-state" alt="" />
                      <img src={`${process.env.PUBLIC_URL}/images/ic-cancer-treatment-white.png`} className="hover-state" alt="" />
                    </div>
                    <span class="card-text font-size-md font-weight-semibold mt-2 d-block">
                      Cancer Treatment
                    </span>
                  </div>
                </Link>
              </div>
              <div className="list-inline-item py-2">
                <Link to={'/treatment/Cardiac Surgery'} class="card border-0 icon-box link-hover-dark-white">
                  <div class="card-body p-0">
                    <div>
                      <img src={`${process.env.PUBLIC_URL}/images/ic-cardiac.png`} className="normal-state" alt="" />
                      <img src={`${process.env.PUBLIC_URL}/images/ic-cardiac-white.png`} className="hover-state" alt="" />
                    </div>
                    <span class="card-text font-size-md font-weight-semibold mt-2 d-block">
                      Cardiac Surgery
                    </span>
                  </div>
                </Link>
              </div>
              <div className="list-inline-item py-2">
                <Link to={'/treatment/Cosmetic Surgery'} class="card border-0 icon-box link-hover-dark-white">
                  <div class="card-body p-0">
                    <div>
                      <img src={`${process.env.PUBLIC_URL}/images/ic-cosmetic-surgery.png`} className="normal-state" alt="" />
                      <img src={`${process.env.PUBLIC_URL}/images/ic-cosmetic-surgery-white.png`} className="hover-state" alt="" />
                    </div>
                    <span class="card-text font-size-md font-weight-semibold mt-2 d-block">
                      Cosmetic Surgery
                    </span>
                  </div>
                </Link>
              </div>
              <div className="list-inline-item py-2">
                <Link to={'/treatment/Fertility Treatment'} class="card border-0 icon-box link-hover-dark-white">
                  <div class="card-body p-0">
                    <div>
                      <img src={`${process.env.PUBLIC_URL}/images/ic-fertility.png`} className="normal-state" alt="" />
                      <img src={`${process.env.PUBLIC_URL}/images/ic-fertility-white.png`} className="hover-state" alt="" />
                    </div>
                    <span class="card-text font-size-md font-weight-semibold mt-2 d-block">
                      Fertility Treatment
                    </span>
                  </div>
                </Link>
              </div>
              <div className="list-inline-item py-2">
                <Link to={'/treatment/Neurology'} class="card border-0 icon-box link-hover-dark-white">
                  <div class="card-body p-0">
                    <div>
                      <img src={`${process.env.PUBLIC_URL}/images/ic-neurology.png`} className="normal-state" alt="" />
                      <img src={`${process.env.PUBLIC_URL}/images/ic-neurology-white.png`} className="hover-state" alt="" />
                    </div>
                    <span class="card-text font-size-md font-weight-semibold mt-2 d-block">
                      Neurology
                    </span>
                  </div>
                </Link>
              </div>
            </div>



            <div className="list-inline  flex-wrap my-2 d-block d-sm-none ">
              <Slider {...home}>
                <div className="list-inline-item py-2">
                  <Link to={'/treatment/Cancer Treatment'} class="card border-0 icon-box link-hover-dark-white">
                    <div class="card-body p-0">
                      <div>
                        <img src={`${process.env.PUBLIC_URL}/images/ic-cancer-treatment.png`} className="normal-state" alt="" />
                        <img src={`${process.env.PUBLIC_URL}/images/ic-cancer-treatment-white.png`} className="hover-state" alt="" />
                      </div>
                      <span class="card-text font-size-md font-weight-semibold mt-2 d-block">
                        Cancer Treatment
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="list-inline-item py-2">
                  <Link to={'/treatment/Cardiac Surgery'} class="card border-0 icon-box link-hover-dark-white">
                    <div class="card-body p-0">
                      <div>
                        <img src={`${process.env.PUBLIC_URL}/images/ic-cardiac.png`} className="normal-state" alt="" />
                        <img src={`${process.env.PUBLIC_URL}/images/ic-cardiac-white.png`} className="hover-state" alt="" />
                      </div>
                      <span class="card-text font-size-md font-weight-semibold mt-2 d-block">
                        Cardiac Surgery
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="list-inline-item py-2">
                  <Link to={'/treatment/Cosmetic Surgery'} class="card border-0 icon-box link-hover-dark-white">
                    <div class="card-body p-0">
                      <div>
                        <img src={`${process.env.PUBLIC_URL}/images/ic-cosmetic-surgery.png`} className="normal-state" alt="" />
                        <img src={`${process.env.PUBLIC_URL}/images/ic-cosmetic-surgery-white.png`} className="hover-state" alt="" />
                      </div>
                      <span class="card-text font-size-md font-weight-semibold mt-2 d-block">
                        Cosmetic Surgery
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="list-inline-item py-2">
                  <Link to={'/treatment/Fertility Treatment'} class="card border-0 icon-box link-hover-dark-white">
                    <div class="card-body p-0">
                      <div>
                        <img src={`${process.env.PUBLIC_URL}/images/ic-fertility.png`} className="normal-state" alt="" />
                        <img src={`${process.env.PUBLIC_URL}/images/ic-fertility-white.png`} className="hover-state" alt="" />
                      </div>
                      <span class="card-text font-size-md font-weight-semibold mt-2 d-block">
                        Fertility Treatment
                      </span>
                    </div>
                  </Link>
                </div>
                <div className="list-inline-item py-2">
                  <Link to={'/treatment/Neurology'} class="card border-0 icon-box link-hover-dark-white">
                    <div class="card-body p-0">
                      <div>
                        <img src={`${process.env.PUBLIC_URL}/images/ic-neurology.png`} className="normal-state" alt="" />
                        <img src={`${process.env.PUBLIC_URL}/images/ic-neurology-white.png`} className="hover-state" alt="" />
                      </div>
                      <span class="card-text font-size-md font-weight-semibold mt-2 d-block">
                        Neurology
                      </span>
                    </div>
                  </Link>
                </div>
              </Slider>
            </div>
          </Row>
        </Container>
      </section>

      <section className='ab-section vh-provider d-flex align-items-center'  >
        <Container>
          <Row>
            <div className='col-md-10 mx-auto  pt-md-3 mb-3 text-center '>
              <h2 className='main-heading-page'>Happy Customers & Success Stories…</h2>
              <h5 className='main-sub-heading'>Searching for the best & affordable medical tourism destination country and hospital is not so challenging now. We do have a few success stories and happy patients recommending our service and are frequently on-boarding new hospitals.</h5>
            </div>
          </Row>
          <Row>
            <div className="col-md-11 mx-auto">

              <Slider {...afterbefore}>

                <div className='ab-image position-relative '>
                  <img className='ab-img-black ad-img' src={`${process.env.PUBLIC_URL}/images/slide_01.jpg`} alt="" />
                  <div className='content'>
                    <div className='ab-white-empty-card'></div>
                    <h4 className='ab-font-style'>Before Knee Replacement</h4>
                  </div>
                </div>
                <div className='ab-image position-relative ' >
                  <img src={`${process.env.PUBLIC_URL}/images/slide_02.jpg`} alt="" className='ad-img' />
                  <div className='content'>
                    <h4 className='ab-font-style'>After knee replacement</h4>
                  </div>
                </div>
                <div className='ab-image position-relative '>
                  <img src={`${process.env.PUBLIC_URL}/images/slide_03.jpg`} alt="" className='ad-img' />
                  <div className='content'>
                    <h4 className='ab-font-style'>In sports injury</h4>
                  </div>
                </div>
                <div className='ab-image position-relative '>
                  <img src={`${process.env.PUBLIC_URL}/images/slide_04.jpg`} alt="" className='ad-img' />
                  <div className='content'>
                    <h4 className='ab-font-style'>Recovering after surgery</h4>
                  </div>
                </div>
                <div className='ab-image position-relative '>
                  <img src={`${process.env.PUBLIC_URL}/images/slide_05.jpg`} alt="" className='ad-img' />
                  <div className='content'>
                    <h4 className='ab-font-style'>feeling much better now</h4>
                  </div>
                </div>
                <div className='ab-image position-relative '>
                  <img src={`${process.env.PUBLIC_URL}/images/slide_06.jpg`} alt="" className='ad-img' />
                  <div className='content'>
                    <h4 className='ab-font-style'>I’m cancer free now</h4>
                  </div>
                </div>
                <div className='ab-image position-relative '>
                  <img src={`${process.env.PUBLIC_URL}/images/slide_07.jpg`} alt="" className='ad-img' />
                  <div className='content'>
                    <h4 className='ab-font-style'>Pregnant</h4>
                  </div>
                </div>
                <div className='ab-image position-relative'>
                  <img src={`${process.env.PUBLIC_URL}/images/slide_11.jpg`} alt="" className='ad-img' />
                  <div className='content'>
                    <h4 className='ab-font-style'>Cancer free</h4>
                  </div>
                </div>

              </Slider>
            </div>
          </Row>
        </Container>
      </section>

      <section id="section-02" className=" featured-medical-center d-flex align-items-center vh-all">
        <Container>
          <Row>
            <div className="col-md-10 mx-auto mb-3 mt-4 text-center pym">
              <h2 className='main-heading-page'>Top Visited Medical & Wellness Centres By Our Global Patients</h2>
              <p className='main-sub-heading'>Checkout Trip4Cure’s Most recommended and suggested Medical & Wellness Centers across the globe!</p>
            </div>
          </Row>
          <Row>
            {
              hospitals.length > 0 && <Slider {...medical}>
                {
                  hospitals.map(hospital => {
                    return (
                      <div key={hospital.id}>
                        <Link to={`/view-medical-center/${hospital.id}`} className="sliderblock" >

                          <Card className="text-center mx-4 my-3">
                            <div className="image-block">
                              <Card.Img variant="top" style={{ width: '100%', height: '29vh' }} src={hospital.profile_image ? 'https://trip4cure.com' + hospital.profile_image : ''} />
                            </div>
                            <Card.Body>
                              <Card.Title className='card-heading'>{hospital.hospital_name}</Card.Title>
                              <Card.Text className='card-sub-heading-1'>{hospital.hospital_city}</Card.Text>
                              <Card.Text className='card-text'>{hospital.hospital_country} - {hospital.specialitycount} Specialties</Card.Text>
                            </Card.Body>
                          </Card>
                        </Link>

                      </div>


                    )
                  })
                }
              </Slider>
            }
          </Row>
          <Row>
            <div className="text-center">

              <div class="text-center mt-5"> <Link to={'/view-medical-center'}><button type="button" class="line-primary-btn px-4 py-2 button-home "><h6 className='btn-font'>View All Medical Centers</h6></button></Link></div>
            </div>
          </Row>
        </Container>
      </section >


      <section id="section-03" className="py-3  medical-tourism-guide  d-flex align-items-center vh-all" >
        <Container>
          <Row>
            <div className="col-md-10 mx-auto py-3 pym  text-center">
              <h2 className='main-heading-page my-5'>Tips & Tricks For Your Medical & Wellness Travel</h2>
              <h5 className='main-sub-heading'>Trip4Cure is focused to guide our customers/patients with all necessary steps and formalities needed for an international medical tourism. We assist global patients to choose their medical providers, plan travel and accommodation and keep posting travel-related news in this section.</h5>
            </div>
          </Row>
          <Row>
            {guide.length > 0 && <Slider {...mtg}>
              {
                guide.map(guides => {
                  return (
                    <div>
                        <Card className="sliderblock mgt-block my-3">
                          <Card.Body>
                            <Card.Title><h4 className='medical-card-heading' style={{ height: "40px" }}>
                              {guides.card_heading.length > 50 ?
                                `${guides.card_heading.substring(0, 50)}...` : guides.card_heading}
                            </h4></Card.Title>
                            <Card.Text>
                              <p className='medical-card-sub-heading' style={{ height: "40px" }}>
                                {guides.short_description.length > 100 ?
                                  `${guides.short_description.substring(0, 100)}...` : guides.short_description
                                }</p>
                            </Card.Text>
                            <Link to={{ pathname: `view-medical-tourism-guide/view-medical-tourism-info-page/${guides.id}` }} className="text-link" variant="link">Read More</Link>
                          </Card.Body>
                        </Card>
                      
                    </div>
                  )
                })
              }


            </Slider>}
          </Row>
          <Row>
            <div class="text-center mt-5"><Link to={'/view-medical-tourism-guide'}> <button type="button" class="line-primary-btn px-4 py-2 button-home "><h6 className='btn-font'>View All</h6></button></Link></div>
          </Row>
        </Container>
      </section>


      <section id="section-04" className="py-3 featured-destinations  d-flex align-items-center vh-all" >
        <Container>
          <Row>
            <div className="col-md-10 mb-3">
              <h2 className='main-heading-page m-center'>Top Countries & Destinations For Your Cure, Wellness</h2>
              <h5 className='main-sub-heading m-center'>Trip4Cure’s recommended destinations and countries for medical tourism and/or wellness are based on current trends, quality, cost, and customer satisfaction.</h5>
            </div>
          </Row>
          <Row>
            {dest.length > 0 && <Slider className="arrowonTopright" {...fd}>
              {dest.map(destinations => {
                return (
                  <div>
                    <Link to={{ pathname: `view-destination-centers/${destinations.country_name}` }} className="sliderblock" >
                      <Card className="sliderblock">
                        <Card.Img variant="top" style={{ width: "100%", height: '60vh' }} src={destinations.country_image ? 'https://trip4cure.com' + destinations.country_image : ''} />
                        <div className="fd-content">
                          <Card.Img className=" i-flag m-2 top-0 start-0 rounded-circle" variant="top" src={destinations.country_logo ? 'https://trip4cure.com' + destinations.country_logo : ''} />
                          <div className="fd-bottom-content">
                            <h3 className="destination-country">{destinations.country_name}</h3>
                            <h4 className="destination-centers">({destinations.Hospitalcount} Medical Centers)</h4>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </div>
                )
              })
              }

            </Slider>}
          </Row>
          <Row>
            <div className="text-center py-md-4">
              <div class="text-center mt-3"> <Link to={'/view-all-destination'}> <button type="button" class="line-primary-btn px-4 py-2 button-home "><h6 className='btn-font'>View All Destinations</h6></button></Link></div>
            </div>
          </Row>
        </Container>
      </section>

      <section id="section-05" className=" py-5 pb-3 testimonials vh-all">
        <Container>
          <Row>
            <div class="col-md-9 mx-auto py-5 text-center"><h2 className="text-white-heading main-heading-page text-white">Testimonials By Happy Customers…</h2></div>
          </Row>
          <Row>
            <div className="col-md-8 mx-auto text-center testimonial-card">
              <Slider {...testimonial}>
                <Card>
                  <Card.Img variant="top" className=" mt-3  ml-5 guest-img" src={`${process.env.PUBLIC_URL}/images/guest-testimonial-1.png`} />
                  <Card.Body>
                    <Card.Text className="mt-2">
                      <h6 className="text-white"> Your Service was excellent, I had a reply and appointment within 24 hours.If I require
                        medical assistance in the future I will most certainly consider using your platform. </h6>
                    </Card.Text>
                    <div style={{ fontSize: '2rem' }}>
                      {[1, 2, 3, 4, 5].map((value) => (
                        <span
                          key={value}
                          style={{
                            cursor: 'pointer',
                            color: value <= (hoveredRating || rating) ? 'orange' : 'gray',
                            display: 'inline-block',
                            width: '30px',
                            height: '30px',
                          }}
                          onClick={() => handleClick(value)}
                          onMouseEnter={() => handleMouseEnter(value)}
                          onMouseLeave={handleMouseLeave}
                        >
                          &#x1F7CA;
                        </span>
                      ))}
                    </div>
                    <Card.Text className="mt-5">
                      <h5 className="text-white">JULIA ROSE</h5>
                      <h6 className="text-white">From Los Angeles,Calfornia</h6>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Img variant="top" className=" mt-3  ml-5 guest-img" src={`${process.env.PUBLIC_URL}/images/guest-testimonial-1.png`} />
                  <Card.Body>
                    <Card.Text className="mt-2">
                      <h6 className="text-white"> Your Service was excellent, I had a reply and appointment within 24 hours.If I require
                        medical assistance in the future I will most certainly consider using your platform. </h6>
                    </Card.Text>
                    <div style={{ fontSize: '2rem' }}>
                      {[1, 2, 3, 4, 5].map((value) => (
                        <span
                          key={value}
                          style={{
                            cursor: 'pointer',
                            color: value <= (hoveredRating || rating1) ? 'orange' : 'gray',
                            display: 'inline-block',
                            width: '30px',
                            height: '30px',
                          }}
                          onClick={() => handleClick1(value)}
                          onMouseEnter={() => handleMouseEnter(value)}
                          onMouseLeave={handleMouseLeave}
                        >
                          &#x1F7CA;
                        </span>
                      ))}
                    </div>
                    <Card.Text className="mt-5">
                      <h5 className="text-white">JULIA ROSE</h5>
                      <h6 className="text-white">From Los Angeles,Calfornia</h6>
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Img variant="top" className=" mt-3  ml-5 guest-img" src={`${process.env.PUBLIC_URL}/images/guest-testimonial-1.png`} />
                  <Card.Body>
                    <Card.Text className="mt-2">
                      <h6 className="text-white"> Your Service was excellent, I had a reply and appointment within 24 hours.If I require
                        medical assistance in the future I will most certainly consider using your platform. </h6>
                    </Card.Text>
                    <div style={{ fontSize: '2rem' }}>
                      {[1, 2, 3, 4, 5].map((value) => (
                        <span
                          key={value}
                          style={{
                            cursor: 'pointer',
                            color: value <= (hoveredRating || rating2) ? 'orange' : 'gray',
                            display: 'inline-block',
                            width: '30px',
                            height: '30px',
                          }}
                          onClick={() => handleClick2(value)}
                          onMouseEnter={() => handleMouseEnter(value)}
                          onMouseLeave={handleMouseLeave}
                        >
                          &#x1F7CA;
                        </span>
                      ))}
                    </div>
                    <Card.Text className="mt-5">
                      <h5 className="text-white">JULIA ROSE</h5>
                      <h6 className="text-white">From Los Angeles,Calfornia</h6>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Slider>
            </div>
          </Row>
        </Container>
      </section>

      <section id="section-06" className="ab-section py-5 global-provider  d-flex align-items-center vh-all"  >
        <Container>
          <Row>
            <div className="col-md-10 mb-5">
              <h2 className='main-heading-page m-center'>Global Insurance Providers, Travel & Hospitality Vendors</h2>
              <h5 className='main-sub-heading m-center'>We plan to work with the top notch, highly rated global insurance providers, travel agencies, hotels, and airlines soon and in fact have already started on-boarding a few of these vendors.</h5>
            </div>
          </Row>
          <Row>
            {
              Insurance.length > 0 && <Slider {...Insu}>
                {
                  Insurance.map(Insurances => {
                    return (
                      <div key={Insurances.id}>
                        <Link to={`/insurance-detail/${Insurances.id}`} className="sliderblock" >

                          <Card className="text-center mx-4 my-3">
                            <div className="image-block">
                              <Card.Img variant="top" style={{ width: '100%', height: '29vh' }} src={Insurances.image ? 'https://trip4cure.com' + Insurances.image : ''} />
                            </div>
                            <Card.Body>
                              <Card.Title className='card-heading'>{Insurances.company_name}</Card.Title>
                              <Card.Text className='card-sub-heading-1'>{Insurances.country}</Card.Text>
                            </Card.Body>
                          </Card>
                        </Link>

                      </div>


                    )
                  })
                }
              </Slider>
            }
          </Row>
          <Row>
            <div className="text-center py-md-4 mt-3">
              <Link to={'/allinsurance'}> <div class="text-center mt-3"><button type="button" class="line-primary-btn mt-4 mt-md-0 px-4 py-2 button-home "><h6 className='btn-font'>View All</h6></button></div></Link>
            </div>
          </Row>
        </Container>
      </section>

      <section id="section-07" className="ab-section latest announcement d-flex align-items-center vh-all">
        <Container>
          <Row>
            <div className="col-md-10 pt-5 pt-md-0  my-2 mx-auto text-center">
              <h2 className='main-heading-page'>Latest Announcements</h2>
              <h5 className='main-sub-heading'>Trip4Cure’s Latest news, announcements, success stories, new on-boarded hospitals, vendor and partner information.</h5>
            </div>
          </Row>
          <Row className="mt-3">
            {fannouncement.length > 0 && <Slider {...fmc}>

              {fannouncement.map(announcement => {
                return (
                  <Col md={4}>
                    <Card className="border-0 position-relative blog-card ">
                      <Card.Img variant="top" className="rounded-0" style={{ width: '95%', height: "28vh" }} src={announcement.image ? 'https://trip4cure.com' + announcement.image : ''} />
                      <Card.Body>
                        <Card.Title className='card-title card-h' style={{ height: "40px" }}><h5 className='medical-card-heading'>  {announcement.heading.length > 60 ?
                          `${announcement.heading.substring(0, 60)}...` : announcement.heading
                        }</h5></Card.Title>
                        <Card.Text><p className='medical-card-sub-heading' style={{ height: "40px" }}>
                          {announcement.short_description.length > 100 ?
                            `${announcement.short_description.substring(0, 100)}...` : announcement.short_description
                          }</p></Card.Text>
                        <Link to={{ pathname: `/view-all-announcement/announcement-detail/${announcement.id}` }} className="text-link" variant="link">Read More</Link>
                      </Card.Body>
                    </Card>
                  </Col>
                )

              })}
            </Slider>
            }
          </Row>
          <Row>
            <div class="text-center"><Link to={'/view-all-announcement'}> <button type="button" class="line-primary-btn mt-5 px-4 py-2 button-home "><h6 className='btn-font'>View All Announcements</h6></button></Link></div>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Homepage;



