import React from 'react'
import '../ViewSpeciality/ViewSpeciality.css'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import ScrollToTop from '../../Utilies/ScrollTop';
import { Link, useParams } from "react-router-dom";
const ViewSpeciality = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <section className='viewspeciality-page'>
        <Container>
          <Row>
            <div className='col-md-10 text-center mt-5 mx-auto viewspeciality-header'>
              <h2 className='viewspeciality-main-heading'>All Available Specialties</h2>
              {/* <p className='viewspeciality-sub-heading'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean hendrerit diam at sodales tempus. Sed varius magna accumsan nulla egestas, sed faucibus justo blandit. In hac habitasse platea dictumst.</p> */}
            </div>
          </Row>
          <Row className='mb-5'>
            <Col md={4} className=''>
              <Link to={'/treatment/Addiction Treatment'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4  mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/addiction-treatment.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Addiction Treatment</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Anesthesiology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/anesthesiology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Anesthesiology</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} >
              <Link to={'/treatment/Aesthetics'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/aesthetics.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Aesthetics</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Ayurveda Treatments'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/Ayurveda.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Ayurveda Treatments</h5>
                </div>
              </Link>
            </Col>
            <Col md={4}>
              <Link to={'/treatment/Alternative Therapies'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/Alternative Therapies.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Alternative Therapies</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Bone Marrow Transplant'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/bone-marrow-transplant.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Bone Marrow Transplant</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Bariatric Surgery'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12  mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/bariatric-surgery.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Bariatric Surgery</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Cancer Treatment'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/cancer-treatment.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Cancer Treatment</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Cardiology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/cardiology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Cardiology</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Cardiovascular'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/Cardiovascular.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Cardiovascular</h5>
                </div>
              </Link>
            </Col>
      
            <Col md={4} className=''>
              <Link to={'/treatment/Cosmetic Surgery'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/cosmetic-surgery.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Cosmetic Surgery</h5>
                </div>
              </Link>
            </Col>

            <Col md={4} className=''>
              <Link to={'/treatment/Cardiac Surgery'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4  icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/cardiac-surgery.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Cardiac Surgery</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Dermatology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/dermatology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Dermatology</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Dialysis'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/dialysis.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Dialysis</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Dental'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/dental.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Dental</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Endocrinology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/endocrinology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Endocrinology</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Eye Surgery'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/eye-surgery.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Eye Surgery</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Ear Nose And Throat'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/ear-nose-and-throat.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Ear Nose And Throat</h5>
                </div>
              </Link>
            </Col>

            <Col md={4} className=''>
              <Link to={'/treatment/Fertility Treatments'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/fertility-treatments.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Fertility Treatments</h5>
                </div>
              </Link>
            </Col>


            <Col md={4} className=''>
              <Link to={'/treatment/Gastroenterology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/gastroenterology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Gastroenterology</h5>
                </div>
              </Link>


            </Col>

            <Col md={4} className=''>
              <Link to={'/treatment/General Surgery'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/general-surgery.png`} alt="" />
                  <h5 className='viewspeciality-heading'>General Surgery</h5>
                </div>
              </Link>
            </Col>

            <Col md={4} className=''>
              <Link to={'/treatment/Gynecology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/Gynecology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Gynecology</h5>
                </div>
              </Link>
            </Col>

            <Col md={4} className=''>
              <Link to={'/treatment/Hair Transplant'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/hair-transplant.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Hair Transplant</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Hematology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/hematology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Hematology</h5>
                </div>
              </Link>
            </Col>

            <Col md={4} className=''>
              <Link to={'/treatment/Hip Replacement'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/hip-replacement.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Hip Replacement</h5>
                </div>
              </Link>
            </Col>


            <Col md={4} className=''>
              <Link to={'/treatment/Imaging'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/imaging.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Imaging</h5>
                </div>
              </Link>
            </Col>

            <Col md={4} className=''>
              <Link to={'/treatment/Knee Replacement'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/knee-replacement.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Knee Replacement</h5>
                </div>
              </Link>

            </Col>

            <Col md={4} className=''>

              <Link to={'/treatment/Medical Check Ups'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/medical-check-ups.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Medical Check Ups</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Neurology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/neurology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Neurology</h5>
                </div>
              </Link>
            </Col>

            <Col md={4} className=''>

              <Link to={'/treatment/Neuro Surgery'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/neuro-surgery.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Neuro Surgery</h5>
                </div>
              </Link>

            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Nephrology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/nephrology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Nephrology</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Ob Gyn'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/ob-gyn.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Ob Gyn</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Oncology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/oncology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Oncology</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Organ Transplant'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/Organ Transplant.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Organ Transplant</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Orthopedics'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/orthopedics.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Orthopedics</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Pediatrics'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/pediatrics.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Pediatrics</h5>
                </div>
              </Link>
            </Col>

            <Col md={4} className=''>
              <Link to={'/treatment/Rehabilitation'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/rehabilitation.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Rehabilitation</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>

              <Link to={'/treatment/Stem'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/stem.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Stem</h5>
                </div>
              </Link>
            </Col>

               
            <Col md={4} className=''>
              <Link to={'/treatment/Thyroid'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/thyroid.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Thyroid</h5>
                </div>

              </Link>
            </Col>
            <Col md={4} className=''>
              <Link to={'/treatment/Urology'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/urology.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Urology</h5>
                </div>
              </Link>
            </Col>
            <Col md={4} className=''>

              <Link to={'/treatment/Vascular Surgery'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4  mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/vascular-surgery.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Vascular Surgery</h5>
                </div>
              </Link>
            </Col>









  
            <Col md={4} className=''>
              <Link to={'/treatment/Wellness'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4  mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/wellness.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Wellness</h5>
                </div>
              </Link>


            </Col>







        
       
       
        
            <Col md={4} className=''>
              <Link to={'/treatment/Yoga'} style={{ textDecoration: 'none' }}>
                <div className='col-md-12 mt-4 mx-auto icon-img-heading'>
                  <img className='icon-img me-3' src={`${process.env.PUBLIC_URL}/images/yoga.png`} alt="" />
                  <h5 className='viewspeciality-heading'>Yoga</h5>
                </div>
              </Link>
            </Col>





          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default ViewSpeciality