import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import { Link,useNavigate,useParams } from 'react-router-dom';
const Cancel = () => {
  const {price } = useParams()
  const [email, setemail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const user =localStorage.getItem('user');
    const token =localStorage.getItem('token')
    setemail(localStorage.getItem('useremail'));
    if(token && user=="hospital" ){
      navigate(`/cancel/${price}`)
    }else{
      navigate('/')
    }
  }, []);
  return (
    <div>
      <Header />


      <div className='subscription-wrapper'>
        <Row className='justify-content-center'>
          <Col xl={4} className='my-5'>
            <Card>
              <Card.Body>
                <Row className='justify-content-center'>

                  <Col lg={12}>

                    <div className='text-center py-3'>
                      <h1><strong> <i class="fa-regular fa-xmark"></i>CANCEL</strong></h1>
                      <div className='my-3'>
                        <span className='hrLine'></span>
                        <p className='my-2'><strong>Email Id:</strong> {email}</p>
                        <span className='hrLine'></span>
                        <p className='my-2'><strong>Price:</strong> ${price}</p>
                        <span className='hrLine'></span>
                        <p className='my-2'><strong>Plan Name:</strong> {price ==1000?'Annual Plan':price==300? 'Quarterly Plan':'Free Trial'}</p>
                        <span className='hrLine'></span>
                      </div>
                      <div>
                       <Link to={'../subscription'}><button className='btn'>TRY AGAIN</button></Link> 
                      </div>
                    </div>
                  </Col>

                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <Footer />

    </div>
  );
};

export default Cancel;