import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import ReactPaginate from "react-paginate";
import ScrollToTop from '../../Utilies/ScrollTop';

const Search = () => {

  const { speciality, country } = useParams();
  const [searchResults, setSearchResults] = useState([]);
  const [countrydata, setCountry] = useState([]);
  const [specialitydata, setSpecialty] = useState([])
  const [page, setpage] = useState(1)
  const [pages, setpages] = useState(1);

  useEffect(() => {
    console.log(country)
    setCountry(country);
    setSpecialty(speciality)


    fetchSearchResults(page);
  }, []);
  const fetchSearchResults = async (count) => {
    try {
      const response = await fetch('https://trip4cure.com/api/search_hospital/?page=' + count, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country, specility: speciality }),
      });

      const data = await response.json();
      setpages(data.count / 10)
      setSearchResults(data.results);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };


  const handlePageClick = (event) => {
    const count = event.selected + 1;
    console.log(count)
    setpage(count);
    fetchSearchResults(count)
  };
  return (

    <React.Fragment>
      <ScrollToTop/>
      <Header />
      <section className='cancer-card-page'>
        <Container>
          <Row>
            <div className='col-md-10 mx-auto mt-5 text-center '>
              <h2 className='cancer-card-main-heading'>{specialitydata} Hospitals in {countrydata}</h2>
              {/* <p className='main-sub-heading-page-fmc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean hendrerit diam at sodales tempus. Sed varius magna accumsan nulla egestas, sed faucibus justo blandit. In hac habitasse platea dictumst.</p> */}
            </div>
          </Row>
          <Row className='mx-md-5 mb-5 justify-content-around'>
            <div className='col-md-10 pt-5'>
              {
                searchResults.length > 0 ?
                  <Row className='gx-6 gy-5'>
                    {

                      searchResults.map(hospital =>
                        <Col md={5} xs={12} className='mx-md-3 px-md-3' key={hospital.id}>
                          <Link to={`/view-medical-center/${hospital.id}`} className="sliderblock">
                            <Card className="text-center">
                              <div className="image-block">
                                <Card.Img variant="top" style={{ width: '100%', height: '28vh' }} src={hospital.profile_image ? 'https://trip4cure.com' + hospital.profile_image : ''} />
                                {/* <Card.Img variant="top" src={hospital.hospital.profile_image} /> */}
                              </div>
                              <Card.Body>
                                <Card.Title><h4 className='card-heading'>{hospital.hospital_name}</h4></Card.Title>
                                <Card.Text><p className='card-sub-heading-view-medical'>{hospital.hospital_city}</p></Card.Text>
                                <Card.Text><p>{hospital.hospital_country} - {hospital.specialitycount} Specialties</p></Card.Text>
                              </Card.Body>
                            </Card>
                          </Link>
                        </Col>
                      )
                    }
                  </Row>
                  :
                 <span> <p className='mt-5 text-center'>Currently no hospitals or wellness centers available.</p>
                  <p className='text-center mb-5'><Link className='red-link' to={'../view-all-destination'}>View all destinations</Link></p></span>
              }
            </div>

            <div className='d-flex align-items-center justify-content-center mr-2 mt-5'>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                pageCount={pages}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"page-active active"}
              />
            </div>
          </Row>
        </Container>
      </section>
      <Footer />
    </React.Fragment>

  )
}

export default Search