import React, { useEffect, useState } from 'react'
import '../ViewMedicalCenter/ViewMedicalCenter.css'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import MedicalCenterInfoPages_1 from '../ViewMedicalCenterInfoPages/MedicalCenterInfo';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import './ViewMedicalCenter.css';
import ReactPaginate from "react-paginate";
import ScrollToTop from "../../Utilies/ScrollTop";


const ViewMedicalCenter = () => {
  const [hospitals, setHospitals] = useState([])

  const [page, setpage] = useState(1)
  const [pages, setpages] = useState(1)
  useEffect(() => {

    fetchHospitals(page);
  }, []);

  const fetchHospitals = async (count) => {

    try {
      const response = await fetch('https://trip4cure.com/api/all_hospital/?page=' + count, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();
      const hospitalsData = data.results
      setpages(data.count / 10)
      setHospitals(() => ([...hospitalsData]))

    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };
  const handlePageClick = (event) => {

    const count = event.selected + 1;
    console.log(count)
    setpage(count);
    fetchHospitals(count);
  };

  return (
    <>
     <ScrollToTop />
      <Header />
      <section id='view-medical-page' className='view-medical-center-page '>

        <Container>
          <Row>
            <div className='col-md-10 mx-auto mt-5 text-center'>
              <h2 className='medical-center-heading'>Medical Centers</h2>
              <h5 className='medical-center-subheading'>Checkout Trip4Cure’s Most recommended and suggested Medical & Wellness Centers across the globe!</h5>
            </div>

            <Row className='mx-md-5 mb-5 justify-content-around'>
              <div className='col-md-10 pt-5  '>
                {
                  hospitals.length > 0 ?
                    <Row className='gx-6 gy-5'>
                      {

                        hospitals.map(hospital =>
                          <Col md={5} xs={12} className='mx-md-3 px-md-3' key={hospital.id}>
                            <Link to={`/view-medical-center/${hospital.id}`} className="sliderblock">
                              <Card className="text-center ">
                                <div className="image-block">
                                  <Card.Img variant="top" style={{ width: '100%', height: '28vh' }}  src={'https://trip4cure.com' + hospital.profile_image} />
                                </div>
                                <Card.Body>
                                  <Card.Title><h4 className='card-heading'>{hospital.hospital_name}</h4></Card.Title>
                                  <Card.Text><p className='card-sub-heading-view-medical'>{hospital.hospital_city}</p></Card.Text>
                                  <Card.Text><p>{hospital.hospital_country} - {hospital.specialitycount} Specialties</p></Card.Text>
                                </Card.Body>
                              </Card>
                            </Link>
                          </Col>
                        )
                      }


                      <div className='d-flex align-items-center justify-content-center mr-2 mt-5'>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={10}
                          pageCount={pages}
                          previousLabel="< Previous"
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"page-active active"}
                        />
                      </div>
                    </Row>

                    :
                    <p className='my-5 text-center'>No hospital and/or wellness center found</p>
                }

              </div>
            </Row>
          </Row>

        </Container>
      </section>
      <Footer />
    </>
  )
}

export default ViewMedicalCenter