import React, {useState} from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../SignUp/SignUp.css'
import '../SignIn/SignIn.css'
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { toast, ToastContainer } from 'react-toastify';

function SignUp() {
    const navigate = useNavigate();
 
    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required('First name is required')
            .min(1),
        lastname: Yup.string()
            .required('Last name is required')
            .min(1),
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        password: Yup.string()
            .required('Password is required')
            .min(8)
            .max(15),
        confirmpassword: Yup.string()
            .required('Confirm Password is required')
            .min(8)
            .max(15)
            .oneOf([Yup.ref('password')], 'Passwords does not match')
    });

    const [firstname, setfirstname] = useState("");
    const [LastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [createPassword, setCreatePassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [msg, setMsg] = useState("");

    const handleInput = (e) => {
        const formattedNumber = formatNumber(e.target.value);
        setfirstname(formattedNumber);
    };

    const handleInput1 = (e) => {
        const formattedNumber = formatNumber1(e.target.value);
        setLastName(formattedNumber);
    };

    const formatNumber = (value) => {
        return value.replace(/[^A-Za-z]/ig, "")
    }

    const formatNumber1 = (value) => {
        return value.replace(/[^A-Za-z]/ig, "")
    }

    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    // function onSubmit(data) {

    //     return false;
    // }


    let onSubmit = async (e) => {
        // e.preventDefault();

        try {
            let res = await fetch("https://trip4cure.com/api/register/", {
                method: "POST",
                body: JSON.stringify({
                    first_name: firstname,
                    last_name: LastName,
                    email: email,
                    password: createPassword,
                    c_password: confirmPassword
                }),
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                }
            });
            let resJson = await res.json();
            if (res.status === 201) {
                localStorage.setItem("registrationmail", email);
                const functionThatReturnPromise = () => new Promise(resolve => setTimeout(resolve, 2000));
                toast.promise(
                    functionThatReturnPromise,
                    {               
                        success: 'User sign up successful.OTP sent on your mail!',
                        theme: "dark",
                    }
                );
                navigate("/verify-account/user");
            } else {
                setMsg(resJson.msg||resJson.error||resJson.message)
                console.log(res)
            }
        } catch (err) {
            setMsg("Something wents wrong")
            console.log(err);
        }
    };

    const [passwordType, setPasswordType] = useState("password");
    
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const [passwordTypeC, setPasswordTypeC] = useState("password");
    
    const togglePasswordC = () => {
        if (passwordTypeC === "password") {
            setPasswordTypeC("text")
            return;
        }
        setPasswordTypeC("password")
    }

    return (
     <>
        <Header />
        <ToastContainer/>
        <div className='login-reg-wrapper h-70 d-flex flex-column'>
            <Container className='my-auto'>
                <Row>
                    <Col md={9} lg={7} xl={5} className="mx-auto">
                        <div className='bg-white shadow-md rounded p-4 px-sm-5 mt-4'>
                            
                            <p class="lead text-center">User Sign Up!</p>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group className="mb-3" controlId="fullName">
                                    <Form.Label>First Name*</Form.Label>
                                    <input name="firstname" maxLength={'200'} type="text" {...register('firstname')} onChange={(e) => handleInput(e)} value={firstname} placeholder='Enter first name' className={`form-control ${errors.firstname ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.firstname?.message}</div>

                                </Form.Group>

                                <Form.Group className="mb-3" controlId="fullName">
                                    <Form.Label>Last Name*</Form.Label>
                                    <input name="lastname" type="text" maxLength={'200'} {...register('lastname')} placeholder='Enter last name' onChange={(e) => handleInput1(e)} value={LastName} className={`form-control ${errors.lastname ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.lastname?.message}</div>

                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address*</Form.Label>
                                    <input name="email" type="text" {...register('email')} placeholder='Enter email' onChange={(e) => setEmail(e.target.value)} value={email} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Set Password*</Form.Label>
                                    <input name="password" type={passwordType} {...register('password')} placeholder='Enter Password' onChange={(e) => setCreatePassword(e.target.value)} value={createPassword} className={`input-password d-inline form-control ${errors.password ? 'is-invalid' : ''}`} maxlength="15" />
                                    <span>
                                            <button className="line-primary-btn px-md-3 py-3 submit-button mb-1 "  type='button' onClick={togglePassword}>
                                                {passwordType === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}</button>
                                        </span>
                                    <div className="invalid-feedback">{errors.password?.message}</div>

                                </Form.Group>

                                <Form.Group className="mb-3" controlId="confirmPassword">
                                    <Form.Label>Confirm Password*</Form.Label>
                                    <input name="confirmpassword" type={passwordTypeC} {...register('confirmpassword')} placeholder='Confirm Password' onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} maxlength="15" className={`input-password d-inline form-control ${errors.confirmpassword ? 'is-invalid' : ''}`} />
                                    <span>
                                            <button className="line-primary-btn px-md-3 py-3 submit-button mb-1"  type='button' onClick={togglePasswordC}>
                                                {passwordTypeC === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}</button>
                                        </span>
                                    <div className="invalid-feedback">{errors.confirmpassword?.message}</div>
                                    <span style={{color:'red'}}>{msg}</span>
                                </Form.Group>
                                <div className='d-grid my-4'>
                                  
                                    <button  variant="primary" type="submit" className="line-primary-btn px-2 py-3 submit-button"> Sign Up</button>
                                </div>
                            </Form>
                            <p class="text-muted text-center mb-2">Already have an account? <Link to={'/user-sign-in'}>Log In</Link></p>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* <Container fluid className='py-3'>
                <p className="text-center text-2 text-muted mb-0">Copyright © 2023 <Link to={'/'}>trip4cure</Link>. All Rights Reserved.</p>
            </Container> */}
        </div>
        <Footer />
     </>   
    )
}
export default SignUp