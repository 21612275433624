import React, { useState, useEffect } from 'react';
import ImageUploading from "react-images-uploading";
import ImageGallery from 'react-image-gallery';
import axios from 'axios'
import { Col, Container, Form, Row, Card, Tab, Nav, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import Carousel from 'react-gallery-carousel';
export default function Gallery() {
    const [imagesupload, setImagesUpload] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const [allImg, setAllImg] = useState([]);
    const [allPic, setAllPic] = useState([]);
    const [edit, setedit] = useState(true);

    const maxNumber = 10;
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        if (imageList.length != 0) {
            setImagesUpload(true);
        }
        setImages(imageList);

        console.log(imageList)
    };


    useEffect(() => {
        fetchImages();
    }, [])

    const fetchImages = async () => {

        const headers = {
            "Authorization": "token " + localStorage.getItem("token")
        }

        try {
            const response = await fetch('https://trip4cure.com/api/add_hospital_image/', {
                headers: headers
            });

            const data = await response.json();
            console.log(data.Images[0])
          
            for (let i = 0; i < data.Images.length; i = i + 1) {
                allImg.push({
                    src: 'https://trip4cure.com' + data.Images[i]["image"],    original: 'https://trip4cure.com' + data.Images[i]["image"], thumbnail: 'https://trip4cure.com' + data.Images[i]["image"], id: data.Images[i]["id"]
                })
            }
            setAllPic(data.Images)
            //  setAllImg([])

            console.log(allImg)

        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    }

    let onSubmitImage = async e => {

        //console.log(speciality)
        try {

            const formData = new FormData()

            images.forEach(file => {
                console.log("file", file.file)
                formData.append("hospital_image", file.file);
            });
            // 
            const headers = {
                "Authorization": "token " + localStorage.getItem("token")
            }
            const update = await axios.post('https://trip4cure.com/api/add_hospital_image/', formData, {
                headers: headers
            })
            if (update.status === 201) {
                setImagesUpload(false);
                toast.success('Images uploaded successfully');
                fetchImages();
                onChange([], [])
                console.log(e.target);

            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleRemove = e => {
        console.log(e)

        const url = `https://trip4cure.com/api/delete_image/${e.id}/`;
        const headers = {
            "Authorization": "token " + localStorage.getItem("token")
        }
        axios
            .delete(url, {
                headers: headers
            })
            .then(res => {
                console.log(res)
                toast.error('Deleted successfully');
                fetchImages();
            })
            .catch(err => {
                console.log(err);
            });
    };


    return (
        <div>
            <h4 class="text-right mt-5">Images</h4>
            <button type="button" onClick={e => setedit(!edit)} class="line-primary-btn py-2 px-3 submit-button" style={{ float: 'right' }} name="btnAddMore"> <i class="fa fa-pencil" aria-hidden="true" ></i> </button>
            <div className="App">
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg"]}
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps
                    }) => (
                        // write your building UI
                        <div className="upload__image-wrapper ">
                            <button
                                type='button' my-3
                                class="btn btn-primary my-3"
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                Click here to add Image
                            </button>
                            &nbsp;
                            {imagesupload ? <button class="btn btn-primary my-3" type='button' onClick={onImageRemoveAll}>Remove all images</button> : <></>}


                            {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img src={image.data_url} alt="" width="100" />
                                    <div className="image-item__btn-wrapper">
                                        <button class="btn btn-primary m-1" type='button' onClick={() => onImageUpdate(index)}>Update</button>
                                        <button class="btn btn-primary m-1" type='button' onClick={() => onImageRemove(index)}>Remove</button>

                                    </div>


                                </div>
                            ))}
                            {imagesupload ? <button class="btn btn-primary my-3 float-right" style={{ float: 'right' }} type='button' onClick={() => onSubmitImage()}>Upload</button> : <></>}
                        </div>
                    )}
                </ImageUploading>
            </div>
            {edit && !imagesupload && allImg.length != 0 ? 
            
            // <ImageGallery items={allImg} /> 
            
            
            <Carousel images={allImg} style={{ height:'500px', width: '100%' }} />
            
            : <></>}


            <Row className='my-2'>
                {
                    (allPic && !edit &&!imagesupload) && allPic.map(img =>
                        <Col className='col-sm-4 col-md-3 text-center'>
                            <img src={`${"https://trip4cure.com" + img.image}`} style={{ width: '100%', height: "120px", padding: '5px' }}></img>
                            <button class="line-primary-btn px-md-5 py-2 submit-button"style={{ padding: '5px' }} onClick={() => handleRemove(img)} >Delete</button>
                        </Col>
                    )
                }
            </Row>
        </div>
    )
}