import React, { useState, useEffect ,useRef} from 'react'
import Select from 'react-select';
import axios from 'axios'
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { Col, Container, Form, Row, Card, Tab, Nav, Button } from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
export default function Doctors() {

    const ref = React.useRef();
    useEffect(() => {
        // fetchServices();
        fetchAddDoctors();
        setInterval(function () {
            setaddService(JSON.parse(localStorage.getItem('dataservice')));
        }, 2000);


        console.log(JSON.parse(localStorage.getItem('dataservice')));

    }, []);

    const [speciality, setSpecility] = useState("")
    const [specialityEdit, setSpecilityEdit] = useState("")
    const validationSchema = Yup.object().shape({});


    const formOptions = { resolver: yupResolver(validationSchema) };
    const {  handleSubmit } = useForm(formOptions);
    const [name, setName] = useState("");
    const [nameEdit, setNameEdit] = useState("");
    const [department, setDepartment] = useState("");
    const [departmentEdit, setDepartmentEdit] = useState("");
    const [year, setYear] = useState("");
    const [yearEdit, setYearEdit] = useState("");
    const [addService, setaddService] = useState([]);
    const [addDoctor, setaddDoctor] = useState([]);
    const [file, setFile] = useState();
    const [fileEdit, setFileEdit] = useState();
    function handleChangeImage(event) {
        console.log(event.target.files[0])
        setFile(event.target.files[0])
    }

    function handleChangeImageEdit(event) {
        console.log(event.target.files[0])
        setFileEdit(event.target.files[0])
    }


    const fetchServices = async () => {
        try {

            const headers = {
                "Authorization": "token " + localStorage.getItem("token")
            }
            const response = await fetch('https://trip4cure.com/api/add_get_service/', {
                headers: headers
            });

            const data = await response.json();

            setaddService(() => data.Services);

        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    }



    const fetchAddDoctors = async () => {
        try {

            const headers = {
                "Authorization": "token " + localStorage.getItem("token")
            }
            const response = await fetch('https://trip4cure.com/api/add_get_doctor/', {
                headers: headers
            });

            const data = await response.json();

            setaddDoctor(() => data.Doctors);

        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };


    let onSubmit = async e => {

        console.log(speciality)
        try {


            const formData = new FormData()
            formData.append("dr_name", name)
            formData.append("speciality", speciality.service_name)
            formData.append("experiance", year)
            formData.append("department", department)
            if (file) {
                formData.append("photo", file)
            }



            const headers = {
                "Authorization": "token " + localStorage.getItem("token")
            }
            const update = await axios.post('https://trip4cure.com/api/add_get_doctor/', formData,
                {
                    headers: headers
                })

            if (update.status === 201) {
                fetchAddDoctors();
                toast.success('Docter added successfully');
                console.log(e.target);
                setName("");
                setSpecility("");
                setYear("");
                setDepartment("")
                //handleChangeImageEdit(null)
                ref.current.value = ""
                setFile(e.target.value=null)

                const RESPONSE_STATUS = update.Msg
                if (RESPONSE_STATUS === 'Success image story added successfully') {

                }

            }
        } catch (err) {
            //setMsg("Something wents wrong")
            console.log(err);
        }
    }

    const handleRemove = movie => {
        console.log(movie)
        const url = `https://trip4cure.com/api/delete_doctor/${movie.id}/`;
        const headers = {
            "Authorization": "token " + localStorage.getItem("token")
        }
        axios
            .delete(url, {
                headers: headers
            })
            .then(res => {
                console.log(res)
                toast.error('Deleted successfully');
                fetchAddDoctors();
            })
            .catch(err => {
                console.log(err);
            });
    };


    const [show, setShow] = useState(false);
    const [s_id, sets_id] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setShow(true);
        setDepartmentEdit(e.department)
        setNameEdit(e.name);
        setYearEdit(e.experience)
        sets_id(e.id)
        console.log(e);

    }

    let onEdit = async e => {
        console.log(speciality)
        try {
            const formData = new FormData()
            formData.append("doctor_id", s_id)
            formData.append("name", nameEdit)
            formData.append("speciality", specialityEdit.service_name)
            formData.append("experience", yearEdit)
            formData.append("department", departmentEdit)
            if (fileEdit) {
                formData.append("photo", fileEdit)
            }
            const headers = {
                "Authorization": "token " + localStorage.getItem("token")
            }
            const update = await axios.post('https://trip4cure.com/api/update_doctor/', formData,
                {
                    headers: headers
                })

            if (update.status === 201) {
                fetchAddDoctors();
                setShow(false);
                toast.success('Doctor updated successfully');
                console.log(e.target)
            }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <div>
            <h4 class="text-right mt-5">Doctors</h4>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div class="row my-3">

                    <div class="col-md-12"><label>Name*</label>
                        <input type="text" class="form-control" required onChange={(e) => setName(e.target.value)} value={name} placeholder="Enter Doctor name" />

                    </div>

                    <div class="col-md-6 mb-4"><label class="my-2">Speciality*</label>
                        <Select required className="form-control-filter" name='speciality'
                            getOptionLabel={(option) => option.service_name}
                            getOptionValue={(option) => option.service_name}
                            onChange={(e) => setSpecility(e)}
                            options={addService}
                            value={speciality}
                            isClearable />
                    </div>

                    <div class="col-md-6 my-xs-3 my-md-1"><label>Experience*</label>
                        <input type="text" class="form-control" required onChange={(e) => setYear(e.target.value)} value={year} placeholder="Enter Experience" />

                    </div>


                    <div class="col-md-12 my-xs-4 my-md-2"><label>Department*</label>
                        <input type="text" class="form-control" required onChange={(e) => setDepartment(e.target.value)} placeholder="Enter department" value={department} />
                    </div>

                    <div class="col-md-12"><label>Photo</label>
                        <input type="file" ref={ref} onChange={handleChangeImage} class="form-control" />
                    </div>
                    <div class="mt-5 text-center"><button class="btn btn-primary " type="submit">Add Doctor</button>

                    </div>
                </div>


            </form>

            <Row>
                {
                    addDoctor.map(doctor =>
                        <Col className='col-12 my-3'>
                            <div class="cardc ">

                                <div class="justify-content-start px-3 d-none d-sm-block">
                                    <Row>
                                        <Col className='col-md-2 col-sm-12'>
                                            <div >
                                                <img class="user-img fit-image" src={doctor.photo ? `${"https://trip4cure.com" + doctor.photo}` : `${process.env.PUBLIC_URL}/images/default.jpg`} />
                                            </div></Col>
                                        <Col className='col-md-10 col-sm-12'>
                                            <div class="text-left">
                                                <p><span className='fw-bolder'>Name : </span>{doctor.name}</p>
                                                <p ><span className='fw-bolder'>Speciality : </span>{doctor.speciality}</p>
                                                <p ><span className='fw-bolder'>Department : </span>{doctor.department}</p>
                                                <p ><span className='fw-bolder'>Experience :</span> {doctor.experience}</p>
                                                <button class="line-primary-btn px-3 py-2 submit-button" onClick={() => handleRemove(doctor)} >Delete</button>
                                                <button class="line-primary-btn px-3 py-2 submit-button mx-2" onClick={() => handleShow(doctor)} >Edit</button>
                                            </div>
                                        </Col>

                                    </Row>

                                </div>

                                <Row className='d-block d-sm-none'>
                                  <Col className='col-12'>
                                      <img class="user-img fit-image " src={doctor.photo?`${"https://trip4cure.com" + doctor.photo}`:`${process.env.PUBLIC_URL}/images/default.jpg`} />
                                    </Col>
                                  <Col className='col-12'>
                                    <div class="text-left">
                                      <p><span className='fw-bolder'>Name : </span>{doctor.name}</p>
                                      <p ><span className='fw-bolder'>Speciality : </span>{doctor.speciality}</p>
                                      <p ><span className='fw-bolder'>Department : </span>{doctor.department}</p>
                                      <p ><span className='fw-bolder'>Experience :</span> {doctor.experience}</p>
                                    </div>
                                  </Col>
                                </Row>

                                
                            </div>

                        </Col>

                    )}

            </Row>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Doctor</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit(onEdit)}>
                    <Modal.Body>
                        <div class="row my-3">
                            <div class="col-md-12"><label>Name*</label>
                                <input type="text" class="form-control" required onChange={(e) => setNameEdit(e.target.value)} value={nameEdit} placeholder="Enter Doctor name" />
                            </div>

                            <div class="col-md-6"><label class="my-2">Speciality*</label>
                                <Select required className="form-control-filter" name='speciality'
                                    getOptionLabel={(option) => option.service_name}
                                    getOptionValue={(option) => option.service_name}
                                    onChange={(e) => setSpecilityEdit(e)}
                                    value={specialityEdit}
                                    options={addService} isClearable />
                            </div>

                            <div class="col-md-6"><label>Experience*</label>
                                <input type="text" class="form-control" required onChange={(e) => setYearEdit(e.target.value)} value={yearEdit} placeholder="Enter Experience" />

                            </div>


                            <div class="col-md-12"><label>Department*</label>
                                <input type="text" class="form-control" required onChange={(e) => setDepartmentEdit(e.target.value)} placeholder="Enter department" value={departmentEdit} />
                            </div>

                            <div class="col-md-12"><label>Photo</label>
                                <input type="file" onChange={handleChangeImageEdit} class="form-control" />

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            Update
                        </Button>

                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    )
}
