import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import './ViewMedicalTourismGuide.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import ReactPaginate from "react-paginate";
import ScrollToTop from "../../Utilies/ScrollTop";

const ViewMedicalTourismGuide = () => {
    useEffect(() => {
        fetchGuides(page);
    }, [])

    const [guide, setGuide] = useState()
    const [page, setpage] = useState(1)
    const [pages, setpages] = useState(1);


    const fetchGuides = async (count) => {

        try {
            const response = await fetch('https://trip4cure.com/api/all_guide/?page=' + count, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const data = await response.json();
            const hospitalsData = data.results
            setpages(data.count / 10)
            setGuide(() => ([...hospitalsData]))

        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const handlePageClick = (event) => {
        const count = event.selected + 1;
        console.log(count)
        setpage(count);
        fetchGuides(count);
    };

    return (
        <>
            <ScrollToTop />
            <Header />
            <section>
                <Container>
                    <Row>
                        <div className='col-md-9 mx-auto mt-5 text-center '>
                            <h2 className='medical-tourism-main-heading'>Medical Tourism Guide</h2>
                            <p className='main-sub-heading-page-fmc'>Trip4Cure is focused to guide our customers/patients with all necessary steps and formalities needed for an international medical tourism.</p>
                        </div>
                    </Row>
                    <Row className='gx-6 gy-5 mb-5 '>

                        {guide && guide.map(guides => {
                            return (
                                <Col md={5} xs={12} sm={12} className=' mx-md-3 px-md-5 mx-md-0 px-md-0 vh-80'>
                                    <Card className="sliderblock mgt-block">
                                        <Card.Body>
                                            <Card.Title><h4 className='medical-card-heading' style={{height:'40px'}}> {guides.card_heading.length > 60 ?
                                                `${guides.card_heading.substring(0, 60)}...` : guides.card_heading}</h4></Card.Title>
                                            <Card.Text>
                                                <h6 className='medical-card-sub-heading' style={{height:'40px'}}>{guides.short_description.length > 100 ?
                                                    `${guides.short_description.substring(0, 100)}...` : guides.short_description
                                                }</h6>
                                            </Card.Text>
                                            <Link to={{ pathname: `view-medical-tourism-info-page/${guides.id}` }}><Button className="text-link" variant="link">Read More</Button></Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })

                        }


                        <div className='d-flex align-items-center justify-content-center mr-2 mt-5'>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={10}
                                pageCount={pages}
                                previousLabel="< Previous"
                                renderOnZeroPageCount={null}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"page-active active"}
                            />
                        </div>
                    </Row>


                </Container>
            </section>
            <Footer />
        </>
    )
}

export default ViewMedicalTourismGuide