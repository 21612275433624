import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from '../../Utilies/ScrollTop';

export default function Privacy() {
    return (
        <div>
            <ScrollToTop />
            <Header />
            <Container>
                <Row>
                    <div className='mx-auto mt-5 text-center '>
                        <h2 className='view-announce-main-heading'>Privacy</h2>
                    </div>
                </Row>
                <div className='mb-5'>
                    <p>Responsible body for the listing and data processing on this website:</p>
                    <p>
                    <p><span className='fw-bolder'>
                      Trip4Cure.com</span>, (Service/Product of <span className='fw-bolder'>PuraVidaTek LLC</span>) <span class="text-capitalize">LOCATED AT: 113 TENNYSON DR,PLAINSBOR0, NJ 08536.</span></p>  
                        Trip4Cure (hereinafter referred to as "T4C") operates a platform for the provision of advertising for providers of medical services, including wellness services worldwide, either via its own website or its own mobile application. T4C is solely responsible for controlling the data and processing the information obtained via the platform or other communication between the user, business, hospital, clinic, or third party and the hospital/client/partner of the T4C service team. T4C may use the services of third parties, service providers, and affiliated companies to conduct business operations and to process data provided by users and may pass on the data for these purposes. T4C remains responsible for the information collected and shared under this Privacy Policy, except in cases where T4C cannot be held responsible for a breach of this Privacy Policy.

                    </p>

                   <h5>SSL OR TLS ENCRYPTION:</h5> 
                   <p>
                   For security reasons and to protect the transmission of confidential content that gets exchanged through T4C, our website uses SSL or TLS encryption. This means that the data you transmit via this website cannot be read by third parties. You can recognize an encrypted connection by the "https://" address line of your browser and by the lock symbol in the browser line.
                   </p>


                </div>
            </Container>
            <Footer />
        </div>
    )
}
