import React, { useState, useEffect } from "react";
import "./Topbar.css";
import { Col, Container, Nav, Row } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
// import NewsTicker from "react-advanced-news-ticker";
import { Link } from "react-router-dom";


function Topbar() {

  const [newsItems, setNewsItems] = useState([
    'Using a medical tourism facilitator.',
  ]);


  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT
      },
      'google_translate_element'
    );
  };

  useEffect(() => {
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const [annoumcement, setAnnoumcement] = useState(null);

  useEffect(() => {
    const fetchAnnoumcement = async () => {
      try {
        const response = await fetch('https://trip4cure.com/api/get_annoumcement/');

        const data = await response.json();
        setAnnoumcement(data.Announcement);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };
    fetchAnnoumcement();


    const tickerInterval = setInterval(() => {
      setNewsItems((prevItems) => [
        ...prevItems.slice(1),
        prevItems[0]
      ]);
    }, 2000);

    return () => {
      clearInterval(tickerInterval);
    };
  }, []);

  return (
    <section className="container m-mobile">

      <Row className="align-items-center">
        <Col className="col-xl-9 col-sm-12 col-xs-12">
          <div className="d-flex align-items-center">
            <div className="news-title text-center m-mobile"><img src={`${process.env.PUBLIC_URL}/images/ic-announcement.png`} alt="" /><span className=" d-md-inline-block d-none a-text" >&nbsp;Announcement</span></div>

            <div className="news-ticker">

              {annoumcement && <div className="news-item" style={{ fontWeight: 600 }}> {annoumcement.announcement}</div>}

            </div>
          </div>
        </Col>
        <Col className="col-xl-3 col-sm-12 col-xs-12 " >
          <Row>
            <Col md={7} xs={6} className="mt-md-2 ">
              <div className="navbar-other inline-block pl-mobile" style={{ textAlign: 'right' }}>
                <Nav className="nav social social-white">
                  <Link target="_blank" to={'https://twitter.com/Trip4Cure'}><i className="fa fa-twitter"></i></Link>
                  <Link target="_blank" to={'https://www.facebook.com/tripforcure'}><i className="fa fa-facebook"></i></Link>
                  <Link target="_blank" to={'https://www.linkedin.com/company/tripforcure/'}><i className="fa fa-linkedin"></i></Link>
                  <Link target="_blank" to={'https://www.instagram.com/trip4cure'}><i className="fa fa-instagram"></i></Link>
                </Nav>
              </div>
            </Col>
            <Col md={5} xs={6} className="my-md-2">
              <div style={{ fontSize: '14px', cursor: 'pointer' }} className="m-float pr-mobile">
                <div id="google_translate_element" className="inline-block " style={{ opacity: '0', position: 'absolute' }}></div>
                <img alt="Translate" src="https://assets-global.website-files.com/5d8aac42c851d2d6528d50d4/5e285b441b505959deca2406_translate%20Gray.svg" id="tran_img" style={{ width: '14px' }} />&nbsp;ENGLISH
              </div>
            </Col>
          </Row>


        </Col>
      </Row>

    </section>
  )
}

export default Topbar