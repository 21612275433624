import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Button } from 'react-bootstrap';
import ReactPaginate from "react-paginate";


const Users = () => {

    useEffect(() => {
        fetchRequests(page);
    }, []);




    const [page, setpage] = useState(1)
    const [pages, setpages] = useState(1)
    const [data, setData] = useState([]);
    const [mData, setMdata] = useState([]);
    const [stype, setstype] = useState([]);

    const fetchRequests = async (count) => {

        try {
            const response = await fetch('https://trip4cure.com/api/get_hospital_patient_request/?page=' + count, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "token " + localStorage.getItem("token")
                }
            });

            const data = await response.json();
            console.log("req>>>>>>>>>>>", data)
            setData(data.results)
            setpages(data.count / 10)
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const handlePageClick = (event) => {

        const count = event.selected + 1;
        console.log(count)
        setpage(count);
        fetchRequests(count);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (d) => {
        setShow(true);
        setMdata(d)


    }
    return (
        <>
            <div className='my-4'>
                <h2 className="page-header">
                    Patient Requests
                </h2>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card__body" style={{ overflowX: "auto" }}>
                                <table class="table table-hover">
                                    <thead class="thead-dark">
                                        <tr className='dash-tr'>
                                            <th scope="col" >Patient Name</th>
                                            <th scope="col" >Email</th>
                                            <th scope="col" >Contact no.</th>
                                            <th scope="col" >Age</th>
                                            <th scope="col" >City</th>
                                            <th scope="col" >Country</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length == 0 ? <tr><td colSpan={6} className='text-center'>No request found.</td></tr> :
                                            data.map(patient =>
                                            (

                                                <tr onClick={() => handleShow(patient)} style={{ cursor: 'pointer' }}>
                                                    <td >{patient.patient_name}</td>
                                                    <td>{patient.patient_email}</td>
                                                    <td>{patient.patient_contact_num}</td>
                                                    <td>{patient.patient_age}</td>
                                                    <td>{patient.patient_city}</td>
                                                    <td>{patient.patient_country}</td>



                                                </tr>

                                            ))}

                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Patient Detail</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Row>

                                                    <Col md={12}><span className='fw-bolder'>Name : </span>{mData.patient_name}</Col>
                                                    <Col md={12}><span className='fw-bolder'>Contact No. : </span>{mData.patient_contact_num}</Col>
                                                    <Col md={12}><span className='fw-bolder'>Email : </span>{mData.patient_email}</Col>
                                                    <Col md={12}><span className='fw-bolder'>Age : </span>{mData.patient_age}</Col>
                                                    <Col md={12}><span className='fw-bolder'>City : </span>{mData.patient_city}</Col>
                                                    <Col md={12}><span className='fw-bolder'>Country : </span>{mData.patient_country}</Col>
                                                    <Col md={12}><span className='fw-bolder'>From Date : </span>{mData.from_date}</Col>
                                                    <Col md={12}><span className='fw-bolder'>To Date : </span>{mData.to_date}</Col>
                                                    <Col md={12}><span className='fw-bolder'>Services : </span>
                                                        {mData.service_type}
                                                    </Col>
                                                    <Col md={12}><span className='fw-bolder'>Message : </span>{mData.message}</Col>

                                                </Row>


                                            </Modal.Body>
                                        </Modal>


                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-center align-items-center mt-5" >
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="Next >"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={10}
                                        pageCount={pages}
                                        previousLabel="< Previous"
                                        renderOnZeroPageCount={null}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"page-active active"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Users
