import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { useLocation } from 'react-router-dom'

import { useParams } from 'react-router-dom'
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import ScrollToTop from '../../Utilies/ScrollTop';


const InsuranceDetail = () => {
    const { id } = useParams();

    console.log(id);

    useEffect(() => {
        fetchDetail(id);
    }, [])


    const [data, setData] = useState();
    let fetchDetail = async (blog) => {
        try {
            let res = await fetch("https://trip4cure.com/api/insurance_details/", {
                method: "POST",
                body: JSON.stringify({
                    insurance_id: blog,
                }),
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                }
            });
            let resJson = await res.json();
            setData(resJson['insurance'])
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <ScrollToTop />
            <Header />
            <section>
                {data != null && <Container>
                    <Row>
                        <div className='col-md-9 mx-auto mt-5 text-center announce-card-1'>
                            <h2 className='view-announce-main-heading'>{data.company_name}</h2>
                            <p className='main-sub-heading-page-fmc'>{data.short_description}</p>
                        </div>
                    </Row>
                    <Row>
                    <div>Date : {(new Date(data.created)).toLocaleString('hi-IN')}</div>
                        <div className='col-md-7 my-5' style={{ wordWrap: 'break-word' }} >
                            <img variant="top" style={{ width: '75%', height: "35vh" }} className="rounded-0 " src={"https://trip4cure.com" + data.image} />
                            <p className='mt-2'><span className='fw-bolder'>Country : </span>{data.country}</p>
                            <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
                        </div>
                    </Row>
                </Container>}
            </section>
            <Footer />
        </>
    )
}

export default InsuranceDetail
