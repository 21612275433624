import React,{useState,useEffect} from 'react'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { useLocation } from 'react-router-dom'
import './AnnounceCard.css';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';

import { useParams } from 'react-router-dom'


const AnnounceCard = () => {
    const { id } = useParams();

    console.log(id);

    useEffect(() => {
        fetchDetail(id);
    }, [])

    
    const [data, setData] = useState();
    let fetchDetail = async (blog) => {
        try {
            let res = await fetch("https://trip4cure.com/api/announcement_blog_detail/", {
                method: "POST",
                body: JSON.stringify({
                    blog_id: blog,
                }),
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                }
            });
            let resJson = await res.json();
             setData(resJson['Blog Detail'])
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Header />
            <section>
               {data!=null && <Container>
                    <Row>
                        <div className='col-md-9 mx-auto mt-5 text-center announce-card-1'>
                            <h2 className='view-announce-main-heading'>{data.heading}</h2>
                            <p className='main-sub-heading-page-fmc'>{data.short_description}</p>
                        </div>
                    </Row>
                    <Row>
                    <div>Date : {(new Date(data.created_at)).toLocaleString('hi-IN')}</div>
                        <div className='col-md-7 my-5'  style={{wordWrap:'break-word'}} >
                        <img variant="top" style={{ width: '75%', height: "35vh" }} className="rounded-0 " src={"https://trip4cure.com"+data.image}/>
                           <p dangerouslySetInnerHTML={{ __html: data.blog_description}}></p> 
                        </div>
                    </Row>
                </Container>}
            </section>
            <Footer />
        </>
    )
}

export default AnnounceCard
