import React, { useState, useEffect } from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollToTop from '../../Utilies/ScrollTop';
import ReactPaginate from "react-paginate";
export default function ViewAllInsurance() {
  useEffect(() => {
    fetchInsurance(page);
  }, [])

  const [Insurance, setInsurance] = useState([])
  const [page, setpage] = useState(1)
  const [pages, setpages] = useState(1);


  const fetchInsurance = async (count) => {

    try {
      const response = await fetch('https://trip4cure.com/api/all_insurance/?page=' + count, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();
      console.log(data)
      const InsuranceData = data.results;
      console.log(data)
      setpages(data.count / 10)
      setInsurance(() => ([...InsuranceData]))
      console.log(Insurance.length)

    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handlePageClick = (event) => {
    const count = event.selected + 1;
    console.log(count)
    setpage(count);
    fetchInsurance(count);
  };
  return (
    <>
      <React.Fragment>
        <ScrollToTop />
        <Header />
        <section className='cancer-card-page'>
          <Container>
            <Row>
              <div className='col-md-10 mx-auto mt-5 text-center '>
                <h2 className='cancer-card-main-heading'>Global Insurance Providers, Travel & Insuranceity Vendors
                </h2>
                <p className='main-sub-heading-page-fmc'>We plan to work with the top notch, highly rated global insurance providers, travel agencies, hotels, and airlines soon and in fact have already started on-boarding a few of these vendors.</p>
              </div>
              <Row className='mx-md-5 mb-5 justify-content-around'>
                <div className='col-md-10 pt-5'>
                  {
                    Insurance.length > 0 ?
                      <Row className='gx-6 gy-5'>
                        {
                          Insurance.map(Insurances =>
                            <Col md={5} xs={12} className='mx-md-3 px-md-3' key={Insurances.id}>
                              <Link to={`/insurance-detail/${Insurances.id}`} className="sliderblock">
                                <Card className="text-center">
                                  <div className="image-block">
                                    <Card.Img variant="top"  style={{ width: '100%', height: '28vh' }}  src={Insurances.image ? 'https://trip4cure.com' + Insurances.image : ''} />

                                  </div>
                                  <Card.Body>
                                    <Card.Title><h4 className='card-heading'>{Insurances.company_name}</h4></Card.Title>
                                    <Card.Text><p className='card-sub-heading-view-medical'>{Insurances.country}</p></Card.Text>
                                  </Card.Body>
                                </Card>
                              </Link>
                            </Col>
                          )
                        }
                      </Row>
                      :
                      <p className='my-5 text-center'>Not available</p>
                  }

                </div>
              </Row>

              <div className='d-flex align-items-center justify-content-center mr-2 mt-5'>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                pageCount={pages}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"page-active active"}
              />
            </div>
            </Row>

          </Container>
        </section>
        <Footer />
      </React.Fragment>
    </>
  )
}