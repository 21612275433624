import React, { useState } from 'react'
import axios from 'axios'
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { Form} from "react-bootstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast, ToastContainer } from 'react-toastify';
export default function Password() {

    const [oldPassword, setoldPassword] = useState("");
    const [NewPassword, setNewPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [msg, setMsg] = useState("");
    const validationSchema = Yup.object().shape({

        checkpassword: Yup.string()
            .required('Current Password is required')
            .min(8)
            .max(15),
        setpassword: Yup.string()
            .required('Password is required')
            .min(8)
            .max(15),
        confirmpassword: Yup.string()
            .required('Confirm Password is required')
            .min(8)
            .max(15)
            .oneOf([Yup.ref('setpassword')], 'Passwords does not match')
    });



    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;






    let onSubmit = async e => {


        try {


            const formData = new FormData()
            formData.append("old_password", oldPassword)
            formData.append("new_password", NewPassword)
            formData.append("conf_password", confirmPassword)



            const headers = {
                "Authorization": "token " + localStorage.getItem("token")
            }
            const update = await axios.post('https://trip4cure.com/api/resetpassword/', formData,
                {
                    headers: headers
                })
            const RESPONSE_STATUS = update.msg
            //setMsg(RESPONSE_STATUS)
            console.log(update)
         
            if (update.status === 201) {
                toast.success('Password changed successfully');
                console.log(e.target);
                setNewPassword("");
                setoldPassword("");
                setconfirmPassword("");

                setMsg(RESPONSE_STATUS)
            }
        } catch (err) {
            setMsg(err.response?.data?.msg)
            console.log(err);
        }
    }


    const [passwordTypeO, setPasswordTypeO] = useState("password");

    const togglePasswordO = () => {
        if (passwordTypeO === "password") {
            setPasswordTypeO("text")
            return;
        }
        setPasswordTypeO("password")
    }


    const [passwordTypeN, setPasswordTypeN] = useState("password");

    const togglePasswordN = () => {
        if (passwordTypeN === "password") {
            setPasswordTypeN("text")
            return;
        }
        setPasswordTypeN("password")
    }


    const [passwordTypeC, setPasswordTypeC] = useState("password");

    const togglePasswordC = () => {
        if (passwordTypeC === "password") {
            setPasswordTypeC("text")
            return;
        }
        setPasswordTypeC("password")
    }






    return (
        <div>
            <h4 class="text-right mt-5">Change Password</h4>
            <Form onSubmit={handleSubmit(onSubmit)} >

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label >Current Password*</Form.Label>
                    <input name="checkpassword" type={passwordTypeO} {...register('checkpassword')} onChange={(e) => setoldPassword(e.target.value)} placeholder='Enter Current Password' value={oldPassword} maxlength="15" className={`input-password d-inline form-control ${errors.checkpassword ? 'is-invalid' : ''}`} />
                    <span>
                        <button className="line-primary-btn px-2 py-2 submit-button mb-1 " type='button' onClick={togglePasswordO}>
                            {passwordTypeO === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}</button>
                    </span>
                    <div className="invalid-feedback">{errors.checkpassword?.message}</div>
                </Form.Group>


                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label style={{display:'block'}}>New Password*</Form.Label>
                    <input name="setpassword" type={passwordTypeN} value={NewPassword} {...register('setpassword')} onChange={(e) => setNewPassword(e.target.value)} placeholder='Enter New Password' maxlength="15" className={`input-password d-inline form-control ${errors.setpassword ? 'is-invalid' : ''}`} />
                    <span>
                        <button className="line-primary-btn px-2 py-2 submit-button mb-1 " type='button' onClick={togglePasswordN}>
                            {passwordTypeN === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}</button>
                    </span>
                    <div className="invalid-feedback">{errors.setpassword?.message}</div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="confirmPassword">
                    <Form.Label>Confirm Password*</Form.Label>
                    <input name="confirmpassword" type={passwordTypeC} value={confirmPassword} {...register('confirmpassword')} onChange={(e) => setconfirmPassword(e.target.value)} placeholder='Confirm Password' maxlength="15" className={`input-password d-inline form-control ${errors.confirmpassword ? 'is-invalid' : ''}`} />
                    <span>
                        <button className="line-primary-btn px-2 py-2 submit-button mb-1 " type='button' onClick={togglePasswordC}>
                            {passwordTypeC === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}</button>
                    </span>
                    <div className="invalid-feedback">{errors.confirmpassword?.message}</div>
                </Form.Group>
                <p style={{ color: 'red' }}>{msg}</p>
                <div class="my-5 text-center"><button class="line-primary-btn py-2 px-3 submit-button" type="submit">Submit</button></div>
            </Form>
        </div>
    )
}
