import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import './ViewAnnouncement.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import ReactPaginate from "react-paginate";
import ScrollToTop from "../../Utilies/ScrollTop";
const ViewAnnouncement = () => {
    useEffect(() => {
        fetchAnnouncements(page);
    }, [])

    const [announcement, setAnnouncement] = useState()
    const [page, setpage] = useState(1)
    const [pages, setpages] = useState(1);


    const fetchAnnouncements = async (count) => {

        try {
            const response = await fetch('https://trip4cure.com/api/all_announcement_blog/?page=' + count, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const data = await response.json();
            const hospitalsData = data.results
            setpages(data.count / 10)
            setAnnouncement(() => ([...hospitalsData]))

        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const handlePageClick = (event) => {
        const count = event.selected + 1;
        console.log(count)
        setpage(count);
        fetchAnnouncements(count);
    };


    return (
        <React.Fragment>
            <ScrollToTop />
            <Header />
            <section>
                <Container>
                    <Row>
                        <div className='col-md-9 mx-auto mt-5 text-center '>
                            <h2 className='view-announce-main-heading'>Announcements</h2>
                            <p className='main-sub-heading-page-fmc'>Trip4Cure’s Latest news, announcements, success stories, new on-boarded hospitals, vendor and partner information.</p>
                        </div>
                    </Row>
                    <Row className='gx-6 gy-5 mb-5'>

                        {announcement && announcement.map(announcements => {
                            return (
                                <Col md={6} xs={12} className='px-md-5 mx-md-0 px-md-0'>
                                    <Card className="px-md-5 border-0 position-relative blog-card">
                                        <Card.Img variant="top" style={{ width: '95%', height: "35vh" }} className="rounded-0" src={`${"https://trip4cure.com" + announcements.image}`} />
                                        <Card.Body>
                                            <Card.Title className='card-title card-h'><h5 className='medical-card-heading'>{announcements.heading.length > 60 ?
                                                `${announcements.heading.substring(0, 60)}...` : announcements.heading
                                            }</h5></Card.Title>
                                            <Card.Text><p className='medical-card-sub-heading'>   {announcements.short_description.length > 100 ?
                                                `${announcements.short_description.substring(0, 100)}...` : announcements.short_description
                                            }</p></Card.Text>
                                            <Link to={{ pathname: `/view-all-announcement/announcement-detail/${announcements.id}` }} ><Button className="text-link" variant="link">Read More</Button></Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })

                        }

                        <div className='d-flex align-items-center justify-content-center mr-2 mt-5'>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next >"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={10}
                                pageCount={pages}
                                previousLabel="< Previous"
                                renderOnZeroPageCount={null}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"page-active active"}
                            />
                        </div>
                    </Row>

                </Container>
            </section>
            <Footer />
        </React.Fragment>
    )
}

export default ViewAnnouncement