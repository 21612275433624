import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from '../../Utilies/ScrollTop';


export default function PricingRefrence() {
    return (
        <>
            <ScrollToTop />
            <Header />
            <Container>
                <Row className='my-5'>
                    <div className='mx-auto  text-center '>
                        <h2 className='view-announce-main-heading'>Medical Procedure Pricing in 2022 (reference)</h2>
                    </div>
                    <div>
                        <p>
                            The cost and quality of care is one of the most important factors for choosing right medical tourism destination. Through our collaborations and research, we have listed the prices of major surgical procedures in key medical tourism destinations throughout the world.
                        </p>
                        <p>
                            The pricing data are quoted from year 2022 and will vary based on the hospital you request and existing medical condition of the patient as well. Patients can use this as a reference.
                        </p>
                        <p>
                            This list is just a sub-set of all medical procedures/surgeries. Most of our listed hospitals cover beyond these procedures and you can get a quote from them if you are a registered user.
                        </p>

                    </div>


                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Surgery</th>
                                <th scope="col">US</th>
                                <th scope="col">South America</th>
                                <th scope="col">Asia</th>
                                <th scope="col">India</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Coronary Artery Bypass Graft (CABG)</th>
                                <td>$40,000 - $200,000</td>
                                <td>$15,000 - $60,000</td>
                                <td>$15,000 - $60,000</td>
                                <td>$7,000 - $15,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Hip Replacement Surgery</th>
                                <td>$30,000 - $60,000</td>
                                <td>$10,000 - $25,000</td>
                                <td>$10,000 - $30,000</td>
                                <td>$4,500 - $8,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Knee Replacement Surgery</th>
                                <td >$25,000 - $50,000</td>
                                <td>$8,000 - $20,000	</td>
                                <td>$8,000 - $25,000	</td>
                                <td>$4,000 - $7,500</td>
                            </tr>

                            <tr>
                                <th scope="row">Spinal Fusion Surgery</th>

                                <td>$50,000 - $150,000</td>
                                <td>$10,000 - $40,000</td>
                                <td>	$10,000 - $40,000</td>
                                <td>$5,000 - $10,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Hysterectomy</th>
                                <td >$8,000 - $20,000</td>
                                <td>$5,000 - $15,000</td>
                                <td>	$4,000 - $12,000</td>
                                <td>	$2,500 - $5,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Appendectomy</th>
                                <td >$10,000 - $30,000</td>
                                <td>$3,000 - $10,000</td>
                                <td>$3,000 - $8,000</td>
                                <td>$1,500 - $3,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Cataract Surgery</th>
                                <td >$3,000 - $6,000 per eye</td>
                                <td>$1,500 - $4,000 per eye</td>
                                <td>$1,000 - $4,000 per eye</td>
                                <td>$500 - $1,000 per eye</td>
                            </tr>
                            <tr>
                                <th scope="row">Prostatectomy</th>
                                <td >$10,000 - $30,000</td>
                                <td>	$5,000 - $15,000</td>
                                <td>$4,000 - $10,000</td>
                                <td>$3,000 - $6,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Breast Augmentation</th>
                                <td >$5,000 - $10,000</td>
                                <td>$3,000 - $8,000</td>
                                <td>$3,000 - $8,000</td>
                                <td>	$2,500 - $4,500</td>
                            </tr>
                            <tr>
                                <th scope="row">Breast Implants</th>
                                <td >$6,000 - $10,000</td>
                                <td>$4,000 - $8,000</td>
                                <td>$3,000 - $8,000</td>
                                <td>$5,000 - $8,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Gastric Bypass Surgery	</th>
                                <td >$20,000 - $35,000</td>
                                <td>$10,000 - $25,000</td>
                                <td>$8,000 - $25,000</td>
                                <td>$6,000 - $12,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Cesarean Section (C-Section)</th>
                                <td >$15,000 - $30,000	</td>
                                <td>$6,000 - $15,000</td>
                                <td>$5,000 - $12,000</td>
                                <td>$2,500 - $5,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Gallbladder Removal (Cholecystectomy)</th>

                                <td>$10,000 - $25,000</td>
                                <td>$4,000 - $10,000</td>
                                <td>$3,000 - $8,000	</td>
                                <td>$1,500 - $3,500</td>
                            </tr>
                            <tr>
                                <th scope="row">Total Mastectomy </th>
                                <td >$8,000 - $20,000</td>
                                <td>$4,000 - $12,000</td>
                                <td>$3,000 - $10,000</td>
                                <td>$1,500 - $5,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Prostate Surgery</th>
                                <td >$10,000 - $25,000</td>
                                <td>$5,000 - $12,000</td>
                                <td>$4,000 - $10,000</td>
                                <td>$2,000 - $5,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Carpal Tunnel Release</th>
                                <td >$5,000 - $10,000</td>
                                <td>$2,000 - $6,000</td>
                                <td>$1,000 - $4,000</td>
                                <td>	$500 - $2,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Angioplasty</th>
                                <td >$25,000 - $40,000</td>
                                <td>$5,000 - $12,000</td>
                                <td>$8,000 - $13,000</td>
                                <td>$5,500 - $6,200</td>
                            </tr>
                            <tr>
                                <th scope="row">Dental Implant (Basic)</th>
                                <td >$2,000 -$5,000</td>
                                <td>$700 - $1,000</td>
                                <td>$1,000 - $3,000</td>
                                <td>$1,000 - $3,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Face Lift</th>
                                <td >$10,000 - $20,000</td>
                                <td>$4,000 - $7,000	</td>
                                <td>$2,000 - $8,000</td>
                                <td>$6,500 - $8,000</td>
                            </tr>
                            <tr>
                                <th scope="row">IVF Treatment</th>
                                <td >$12,000 - $18,000</td>
                                <td>$6,000 - $13,000</td>
                                <td>$5,000 - $9,000</td>
                                <td>$5,500 - $7,000</td>
                            </tr>
                            <tr>
                                <th scope="row">Yoga & Ayurveda Treatments</th>
                                <td >N/A</td>
                                <td >N/A</td>
                                <td >N/A</td>
                                <td >Wide ranges</td>
                            </tr>
                        </tbody>
                    </table>











                </Row>
            </Container>
            <Footer />
        </>
    )
}
