import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Card, Button } from "react-bootstrap";
import { Link, useParams, useLocation } from "react-router-dom";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import './Treatment.css'
import ScrollToTop from '../../Utilies/ScrollTop';

const CancerTreatment = () => {
  const { speciality } = useParams();
  const [hospitals, setHospitals] = useState("")
  const location = useLocation();

  useEffect(() => {
    fetchSearchResults();
  }, [location]);


  const fetchSearchResults = async () => {
    try {
      const response = await fetch('https://trip4cure.com/api/search_services/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ service_name: speciality })
      });

      const hospitalsData = await response.json();

      setHospitals(() => ([...hospitalsData.results]));

    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };
  return (
    <React.Fragment>
      <ScrollToTop />
      <Header />
      <section className='cancer-card-page'>
        <Container>
          <Row>
            <div className='col-md-10 mx-auto mt-5 text-center '>
              <h2 className='cancer-card-main-heading'>{speciality} Hospitals</h2>

            </div>
            <Row className='mx-md-5 mb-5 justify-content-around'>
              <div className='col-md-10 pt-5'>
                {
                  hospitals.length > 0 ?
                    <Row className='gx-6 gy-5'>
                      {

                        hospitals.map(hospital =>
                          <Col md={5} xs={12} className='mx-md-3 px-md-3' key={hospital.id}>
                            <Link to={`/view-medical-center/${hospital.id}`} className="sliderblock">
                              <Card className="text-center">
                                <div className="image-block">
                                  <Card.Img variant="top" style={{ width: '100%', height: '28vh' }} src={hospital.profile_image ? 'https://trip4cure.com' + hospital.profile_image : ''} />
                                  {/* <Card.Img variant="top" src={hospital.hospital.profile_image} /> */}
                                </div>
                                <Card.Body>
                                  <Card.Title><h4 className='card-heading'>{hospital.hospital_name}</h4></Card.Title>
                                  <Card.Text><p className='card-sub-heading-view-medical'>{hospital.hospital_city}</p></Card.Text>
                                  <Card.Text><p>{hospital.hospital_country} - {hospital.specialitycount} Specialties</p></Card.Text>
                                </Card.Body>
                              </Card>
                            </Link>
                          </Col>
                        )
                      }
                    </Row>
                    :
                    <p className='my-5 text-center'>No hospital and/or wellness center found</p>
                }

              </div>
            </Row>
          </Row>

        </Container>
      </section>
      <Footer />
    </React.Fragment>
  )
}

export default CancerTreatment