import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../SignUp/SignUp.css'
import '../SignIn/SignIn.css'
import '../ContactUS/ContactUS.css'
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import { toast, ToastContainer } from 'react-toastify';
import ScrollToTop from '../../Utilies/ScrollTop'
const ContactUS = () => {
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required('First name is required')
            .min(1),
        lastname: Yup.string()
            .required('Last name is required')
            .min(1),
        country: Yup.string()
            .required('Country name is required')
            .min(2),
        city: Yup.string()
            .required('City name is required')
            .min(2),
        contactnumber: Yup.string()
            .required('Contact number is required')
            .min(5),
        email: Yup.string()
            .required('Email is required')
            .email('Email is invalid'),
        message: Yup.string()
            .required('Message is required')
            .min(10),
    });

    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userCountry, setUserCountry] = useState("");
    const [userCity, setUserCity] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [msg, setMsg] = useState("");

    const handleFirstName = (e) => {
        const formattedNumber = formatNumber(e.target.value);
        setUserFirstName(formattedNumber);
    };
    const handleLastName = (e) => {
        const formattedNumber = formatNumber(e.target.value);
        setUserLastName(formattedNumber);
    };

    const handleCountry = (e) => {
        const formattedNumber = formatNumber(e.target.value);
        setUserCountry(formattedNumber);
    };

    const handleCity = (e) => {
        const formattedNumber = formatNumber(e.target.value);
        setUserCity(formattedNumber);
    };

    const handlePhone = (e) => {
        const formattedNumber = NotAlph(e.target.value);
        setUserPhone(formattedNumber);
    };



    const formatNumber = (value) => {
        return value.replace(/[^A-Za-z]/ig, "")
    }


    const NotAlph = (value) => {
        return value.replace(/[^0-9]/ig, "")
    }
    const [submitButton, setSubmitButton] = useState(true);
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    let onSubmit = async (e) => {
        setSubmitButton(false)
        try {


            let res = await fetch("https://trip4cure.com/api/contact_us_api/", {
                method: "POST",
                body: JSON.stringify({
                    first_name: userFirstName,
                    last_name: userLastName,
                    country: userCountry,
                    email: email,
                    city: userCity,
                    contact_no: userPhone,
                    massage: message
                }),
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                }
            });
            let resJson = await res.json();
            if (res.status === 201) {
                setSubmitButton(true);
                const functionThatReturnPromise = () => new Promise(resolve => setTimeout(resolve, 2000));
                toast.promise(
                    functionThatReturnPromise,
                    {
                        success: 'Contact Us submitted successfully',
                        theme: "dark",
                    }
                );
                navigate("/");
                console.log(res)

            } else {
                setMsg(resJson.msg || resJson.error || resJson.message)
                console.log(res)
            }
        } catch (err) {
            setMsg("Something wents wrong")
            console.log(err);
        }
    }
    return (
        <>
            <ScrollToTop />
            <Header />
            <ToastContainer />
            <div className='login-reg-wrapper h-70 d-flex flex-column'>
                <Container className='my-auto'>
                    <Row>
                        <Col md={9} lg={8} xl={6} className="mx-auto">
                            <div className='bg-white shadow-md rounded p-4 px-sm-5 mt-4'>
                                <p class="lead text-center">Contact us</p>
                                <p class="text-center" style={{color: 'blue'}}>Contact us for onboarding your hospital or insurance or other travel related businesses.</p>
                                <Form onSubmit={handleSubmit(onSubmit)}>

                                    <div className='row'>
                                        <div className='col-md-6 cl-xs-12'>
                                            <Form.Group className="mb-3" controlId="fullName">
                                                <Form.Label>First Name*</Form.Label>
                                                <input name="firstname" type="text" {...register('firstname')} maxLength={'200'} onChange={(e) => handleFirstName(e)} value={userFirstName} placeholder='Enter first name' className={`form-control ${errors.firstname ? 'is-invalid' : ''}`} />
                                                <div className="invalid-feedback">{errors.firstname?.message}</div>
                                            </Form.Group>
                                        </div>
                                        <div className='col-md-6 cl-xs-12'>
                                            <Form.Group className="mb-3" controlId="fullName">
                                                <Form.Label>Last Name*</Form.Label>
                                                <input name="lastname" type="text" {...register('lastname')} maxLength={'200'} placeholder='Enter last name' onChange={(e) => handleLastName(e)} value={userLastName} className={`form-control ${errors.lastname ? 'is-invalid' : ''}`} />
                                                <div className="invalid-feedback">{errors.lastname?.message}</div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 cl-xs-12'>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Email*</Form.Label>
                                                <input name="email" type="text" {...register('email')} onChange={(e) => setEmail(e.target.value)} value={email} placeholder='Enter email' className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                                <div className="invalid-feedback">{errors.email?.message}</div>
                                            </Form.Group>
                                        </div>
                                        <div className='col-md-6 cl-xs-12'>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Contact Number*</Form.Label>
                                                <input name="contactnumber" type="text" {...register('contactnumber')} maxLength={'20'} onChange={(e) => handlePhone(e)} value={userPhone} placeholder='Enter contact number' className={`form-control ${errors.contactnumber ? 'is-invalid' : ''}`} />
                                                <div className="invalid-feedback">{errors.contactnumber?.message}</div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 cl-xs-12'>
                                            <Form.Group className="mb-3" controlId="fullName">
                                                <Form.Label>Country*</Form.Label>
                                                <input name="country" type="text" {...register('country')} maxLength={'200'} onChange={(e) => handleCountry(e)} value={userCountry} placeholder='Enter country name' className={`form-control ${errors.country ? 'is-invalid' : ''}`} />
                                                <div className="invalid-feedback">{errors.country?.message}</div>
                                            </Form.Group>
                                        </div>

                                        <div className='col-md-6 cl-xs-12'>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>City*</Form.Label>
                                                <input name="city" type="text" {...register('city')} maxLength={'200'} onChange={(e) => handleCity(e)} value={userCity} placeholder='Enter city name' className={`form-control ${errors.city ? 'is-invalid' : ''}`} />
                                                <div className="invalid-feedback">{errors.city?.message}</div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <Form.Group className="mb-4" controlId="formBasicPassword">
                                        <Form.Label>Message*</Form.Label>
                                        <textarea rows={4} name="message" type="text" {...register('message')} maxLength={'4000'} onChange={(e) => setMessage(e.target.value)} value={message} placeholder='Enter Message' className={`form-control ${errors.message ? 'is-invalid' : ''}`} />
                                        <div className="invalid-feedback">{errors.message?.message}</div>

                                    </Form.Group>

                                    <div className='d-grid my-4'>

                                        <button className="line-primary-btn px-2 py-3 submit-button" disabled={!submitButton} type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </Form>

                            </div>

                            <div>
                                <p style={{ color: 'blue',fontSize:'12px' }}>
                                    For on-boarding hospitals, wellness centers, vendor or partnership opportunities, other inquiries contact:
                                </p>
                                <p style={{ color: 'red',fontSize:'12px'  }}>
                                    Mr. Preshit  Dalal (Founder/Technologist) | <a href='mailto:contact@trip4cure.com'>contact@trip4cure.com</a> | <a href='tel:1-7322078256'>1-7322078256</a> (USA)
                                </p>
                                <p style={{ color: 'red' ,fontSize:'12px' }}>
                                    Mr. Pratik | <a href='mailto:pratik@puravidasportz.com'>pratik@puravidasportz.com</a> | <a href='tel:+919004094336'>+91-9004094336</a> (INDIA)
                                </p>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default ContactUS