import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from '../../Utilies/ScrollTop';
export default function About() {
    return (
        <>
            <ScrollToTop />
            <Header />
            <Container>
                <Row>
                    <div className='mx-auto mt-5 text-center '>
                        <h2 className='view-announce-main-heading'>About Us</h2>
                    </div>
                </Row>

                <div className='mb-5'>
                    <img src={`${process.env.PUBLIC_URL}/images/founder.jpg`} style={{ width: '200px',float:'left' }} className='mb-2 mx-4' />
                    
                    <p className='my-md-5'>
                        Our founder, Mr. Preshit Dalal’s search for a second medical opinion & an alternative treatment option for his existing personal health condition gave birth to this idea of Trip4Cure.com, a quest to build an e-platform for Medical & Wellness Tourism Service.
                    </p >
                    <p className='my-md-5 pb-md-5'>
                        In an era where global healthcare options are more accessible than ever before, medical and wellness tourism has emerged as a flourishing industry. For us, an entrepreneurial spirit, and a passion for improving lives, starting a medical and wellness tourism service is a rewarding endeavor.
                    </p>
                    <h5>Why Medical & Wellness Tourism?</h5>
                    <p >
                        Medical and wellness tourism offers a unique blend of healthcare and travel, catering to individuals seeking specialized medical treatments, elective procedures, or rejuvenating wellness experiences in foreign destinations. The allure lies in providing high-quality healthcare services coupled with the exploration of vibrant cultures, scenic landscapes, and relaxation – all at a competitive cost.
                    </p>
                    <h5>Understanding the Market</h5>
                    <p>
                        The first step in establishing your medical and wellness tourism service is to gain a profound understanding of the market. Research key trends, target demographics, and popular medical destinations. Are you drawn to offering cosmetic surgery packages in South Korea, dental procedures in Mexico, or wellness retreats in Bali? Identifying your niche is essential.
                    </p>
                    <h5>Building Partnerships</h5>
                    <p>
                        A successful medical and wellness tourism service relies heavily on partnerships. Collaborate with internationally acclaimed hospitals, clinics, wellness centers, and local tour operators. Establishing trust and credibility with these partners is paramount, as their reputation will be intertwined with your services.
                    </p>
                    <h5>Legal and Regulatory Considerations</h5>
                    <p>
                        Navigating the legal and regulatory landscape is crucial. Different countries have varying requirements for medical and wellness tourism operators. Ensure that you comply with all licensing, insurance, and accreditation standards. Ethical and legal considerations are essential when dealing with healthcare and client safety.
                    </p>
                    <h5>Creating Tailored Packages</h5>
                    <p>
                        Crafting enticing packages is the heart of your business. Develop comprehensive offerings that include medical consultations, treatments, accommodation, travel arrangements, and cultural experiences. Personalization is key – cater to the unique needs and preferences of your clients.
                    </p>
                    <h5>Online Presence and Marketing</h5>
                    <p>
                        In today's digital age, a strong online presence is indispensable. Create an appealing website that showcases your services, testimonials, and destination highlights. Invest in digital marketing strategies, including social media, search engine optimization (SEO), and pay-per-click advertising, to reach potential clients worldwide.
                    </p>
                    <h5>Customer Care and Support</h5>
                    <p>
                        Exceptional customer care is non-negotiable. With registered hospitals & patients, we will provide 24/7 support to assist clients before, during, and after their trips. We understand - Transparency, clear communication, and empathy are the pillars of building lasting relationships with our clients.
                    </p>
                    <h5>Safety and Quality Assurance</h5>
                    <p>
                        We ensure that our partner facilities adhere to international healthcare standards. Implement rigorous quality assurance protocols to guarantee the highest level of care for our clients.
                    </p>
                    <h5>Embrace Sustainability</h5>
                    <p>
                        Sustainability is a growing concern for travelers. We will be considering eco-friendly practices in our operations, from choosing eco-conscious accommodation to promoting responsible tourism activities.
                    </p>
                    <h5>Our Goal</h5>
                    <p>
                        T4C aims at connecting patients and doctors around the world. We are immersing ourselves in the market, forging strong partnerships, and prioritizing client satisfaction and safety, we want to embark on a journey that not only transforms lives but also opens doors to the world.
                    </p>
                    <p>
                        As you take your first steps into this exciting industry, remember that success lies in your dedication to delivering exceptional experiences that blend the best of healthcare and travel. It's a journey worth taking – one that can lead to a fulfilling and prosperous future in the realm of medical and wellness tourism.
                    </p>

                    <h5>Insurance challenges:</h5>
                    <p>
                        Medical tourism insurance providers offer specialized coverage for individuals traveling abroad for medical treatments or procedures. While the availability of these providers may vary depending on your location and the destination for your medical tourism, here are some known insurance providers that offer medical tourism-specific coverage:
                    </p>
                    <p>1. Medigap or Medicare Supplement Plans: Some Medigap plans, such as Plan C, D, F, G, M, and N, provide limited coverage for foreign travel emergencies. These plans can be helpful for U.S. citizens traveling abroad for medical care.</p>

                    <p>2. Companion Global Healthcare: This company offers medical tourism insurance plans designed to cover expenses related to medical treatments overseas.</p>

                    <p>3. International SOS: International SOS provides comprehensive medical and travel assistance services, including medical evacuation and repatriation, which can be crucial for medical tourists.</p>

                    <p> 4. PassportCard: PassportCard offers international health insurance plans, including coverage for planned medical treatments abroad.</p>

                    <p> 5. World Nomads: While not specifically tailored for medical tourism, World Nomads offers travel insurance that includes some medical coverage for emergency situations during trips.</p>

                    <p>6. Travel Insured International: They offer various travel insurance plans, including some that cover medical expenses during medical tourism trips.</p>

                    <p>7. Medisafe Insurance: Medisafe offers insurance for medical tourists and focuses on coverage for elective surgeries and treatments abroad.</p>

                    <p>
                        Remember that the availability and scope of coverage may differ among providers, so it's crucial to read policy details carefully, including coverage limits, exclusions, and any pre-existing condition clauses. Additionally, consult with a licensed insurance advisor to ensure you select the right plan for your specific medical tourism needs.
                    </p>

                    <div className=''>
                        <p className='fw-bolder text-center fst-italic'>
                            T4C aims at connecting patients and doctors around the world!
                        </p>
                        <p style={{ color: 'blue' }}>
                            For on-boarding hospitals, wellness centers, vendor or partnership opportunities, other inquiries contact:
                        </p>
                        <p style={{ color: 'red' }}>
                            Mr. Preshit Dalal (Founder/Technologist) | <a href='mailto:contact@trip4cure.com'>contact@trip4cure.com</a> | <a href='tel:1-7322078256'>1-7322078256</a> (USA)
                        </p>
                        <p style={{ color: 'red'}}>
                            Mr. Pratik | <a href='mailto:pratik@puravidasportz.com'>pratik@puravidasportz.com</a> | <a href='tel:+919004094336'>+91-9004094336</a> (INDIA)
                        </p>

                    </div>

                </div>
            </Container>
            <Footer />
        </>
    )
}
