import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
export default function Subscription() {
    const navigate = useNavigate();
    const [plan, setPlan] = useState("");
 

    useEffect(() => {
        fetchSub()
    }, [])

    const plans = localStorage.getItem("plan")
    console.log(plans,localStorage.getItem("plan"))
    const [data, setdata] = useState([]);
  
    const fetchSub = async () => {

        const headers = {
            "Authorization": "token " + localStorage.getItem("token")
        }

        try {
            const response = await fetch('https://trip4cure.com/api/my_plan/', {
                headers: headers
            });

            const data = await response.json();
            const check =localStorage.getItem("plan")
            setPlan(check)
            setdata(data.subscriptions)
            console.log(data.subscriptions)

        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    }

   const goto=()=>{
        navigate('/subscription')
    }
    return (
        <div>


            <div className='mt-5'>
                <h4 class="text-right  d-inline">Subscription</h4>
                {data && plan == 'true' ?
                <Button class="line-primary-btn py-2 px-3 submit-button" disabled={true} style={{ float: 'right' }} name="btnAddMore" > Buy Now</Button> :  <button onClick={goto}  class="line-primary-btn py-2 px-3 submit-button"  style={{ float: 'right' }} name="btnAddMore" > Buy Now</button> 
                }

            </div>
            <div className="row my-3">
                <div className="col-12">
                    <div className="card">
                        <div className="card__body" style={{ overflowX: "auto" }}>
                            <table class="table table-hover" >
                                <thead>
                                    {data.length > 0 && <tr className='dash-tr'>
                                        <th>Plan Name</th>
                                        <th>Amount</th>
                                        <th>Start Date</th>
                                        <th>Expiry Date</th>
                                        <th>Status</th>
                                    </tr>}
                                </thead>
                                <tbody>
                                    {data.length == 0 ? <tr><td colSpan={5} className='text-center'>No Subscription.</td></tr> :
                                        data.map(patient =>
                                        (
                                            <tr style={{ cursor: 'pointer' }}>
                                                <td>{patient.plan}</td>
                                                <td>{patient.amount}</td>
                                                <td>
                                                    {(new Date(patient.purches_date)).toLocaleDateString('en-CA')}
                                                </td>
                                                <td>
                                                {(new Date(patient.plan_expire)).toLocaleDateString('en-CA')}
                                                </td>
                                                <td>{patient.transaction_status=="succeeded"?'Payment Successful':patient.transaction_status}</td>
                                            </tr>

                                        ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
