import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { Col, Container, Form, Row, Card, Tab, Nav } from "react-bootstrap";
import './HospitalUserProfile.css'
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'
import Services from '../../Components/Profile/Services/Services';
import Doctors from '../../Components/Profile/Doctor';
import Overview from '../../Components/Profile/Overview';
import Gallery from '../../Components/Profile/Gallery';
import Password from '../../Components/Profile/password';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Subscription from '../../Components/Profile/Subscription';
import ScrollToTop from '../../Utilies/ScrollTop';

export default function HospitalUserProfile() {
  const [edit, setedit] = useState(true);
  const [hospitalProfile, sethospitalProfile] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    fetchHospitalProfile();
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if(token && user=="hospital"){
      navigate('/hospital-profile')
    }else{
      navigate('/')
    }
  }, []);

  const fetchHospitalProfile = async () => {

    try {
      const response = await fetch('https://trip4cure.com/api/update_hospital_profile/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "token " + localStorage.getItem("token")
        }
      });

      const data = await response.json();
      console.log("sethospitalProfile", data)
      sethospitalProfile(data);
      sethospitalName(data.data.hospital_name);
      setcontactnumber(data.data.mobile)
      setEmail(data.data.email)
      setaddress(data.data.address)
      setcity(data.data.city)
      setcountry(data.data.country)
      setyear(data.data.establishment_year)
      setpostcode(data.data.pincode)
      setImage(data.data.image)

    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const [data, setdata] = useState(true);
  const validationSchema = Yup.object().shape({
    hospitalname: Yup.string()
      .required('First name is required')
      .min(2),
    country: Yup.string()
      .required('Country name is required')
      .min(2),
    city: Yup.string()
      .required('City name is required')
      .min(2),
    address: Yup.string(),
    year: Yup.string(),
    postcode: Yup.string(),
    contactnumber: Yup.string()
      .required('Contact number is required')
      .min(5),

  });

  const [hospitalName, sethospitalName] = useState("");
  const [country, setcountry] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [email, setEmail] = useState("");
  const [year, setyear] = useState("");
  const [postcode, setpostcode] = useState("");
  const [contactnumber, setcontactnumber] = useState("");






  const handleInput1 = (e) => {
    const formattedNumber = formatNumber(e.target.value);
    sethospitalName(formattedNumber);
  };
  const handleInput2 = (e) => {
    const formattedNumber = formatNumber(e.target.value);
    setcountry(formattedNumber);
  };

  const handleInput3 = (e) => {
    const formattedNumber = formatNumber(e.target.value);
    setcity(formattedNumber);
  };



  const handleInput4 = (e) => {
    const formattedNumber = OnlyNumber(e.target.value);
    setyear(formattedNumber);
  };

  const handleInput6 = (e) => {
    const formattedNumber = OnlyNumber(e.target.value);
    setpostcode(formattedNumber);
  };

  const handleInput5 = (e) => {
    const formattedNumber = NotAlph(e.target.value);
    setcontactnumber(formattedNumber);
  };



  const formatNumber = (value) => {
    return value.replace(/[^A-Za-z]/ig, "")
  }

  const OnlyNumber = (value) => {
    return value.replace(/[^0-9]/ig, "")
  }


  const NotAlph = (value) => {
    return value.replace(/[^0-9]/ig, "")
  }

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;


  const [msg, setMsg] = useState("");
  const [file, setFile] = useState()
  const [image, setImage] = React.useState(false);

  function handleChangeImage(event) {
    console.log(event.target.files[0])
    setFile(event.target.files[0])
  }

  let onSubmit = async e => {
    // e.preventDefault();
    try {
      console.log(file)

      const formData = new FormData()
      formData.append("user_id", hospitalProfile.data.id)
      formData.append("hospital_name", hospitalName)
      formData.append("hospital_country", country)
      formData.append("email", email)
      formData.append("mobile_num", contactnumber)
      formData.append("city", city)
      formData.append("establishment_year", year==null?"":year)
      formData.append("address", address==null?"":address)
      formData.append("pincode", postcode==null?"":postcode)
      if (file != null) {
        formData.append("profile_image", file)
      }


      const headers = {
        "Authorization": "token " + localStorage.getItem("token")
      }
      const update = await axios.post('https://trip4cure.com/api/update_hospital_profile/', formData,
        {
          headers: headers
        })

      if (update.status === 201) {
        fetchHospitalProfile();
        toast.success('Hospital profile updated successfully');
        setedit(true)
        console.log(e.target)
        const RESPONSE_STATUS = update.Msg

      }
    } catch (err) {
      setMsg("Something wents wrong")
      console.log(err);
    }
  }




  return (
    <>
    <ScrollToTop/>
      <Header />
      <ToastContainer/>
      <div class="container  hospital-profile" style={{ marginTop: '50px' }}>
        <div class="row">

          <div class="col-md-4">
            <div class="profile-img">
              <img src={image?`${"https://trip4cure.com" + image}`:`${process.env.PUBLIC_URL}/images/default-hospital.png`} />
            </div>
          </div>
          <div class="col-md-8 my-4">



            <ul class="list-group">


              <li class="list-group-item">   <Row>
                <Col md={4}>Hospital Name</Col>
                <Col md={8}>{hospitalName}</Col>
              </Row>
              </li>
              <li class="list-group-item">   <Row>
                <Col md={4}>Contact Number</Col>
                <Col md={8}>{contactnumber}</Col>
              </Row></li>
              <li class="list-group-item">   <Row>
                <Col md={4}>Email id</Col>
                <Col md={8}>{email}</Col>
              </Row></li>
            </ul>
          </div>

        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="info">
          <div class="row">
            <div class="col-md-4" style={{ borderRadius: '10px' }}>
              <div class="profile-work text-center">
                <Nav class="flex-column rounded-circle" >
                  <Nav.Item>
                    <Nav.Link eventKey="info" className="  px-4 nav-tabs nav-first" >Info</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="about" className="  px-4 nav-tabs " >Overview</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="images" className="  px-4 nav-tabs " >Images</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="services" className=" px-4 nav-tabs ">Services</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="doctors" className=" px-4 nav-tabs">Doctors</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="password" className=" px-4 nav-tabs">Password</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="subscription" className=" px-4 nav-tabs nav-last">Subscription</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            <div class="col-md-8">

              <Row className=''>
                <Col md="8">

                </Col>
                <Col md={12}>
                  <Tab.Content >
                    <Tab.Pane eventKey="info">
                      <div class="hospital-profile container rounded bg-white  mb-5">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="py-5">
                              <div class="d-flex justify-content-between align-items-center mb-3">
                                <h4 class="text-right">Information Settings</h4>
                                <button type="button" class="line-primary-btn py-2 px-3 submit-button" style={{ float: 'right' }} name="btnAddMore" onClick={e => setedit(!edit)}> <i class="fa fa-pencil" aria-hidden="true" ></i> </button>
                              </div>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                {hospitalProfile ?
                                  <div class="row mt-3">

                                    <div class="col-md-12"><label>Hospital Name*</label>
                                      {edit ?
                                        <p>{hospitalName}</p> :
                                        <>
                                          <input type="text" {...register('hospitalname')} maxLength={'200'} onChange={(e) => sethospitalName(e.target.value)} value={hospitalName} placeholder='Enter hospital name' className={`form-control ${errors.hospitalname ? 'is-invalid' : ''}`} />
                                          <div className="invalid-feedback">{errors.hospitalname?.message}</div>
                                        </>
                                      }
                                    </div>

                                    <div class="col-md-12"><label>Mobile Number*</label>
                                      {edit ?
                                        <p>{contactnumber}</p> :
                                        <>
                                          <input type="text" {...register('contactnumber')} maxLength={'20'} onChange={(e) => handleInput5(e)} value={contactnumber} placeholder='Enter contact number' className={`form-control ${errors.contactnumber ? 'is-invalid' : ''}`} />
                                          <div className="invalid-feedback">{errors.contactnumber?.message}</div>
                                        </>
                                      }
                                    </div>
                                    <div class="col-md-12"><label>Email ID</label>
                                      {edit ?
                                        <p>{email}</p> :
                                        <>
                                          <input type="text" value={email} placeholder='Enter email' className={`form-control`} disabled />

                                        </>}
                                    </div>
                                    <div class="col-md-12"><label>Address</label>
                                      {edit ?
                                        <p>{address}</p> :
                                        <>
                                          <input type="text" {...register('address')} onChange={(e) => setaddress(e.target.value)} value={address==null?"":address} maxLength={'200'} placeholder='Enter Address' className={`form-control ${errors.address ? 'is-invalid' : ''}`} />
                                          <div className="invalid-feedback">{errors.address?.message}</div>
                                        </>
                                      }
                                    </div>
                                    <div class="col-md-6"><label>City*</label>
                                      {edit ?
                                        <p>{city}</p> :
                                        <>
                                          <input type="text" {...register('city')} maxLength={'200'} onChange={(e) => handleInput3(e)} value={city} placeholder='Enter city name' className={`form-control ${errors.city ? 'is-invalid' : ''}`} />
                                          <div className="invalid-feedback">{errors.city?.message}</div>
                                        </>
                                      }</div>
                                    <div class="col-md-6"><label>Country*</label>
                                      {edit ?
                                        <p>{country}</p> :
                                        <>
                                          <input type="text" {...register('country')} maxLength={'200'} onChange={(e) => handleInput2(e)} value={country} placeholder='Enter country name' className={`form-control ${errors.country ? 'is-invalid' : ''}`} />
                                          <div className="invalid-feedback">{errors.country?.message}</div>
                                        </>}
                                    </div>
                                    <div class="col-md-6"><label>Postcode</label>
                                      {edit ?
                                        <p>{postcode}</p> :
                                        <>
                                          <input type="text" {...register('postcode')} maxLength={'10'} onChange={(e) => handleInput6(e)} value={postcode==null?"":postcode} placeholder='Enter Postcode' className={`form-control ${errors.postcode ? 'is-invalid' : ''}`} />
                                          <div className="invalid-feedback">{errors.postcode?.message}</div>
                                        </>}
                                    </div>
                                    <div class="col-md-6"><label>Establishment Year</label>
                                      {edit ?
                                        <p>{year}</p> :
                                        <>
                                          <input type="text" {...register('year')} maxLength={'4'} onChange={(e) => handleInput4(e)} value={year==null?"":year} placeholder='Enter establishment year' className={`form-control ${errors.year ? 'is-invalid' : ''}`} />
                                          <div className="invalid-feedback">{errors.year?.message}</div>
                                        </>
                                      }
                                    </div>
                                    {!edit ? <div class="col-md-12">
                                      <label>Hospital Image</label>
                                      <input type="file" name="file" onChange={handleChangeImage} className='form-control' />
                                    </div> : <></>}

                                  </div> : <></>}

                                {!edit ? <div class="mt-5 text-center"><button class="btn btn-primary" variant="primary" type="submit">Save Profile</button></div> : <></>
                                }
                              </form>


                            </div>
                          </div>

                        </div>
                      </div>

                    </Tab.Pane>
                    <Tab.Pane eventKey="services">
                      <Services />
                    </Tab.Pane>
                    <Tab.Pane eventKey="images">
                      <Gallery />
                    </Tab.Pane>
                    <Tab.Pane eventKey="about">
                      <Overview />
                    </Tab.Pane>
                    <Tab.Pane eventKey="doctors">
                      <Doctors />
                    </Tab.Pane>

                    <Tab.Pane eventKey="password">
                      <Password />
                    </Tab.Pane>
                    <Tab.Pane eventKey="subscription">
                      <Subscription />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>


            </div>
          </div>
        </Tab.Container>

      </div>
      <Footer />
    </>

  )
}
